<template>
    <div id="autocomplete-container" class="d-flex justify-center">
        <v-autocomplete
            class="shadow-none search-bar"
            v-model="search"
            dense
            prepend-inner-icon="icon-search"
            background-color="text-white"
            color="grey-8"
            ref="topAutocomplete"
            :items="dataToSearchIn"
            hide-details
            hide-selected
            solo-inverted
            flat
            @change="logToSegment('usedSearchBar')"
            :item-text="getItemName"
            :label="$t('commons.layout.topbar.search.label')"
            :append-icon=null
            return-object
        >
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                        {{ $t('commons.layout.topbar.search.placeholder') }}
                    </v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:item="{ item }">
                <div class="searchRow" v-if="item.app">
                    <v-list-item-content style="min-height: 46px;">
                        <div style="max-width: 300px;" class="truncate rowContent">
                            <div class="rowIcon relative">
                                <licence-logo class="vertical-align" :item="item.app"></licence-logo>
                            </div>
                            <span class="pl-4 rowTextContent">
                                <strong>{{ (item.app) ? item.app.name : item.customApp }}</strong>
                                <span class="text-caption rowSubtext">{{$tc("licences.title", 1)}}</span>
                            </span>
                        </div>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.department">
                        <div class="d-flex align-center">
                            <v-chip
                                class="ma-2 f-6"
                                style="width: 12px;height: 12px;"
                                :color="item.department.color"
                                label
                                small
                            ></v-chip>
                            <strong>{{ item.department.name }}</strong>
                        </div>
                    </v-list-item-action>
                </div>
                <div class="searchRow" v-if="item.email">
                    <v-list-item-content style="min-height: 46px;">
                        <div style="max-width: 300px;" class="rowContent">
                            <icon-base icon-name="icon-users" class="rowIcon">
                                <component :fill="'gray'" :is="'icon-users-fill'"></component>
                            </icon-base>
                            <span class="pl-4 rowTextContent truncate">
                                <strong>{{ item.fullName }}</strong>
                                <span class="text-caption rowSubtext">{{$tc("employee.titles", 1)}}</span>
                            </span>
                        </div>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.department">
                        <div class="d-flex align-center">
                            <v-chip
                                class="ma-2 f-6"
                                style="width: 12px;height: 12px;"
                                :color="item.department.color"
                                label
                                small
                            ></v-chip>
                            <strong>{{ item.department.name }}</strong>
                        </div>
                    </v-list-item-action>
                </div>
                
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
    import mixin from '../../Mixins/mixin';
    import LicenceLogo from "../licence/LicenceLogo"
    import {mapGetters} from 'vuex';
    import IconBase from "../../components/IconBase";
    import IconUsersFill from "../../components/icons/IconUsersFill";

    export default {
        name: "search-bar",
        mixins: [mixin],
        components: {
            LicenceLogo,
            IconBase,
            IconUsersFill
        },
        updated(){
            this.$nextTick(() => {
                this.$refs.topAutocomplete.blur()
                this.search = '';
            });
        },
        data () {
            return {
                search: null,
            }
        },
        methods: {
            getItemName(item) {
                if(item.app) {
                    return item.app.name
                }else if(item.customApp) {
                    return item.customApp
                }
                return item.fullName
            },
        },
        computed: {
            ...mapGetters('licence', {
                licences: 'licences',
            }),
            ...mapGetters('user', {
                users: 'users'
            }),
            dataToSearchIn(){
                this.users.forEach(employee => {
                    employee.fullName = this.formatUserFullName(employee)
                });
                this.licences.forEach(licence => {
                    if(licence.app) {
                        licence.fullName = licence.app.name
                    }else if(licence.customApp) {
                        licence.fullName = licence.customApp
                    }
                });
                let dataToSearch = this.licences.concat(this.users)

                dataToSearch.sort(function compare(a, b) {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase())
                        return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase() )
                        return 1;
                    return 0;
                });
                return dataToSearch
            }
        },
        //Prevent the search bar unfocus after data has been loaded onto the dashboard
        updated() {
        },
        watch: {
            search(item){
                if(item.id){
                    if(item.app) {
                        this.$router.push({path: "/licence/"+item.id+'/overview'});
                    } else {
                        this.$router.push({path: '/team/employee/'+item.id});
                    }
                }
            }
        }
    }
</script>
<style lang="scss">
    .search-bar {
        max-width: 440px;
    }
    #autocomplete-container {
        width: 544px;
        .v-input{
            border-radius: 24px;
            border: 1.5px solid var(--v-gray-70-base);
            .v-input__slot{
                background: white !important;
            }
            .v-label.theme--light{
                color: var(--v-gray-40-base) !important;
            }
        }
        .theme--light.v-input--is-focused > .v-input__control > .v-input__slot input{
            color: var(--v-grey-8-base);
        }
        .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections{
            color: var(--v-grey-8-base);
        }
        .icon-search{
            color: var(--v-gray-50-base);
        }
    }
    .v-autocomplete__content{
        .v-divider{
            margin-top: 8px;
        }
        .v-subheader{
            height: 30px;
            padding: 4px 8px 0px;
        }
    }
    .searchRow {
        display: flex;
        width: 100%;
    }
    .rowContent {
        display: flex;
        align-items: center;
        .rowIcon {
            min-width: 30px;
            height: 30px;
            background-color: var(--v-gray-90-base);
            border-radius: 50%;
            overflow: hidden;
        }
        .rowTextContent {
            display: flex;
            flex-direction: column;
            .rowSubtext{
                padding-top: 1px;
                color: var(--v-gray-40-base);
            }
        }
    }
</style>
