import client from "./client";

const apiUrl = "/v2/spends"

export default {
  get(query) {
    return client.get("/v2/spends", {
      params: query,
    });
  },

  apiUrl:apiUrl
};