import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import AuthManager from "../services/AuthManager";
import UserAPI from '../api/user';
import router from '../router';
import ImpersonateMode from "../services/ImpersonateMode";

function buildAuthorizationHeader(){
    return AuthManager.accessToken ? 'Bearer ' + AuthManager.accessToken : '';
}

const httpClient = axios.create({
    headers: {
        Accept: 'application/json',
    }
});

httpClient.interceptors.request.use(

    config => {

        config.headers.Authorization = buildAuthorizationHeader();
        if(ImpersonateMode.isActive()){
            config.headers["X-Switch-User"] = ImpersonateMode.getImpersonatedUserIdentifier();
        }

        return config;
    },

    error => {
        return Promise.reject(error);
    }
);

/**
 * @see https://github.com/Flyrell/axios-auth-refresh
 */
createAuthRefreshInterceptor(
    httpClient,
    failedRequest => {

        return new Promise((resolve, reject) => {

            AuthManager.accessToken = null;

            UserAPI.refreshAccessToken(AuthManager.refreshToken).then(
                response => {

                    AuthManager.storeTokens({
                        access: response.data.token,
                        refresh: response.data.refresh_token,
                    });

                    resolve();
                },
                () => {
                    AuthManager.reset();
                    reject();
                },
            );
        });
    },
    {
        shouldRefresh: error => error.response?.data?.message?.toLowerCase() === 'expired jwt token'
    }
);


httpClient.interceptors.response.use(
    response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    error => {

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if( error.response.status === 401 && router.currentRoute.meta?.requiresAuth === true){
            window.location.href = '/login';
        }

        // Map error for the ErrorForm component
        if( error.response?.data?.message ){
            error.response.data.error = error.response.data.message;
        }

        return Promise.reject(error);
    }
);

export default httpClient;