
import Vue from 'vue';
import router from './router';
import store from './store';
import App from './views/App'
import validation from './plugins/validation'
import i18n from './plugins/i18n'
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts'
import vuetify from './plugins/vuetify'
import '../scss/app.scss'
import { init as sentryInit } from '@sentry/vue';
import "@lottiefiles/lottie-player";
import posthogPlugin from "./plugins/posthog";

if(process.env.NODE_ENV === 'production'){

    sentryInit({
        Vue: Vue,
        dsn: 'https://06302fd9034e45d1b7d0bec05906ca02@o412395.ingest.sentry.io/5289109',
    });
}

Vue.config.productionTip = false

Vue.use(require('vue-moment'), {
    moment
});
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)
Vue.use(posthogPlugin);

async function boot(){

    const user = await store.dispatch("user/get");
    if(user){

        await Promise.all([
            store.dispatch("company/get"),
            store.dispatch("authorization/get"),
        ]);
    }

    new Vue({
        el: '#app',
        i18n,
        vuetify,
        validation,
        router,
        store,
        components: {
            App
        }
    });
}

export default boot;
