import AmortizationsSettingsAPI from "../api/AmortizationsSettings";

const 
  FETCHING          = "FETCHING",
  FETCHING_SUCCESS  = "FETCHING_SUCCESS",
  FETCHING_ERROR    = "FETCHING_ERROR",
  UPDATING          = "UPDATING",
  UPDATING_SUCCESS  = "UPDATING_SUCCESS",
  UPDATING_ERROR    = "UPDATING_ERROR";

export default {

    namespaced: true,

    state: {
        isLoading:          false,
        errors:             null,
        amortization:       null,
    },

    getters: {

        isLoading(state) {
            return state.isLoading;
        },

        hasErrors(state) {
            return state.errors !== null;
        },

        errors(state) {
            return state.errors;
        },

        amortization(state) {
            return state.amortization;
        },

    },

    mutations: {

        [FETCHING](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [FETCHING_SUCCESS](state, payload)
        {
            state.isLoading         = false;
            state.errors            = null;
            state.amortization  = payload;
        },

        [FETCHING_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

        [UPDATING](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [UPDATING_SUCCESS](state, payload)
        {
            state.isLoading         = false;
            state.errors            = null;
            state.amortization  = payload;
        },

        [UPDATING_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

    },

    actions: {

        async get({commit})
        {
            commit(FETCHING);

            try {

                let response = await AmortizationsSettingsAPI.get();
                commit(FETCHING_SUCCESS, response.data);
                
                return response.data;

            } catch (error) {

                commit(FETCHING_ERROR, error);
                return null;
            }
        },

        async update({commit}, payload)
        {
            commit(UPDATING);

            try {

                let response = await AmortizationsSettingsAPI.update(payload);
                commit(UPDATING_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(UPDATING_ERROR, error);
                return null;
            }

        }

    },

}