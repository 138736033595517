import LicenceAPI from "../api/licence";
import LicenceHelper from '../services/Licence/LicenceHelper';
import moment from 'moment';
import Security from "../services/Security/Security";
import permissions from "../services/Security/Permissions";

const CREATING_LICENCE = "CREATING_LICENCE",
  CREATING_LICENCE_SUCCESS = "CREATING_LICENCE_SUCCESS",
  CREATING_LICENCE_ERROR = "CREATING_LICENCE_ERROR",
  FETCHING_LICENCES = "FETCHING_LICENCES",
  FETCHING_LICENCES_SUCCESS = "FETCHING_LICENCES_SUCCESS",
  FETCHING_LICENCES_ERROR = "FETCHING_LICENCES_ERROR",
  FETCHING_RENEWAL_MONTH_LICENCES = "FETCHING_RENEWAL_MONTH_LICENCES",
  FETCHING_RENEWAL_MONTH_LICENCES_SUCCESS = "FETCHING_RENEWAL_MONTH_LICENCES_SUCCESS",
  FETCHING_RENEWAL_MONTH_LICENCES_ERROR = "FETCHING_RENEWAL_MONTH_LICENCES_ERROR",
  FETCHING_TRACKED_LICENCES = "FETCHING_TRACKED_LICENCES",
  FETCHING_TRACKED_LICENCES_SUCCESS = "FETCHING_TRACKED_LICENCES_SUCCESS",
  FETCHING_TRACKED_LICENCES_ERROR = "FETCHING_TRACKED_LICENCES_ERROR",
  FETCHING_LICENCE = "FETCHING_LICENCE",
  FETCHING_LICENCE_SUCCESS = "FETCHING_LICENCE_SUCCESS",
  FETCHING_LICENCE_ERROR = "FETCHING_LICENCE_ERROR",
  UPDATING_LICENCE = "UPDATING_LICENCE",
  UPDATING_LICENCE_SUCCESS = "UPDATING_LICENCE_SUCCESS",
  UPDATING_LICENCE_ERROR = "UPDATING_LICENCE_ERROR",
  DELETING = "DELETING",
  DELETING_SUCCESS = "DELETING_SUCCESS",
  DELETING_ERROR = "DELETING_ERROR",
  UPDATING_LICENCES_SUCCESS= "UPDATING_LICENCES_SUCCESS";


export default {
  namespaced: true,
  state() {
    return {
      isListingLoading: false,
      isLoading: false,
      error: null,
      licences: [],
      licence: null,
      listing: [],
      trackedLicences: 0,
      renewalMonth: 0,
      createdLicence: null
    }
  },
  getters: {

    readonly(state){
      try{
        return !Security.isGranted(permissions.EDIT_LICENCE, state.licence);
      }catch(e){
        // @todo return true when POST & PUT endpoints will be migrated to /v2 so permissions will be returned
        return false;
      }
    },

    isListingLoading(state) {
      return state.isListingLoading;
    },
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasLicences(state) {
      return state.licences.length > 0;
    },
    licences(state) {
      return state.licences;
    },
    licence(state) {
      return state.licence;
    },
    trackedLicences(state) {
      return state.trackedLicences;
    },
    renewalMonth(state) {
      return state.renewalMonth;
    },
    licencesByManager: (state) => (manager) => {
      return state.licences.filter(licence => licence.manager && licence.manager.id === manager.id);
    },
    licencesWithRenewalDate: (state) => {
      return state.licences.filter(
        licence => (
          licence.isRenewable
          && licence.financialModel == "subscription"
          && licence.renewal
          && (!LicenceHelper.isLicenceInactive(licence))
        )
      );
    },
    licencesWithRequiredRenewal: (state) => {
      return  state.licences.filter(
        licence => (
          licence.isFree == false
          && licence.financialModel == "subscription"
          && !LicenceHelper.isLicenceInactive(licence)
          && (
            licence.isRenewable !== false
          )
          && (
            // remove 1 month so that cron can update the date automatically
            moment(licence.renewal).isBefore(moment().subtract(1, 'M'))
            ||
            !licence.renewal
          )
          
        )
      );
    },
    licencesWithOutdatedRenewal: (state) => {
      return  state.licences.filter(
        licence => (
          moment(licence.renewal).isBefore(moment().subtract(1, 'M'))
          && licence.financialModel == "subscription"
          && licence.renewal
          && (!LicenceHelper.isLicenceInactive(licence))
          && (licence.isFree == false)
          && licence.isRenewable !== false
        )
      );
    },
    paidLicencesWithNoRenewal: (state) => {
      return state.licences.filter(
        licence => (
          !licence.renewal
          && licence.financialModel == "subscription"
          && !LicenceHelper.isLicenceInactive(licence) 
          && licence.isFree === false 
          && licence.isRenewable === true
        )
      );
    },

    licencesRequiredFirstPayementDate: (state) => {
      return state.licences.filter(
        licence => (licence.chargeCondition === "pending" || licence.chargeCondition === "yearly")
        &&
          !licence.firstPaymentAt
        &&
          licence.isFree === false 
        &&
          licence.isRenewable === false 
        &&
          licence.transactions && licence.transactions.length > 0
      );
    },

    licencesRequiredChargeCondition: (state) => {
      return state.licences.filter(
        licence => (
            (!licence.chargeCondition)
          && 
            (licence.isFree === false)
          && 
            licence.transactions && licence.transactions.length > 0
        )
      );
    },

    licencesTracked: state => state.licences.filter(licence => !LicenceHelper.isLicenceInactive(licence)),
    licencesTrackedWithoutManager: state => state.licences.filter(licence => !LicenceHelper.isLicenceInactive(licence) && !licence.manager),

    /**
     * @returns {Array}
     */
     getCurrentManagerLicences(state, getters, store)
     {
       return state.licences.filter((licence) => {
         return (licence.manager ? licence.manager.id === store.user.user.id : false);
       });
     },
 
     /**
      * @returns {Array}
      */
     getAssignedToOthersManagersLicences(state, getters, store)
     {
       return state.licences.filter((licence) => {
         return (licence.manager ? licence.manager.id !== store.user.user.id : false);
       });
     },
 
     /**
      * @returns {Array}
      */
     getManagerLessLicences(state)
     {
       return state.licences.filter((licence) => {
         return (!licence.manager);
       });
     },
 
     /**
      */
      getCreatedLicence(state)
     {
       return state.createdLicence;
     },
  },
  mutations: {
    [CREATING_LICENCE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [CREATING_LICENCE_SUCCESS](state, licence) {
      state.isLoading = false;
      state.error = null;
      state.createdLicence = licence;
      state.licences.push(licence);
    },
    [CREATING_LICENCE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.createdLicence = null;
    },
    [DELETING](state) {
      state.isLoading = true;
      state.error = null;
    },
    [DELETING_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [DELETING_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [UPDATING_LICENCE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_LICENCE_SUCCESS](state, licence) {
      state.isLoading = false;
      state.error = null;
      state.licence = licence;
      state.licences.forEach(function(oneLicence, i) {
        if (oneLicence.id == licence.id){
          state.licences.splice(i, 1);
          state.licences.push(licence);
        } 
      });
    },
    [UPDATING_LICENCE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_LICENCES](state) {
      state.isListingLoading = true;
      state.error = null;
    },
    [FETCHING_LICENCES_SUCCESS](state, licences) {
      state.isListingLoading = false;
      state.error = null;
      state.licences = licences;
    },
    [UPDATING_LICENCES_SUCCESS](state, licences) {
      state.isListingLoading = false;
      state.error = null;
      licences.forEach(licenceUpdated => {
        let index = state.licences.findIndex((licence) => licence.id == licenceUpdated.id)
        licenceUpdated.activeUsers = state.licences[index].activeUsers
        licenceUpdated.inactiveCount = state.licences[index].inactiveCount
        state.licences.splice(index, 1)
        state.licences.push(licenceUpdated)
      })
    },
    [FETCHING_LICENCES_ERROR](state, error) {
      state.isListingLoading = false;
      state.error = error;
    },
    [FETCHING_RENEWAL_MONTH_LICENCES](state) {
      state.isLoading = true;
      state.error = null;
      state.renewalMonth = 0;
    },
    [FETCHING_RENEWAL_MONTH_LICENCES_SUCCESS](state, renewalMonth) {
      state.isLoading = false;
      state.error = null;
      state.renewalMonth = renewalMonth;
    },
    [FETCHING_RENEWAL_MONTH_LICENCES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.renewalMonth = 0;
    },
    [FETCHING_TRACKED_LICENCES](state) {
      state.isLoading = true;
      state.error = null;
      state.trackedLicences = 0;
    },
    [FETCHING_TRACKED_LICENCES_SUCCESS](state, licences) {
      state.isLoading = false;
      state.error = null;
      state.trackedLicences = licences.length;
    },
    [FETCHING_TRACKED_LICENCES_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.trackedLicences = 0;
    },
    [FETCHING_LICENCE](state) {
      state.isLoading = true;
      state.error = null;
      state.licence = null;
    },
    [FETCHING_LICENCE_SUCCESS](state, licence) {
      state.isLoading = false;
      state.error = null;
      state.licence = licence;
    },
    [FETCHING_LICENCE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.licence = null;
    },
  },
  actions: {
    async create({ commit }, payload) {
      commit(CREATING_LICENCE);
      try {
        let response = await LicenceAPI.create(payload);
        commit(CREATING_LICENCE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(CREATING_LICENCE_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING_LICENCE);
      try {
        let id = payload.id
        delete payload.id;
        let response = await LicenceAPI.update(id, payload);
        commit(UPDATING_LICENCE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_LICENCE_ERROR, error);
        return null;
      }
    },
    async batch({ commit }, payload) {
      commit(UPDATING_LICENCE);
      try {
        let response = await LicenceAPI.batch(payload);
        commit(UPDATING_LICENCES_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_LICENCE_ERROR, error);
        return null;
      }
    },
    async archive({ commit }, id) {
      commit(DELETING);
      try {
        let response = await LicenceAPI.archive(id);
        commit(DELETING_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(DELETING_ERROR, error);
        return null;
      }
    },
    async getAll({ commit }) {
      commit(FETCHING_LICENCES);
      try {
        let response = await LicenceAPI.getAll();
        commit(FETCHING_LICENCES_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_LICENCES_ERROR, error);
        return null;
      }
    },
    
    async list({commit}, query) {
        commit(FETCHING_LICENCES);
        try {
            let response = await LicenceAPI.list(query);
            commit(FETCHING_LICENCES_SUCCESS, response.data);
            return response.data;
        } catch (error) {
            commit(FETCHING_LICENCES_ERROR, error);
            return null;
        }
    },
    async getMonthRenewals({ commit }) {
      commit(FETCHING_RENEWAL_MONTH_LICENCES);
      try {
        let response = await LicenceAPI.getMonthRenewals();
        commit(FETCHING_RENEWAL_MONTH_LICENCES_SUCCESS, response.data.total);
        return response.data;
      } catch (error) {
        commit(FETCHING_RENEWAL_MONTH_LICENCES_ERROR, error);
        return null;
      }
    },
    async getTrackedLicences({ commit }) {
      commit(FETCHING_TRACKED_LICENCES);
      try {
        let response = await LicenceAPI.list({
          isTracked: 1,
        });
        commit(FETCHING_TRACKED_LICENCES_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_TRACKED_LICENCES_ERROR, error);
        return null;
      }
    },
    async getOne({ commit }, payload) {
      commit(FETCHING_LICENCE);
      try {
        let response = await LicenceAPI.getOne(payload.id);
        commit(FETCHING_LICENCE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_LICENCE_ERROR, error);
        return null;
      }
    },
    async removeUser({ commit }, payload) {
      commit(UPDATING_LICENCE);
      try {
        let response = await LicenceAPI.removeUser(payload.id, payload.userIdToDelete);
        commit(UPDATING_LICENCE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_LICENCE_ERROR, error);
        return null;
      }
    },
    async addUsers({ commit }, payload) {
      commit(UPDATING_LICENCE);
      try {
        let id = payload.id
        delete payload.id;
        let response = await LicenceAPI.addUsers(id, payload);
        commit(UPDATING_LICENCE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_LICENCE_ERROR, error);
        return null;
      }
    },
  }
};