import VueRouter from "vue-router";
import { Store } from "vuex";

export default class OnboardingGuard
{
    /** @type {VueRouter} */
    #router

    /** @type {Store} */
    #store

    /**
     * 1st route does not exist on purpose.
     * It's because the first onboarding step index is 1 (not 0).
     */
    static onboardingRoutes = [
        'onboarding.sandbox',
        'onboarding.user',
        'onboarding.data-sources', 
        'onboarding.track-activity',
        'onboarding.processing'
    ]

    /**
     * These routes are not onboarding routes but can be used while onboarding user.
     */
    static whiteListedRoutes = [
        'magic-login',
        'extension-success',
        'extension-error',
        'onboarding.browser-extension',
        'welcome',
        'nordigen-callback',
        'accountprocessing',
        'sellsy.oauth',
        'spendesk.oauth',
        'quickbooks.oauth',
        'bridge.oauth',
        'xero.oauth',
    ];

    /**
     * @param {VueRouter} router
     * @param {Store} store
     */
    constructor(router, store)
    {
        this.#router = router;
        this.#store = store;
    }

    setupRouter()
    {
        return this.#router.beforeResolve(this.#verifyCompanyState.bind(this));
    }

    /**
     * @param {import("vue-router").Route} to
     * @param {import("vue-router").Route} from
     */
    async #verifyCompanyState(to, from , next)
    {
        // Do not redirect when already on onboarding
        if(this.navigatingInOnboarding(to, from)){
            return next();
        }

        const onboardingStep = this.#store.getters["company/get"]?.onboardingStep;
        if(onboardingStep != -1){

            if (to.query.confirmEmail && onboardingStep === 0) {
                await this.#store.dispatch("company/update", {onboardingStep: 1});
                return next({
                    name: OnboardingGuard.onboardingRoutes[1],
                });
            }            
    
            const whiteListedRoutes = [...OnboardingGuard.whiteListedRoutes];
            whiteListedRoutes.push(OnboardingGuard.onboardingRoutes[onboardingStep]);
            if(
                onboardingStep != 0 &&
                whiteListedRoutes.indexOf(to.name) === -1
            ){
                return next({
                    name: OnboardingGuard.onboardingRoutes[onboardingStep],
                });
            }
        }

        return next();
    }

    navigatingInOnboarding(to, from)
    {
        if(
            from?.name && OnboardingGuard.onboardingRoutes.indexOf(from.name) !== -1
            && to?.name && OnboardingGuard.onboardingRoutes.indexOf(to.name) !== -1
        ){
            return true;
        }else{
            return false;
        }
    }

}
