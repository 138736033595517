import client from "./client";

export default {

    create(data) {
        return client.post("/api/offboardings", data);
    },

    update(id, data) {
        return client.put("/api/offboardings/" + id, data);
    },

    delete(id) {
        return client.delete("/api/offboardings/" + id);
    },

    start(id) {
        return client.patch("/api/offboardings/" + id + "/start");
    },

    can(userId) {
        return client.post("/api/offboardings/can", {
            user: userId,
        });
    },

};