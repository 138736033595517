import client from "./client";

const apiUrl = "/v2/apps"

export default {
  create(data) {
    return client.post("/api/app", data);
  },
  getFromWebsite(data) {
    return client.get("/api/app/getFromWebsite", {
      params: data
    });
  },
  apps(data) {
    return client.get("/api/apps", {
      params: data
    });
  },
  apiUrl: apiUrl,
};