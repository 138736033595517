"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const HydraCollectionFormat_1 = require("./formats/HydraCollectionFormat");
class GetCollectionResponse {
    constructor(response) {
        this.response = new HydraCollectionFormat_1.HydraCollectionFormat(response);
    }
    getCollection() {
        return this.response.getCollection();
    }
    getTotal() {
        return this.response.getTotal();
    }
    getFirstPage() {
        return this.response.getFirstPage();
    }
    getPreviousPage() {
        return this.response.getPreviousPage();
    }
    getCurrentPage() {
        return this.response.getCurrentPage();
    }
    getNextPage() {
        return this.response.getNextPage();
    }
    getLastPage() {
        return this.response.getLastPage();
    }
}
exports.default = GetCollectionResponse;
