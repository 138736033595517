var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: _vm.isCollapse ? "menu__title--line" : "menu__title" },
    [
      !_vm.isCollapse && _vm.title
        ? _c("span", [_vm._v(_vm._s(_vm.title))])
        : !_vm.isCollapse && !_vm.title
        ? _c("span", {
            staticClass: "w-100",
            staticStyle: { "border-top": "1px solid #6B7280" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }