import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import permissions from './services/Security/Permissions';
import PermissionRouterGuard from './services/Security/PermissionRouterGuard';
import OnboardingGuard from './services/Onboarding/OnboardingGuard';
import analytics from '@app/js/services/Analytics/Segment';

Vue.use(VueRouter);

function onboardingGuard(to, from, next) {
    window.localStorage.setItem('onboarded', 0);
    next();
};

const router = new VueRouter({
    // Use to scroll top when clicking a new page
    scrollBehavior (to, from, savedPosition) {
          return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/login',
            name: 'login',
            meta: {
                publicAnalytics: true,
            },
            component: () => import(/* webpackChunkName: "public" */ '@app/js/views/Security/Login'),
        },
        {
            path: '/password',
            name: 'password',
            component: () => import('@app/js/views/Security/ForgottenPassword'),
        },
        {
            path: '/extension',
            name: 'extension',
            component: () => import('@app/js/views/Extension/Connect'),
        },
        {
            path: '/password/confirm/:token',
            name: 'passwordconfirm',
            component: () => import('@app/js/views/Security/ResetPasswordConfirm'),
        },
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName:"public" */ '@app/js/views/Security/Register'),
            meta: {
                publicAnalytics: true
            },
            beforeEnter: onboardingGuard,
        },
        {
            path: '/register/confirm',
            name: 'registerconfirmed',
            component: () => import('@app/js/views/Security/RegisterConfirmed'),
        },
        {
            path: '/invitation/welcome',
            name: 'welcome',
            component: () => import('@app/js/views/Security/Welcome'),
        },
        {
            path: '/account/deleted',
            name: 'account-deleted',
            component: () => import('@app/js/views/Account/AccountDeleted'),
        },
        {
            path: '/welcome',
            name: 'building',
            component: () => import('@app/js/views/Building'),
            meta: {
                publicAnalytics: true,
                requiresAuth: true
            },
            beforeEnter: onboardingGuard,
        },
        {
            path: '/',
            name: 'dashboard',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Dashboard'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_DASHBOARD,
            },
        },
        {
            path: '/tasks',
            name: 'tasks',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Tasks/List'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_TASKS,
            },
        },
        {
            path: '/licences',
            name: 'licences',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Licences/List'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/licence/:id',
            name:'licence',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Licences/Overview'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'overview',
                    name:'licence.overview',
                    component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/components/licence/tabs/OverviewTab'),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'users',
                    name:'licence.users',
                    component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/components/licence/tabs/UsersTab'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ACTIVITIES,
                    },
                },
                {
                    path: 'spend',
                    name:'licence.spend',
                    component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/components/licence/tabs/SpendTab'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_TRANSACTIONS,
                    },
                },
                {
                    path: 'advanced',
                    name:'licence.advanced',
                    component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/components/licence/tabs/AdvancedTab'),
                    meta: {
                        requiresAuth: true,
                    }
                },
            ]
        },
        {
            path: '/renewals',
            name: 'renewal',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Renewals/Renewals'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_RENEWALS,
            },
        },
        {
            path: '/assign-renewals',
            name: 'assign-renewals',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Renewals/AssignRenewals'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_RENEWALS,
            },
        },
        {
            path: '/assign-first-payment',
            name: 'assign-first-payment',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Licences/AssignFirstPayment'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/onboarding/password',
            name: 'onboardingpassword',
            component: () => import('@app/js/views/Onboarding/Password'),
            meta: {
                requiresAuth: true,
            },
            beforeEnter: onboardingGuard,
        },
        { 
            path: '/onboarding/user',
            name:'onboarding.user', 
            component: () => import(/* webpackChunkName:"onboarding" */ '@app/js/views/Onboarding/User'),
            meta: { 
                requiresAuth: true,
                layout: 'onboarding', 
            },
        },
        { 
            path: '/onboarding/data-sources', 
            name:'onboarding.data-sources', 
            component: () => import(/* webpackChunkName:"onboarding" */ '@app/js/views/Onboarding/DataSources'),
            meta: { 
                requiresAuth: true,
                layout: 'onboarding', 
            },
        },
        { 
            path: '/onboarding/track-activity',
            name:'onboarding.track-activity', 
            component: () => import(/* webpackChunkName:"onboarding" */ '@app/js/views/Onboarding/TrackActivity'),
            meta: { 
                requiresAuth: true,
                layout: 'onboarding',
            },
        },
        { 
            path: '/onboarding/processing', 
            name:'onboarding.processing', 
            component: () => import(/* webpackChunkName:"onboarding" */ '@app/js/views/Onboarding/Processing'),
            meta: { 
                requiresAuth: true,
                layout: 'onboarding', 
            },
        },
        { 
            path: '/onboarding/browser-extension',
            name: 'onboarding.browser-extension',
            component: () => import(/* webpackChunkName:"onboarding" */ '@app/js/views/Onboarding/BrowserExtension'),
            meta: { 
                layout: 'security', 
            },
        },
        {
            path: '/payments',
            name: 'transactions',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Transactions/List'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_ADMIN_SECTIONS
            },
        },
        {
            path: '/rules/:id',
            name: 'rules',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Transactions/Rules'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_ADMIN_SECTIONS
            },
        },
        {
            path: '/team',
            name: 'team',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Team/Team'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/team/new',
            name: 'addEmployee',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Team/AddEmployee'),
            meta: {
                requiresAuth: true,
                permission: permissions.EDIT_USER,
            },
        },
        {
            path: '/team/employee/:id',
            name: 'employee',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Team/Employee'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/spend',
            name: 'spend',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Spends/Spends'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_TRANSACTIONS,
            },
        },
        {
            path: '/sources',
            component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Sources/Sources'),
            name: 'source',
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_ADMIN_SECTIONS,
            },
        },
        {
            path: '/sources/plaid/oauth',
            component: () => import('@app/js/views/Sources/Plaid/Oauth'),
            name: 'plaid-oauth',
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/sources/nordigen/callback',
            component: () => import('@app/js/views/Sources/Nordigen/Callback'),
            name: 'nordigen-callback',
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/slack/callback',
            component: () => import('@app/js/views/Account/SlackCallback'),
            name: 'slack-callback',
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/Settings'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'profile',
                    name: 'settings.profile',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingProfile'),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: 'users',
                    name: 'settings.users',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingUsers'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_USERS_SECTION,
                    },
                },
                {
                    path: 'company',
                    name: 'settings.company',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingCompany'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
                {
                    path: 'teams',
                    name: 'settings.teams',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingDepartment'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_TEAMS_SECTION,
                    },
                },
                {
                    path: 'notifications',
                    name: 'settings.notifications',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingNotifications'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
                {
                    path: 'billing',
                    name: 'settings.billing',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingBilling'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
                {
                    path: 'amortization',
                    name: 'settings.amortization',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingAmortization'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
                {
                    path: 'activities',
                    name: 'settings.activities',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingActivities'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
                {
                    path: 'applications',
                    name: 'settings.applications',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingApplications'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
                {
                    path: 'browser-extension',
                    name: 'settings.browser-extension',
                    component: () => import(/* webpackChunkName:"settings" */ '@app/js/views/Settings/SettingBrowserExtension'),
                    meta: {
                        requiresAuth: true,
                        permission: permissions.SEE_ADMIN_SECTIONS,
                    },
                },
            ]
        },
        {
            path: '/account/processing',
            name: 'accountprocessing',
            component: () => import('@app/js/views/ProcessingAccount'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/plans',
            name: 'plans',
            component: () => import('@app/js/views/Billing/Plans'),
            meta: {
                requiresAuth: true,
            },
        },
        { 
            path: '/login/:access/:refresh',
            name: 'magic-login',
            component: () => import('@app/js/views/Security/JWTLinkLogin'),
        },
        { 
            path: '/installation/success',
            name: 'extension-success',
            component: () => import('@app/js/views/Extension/Success'),
        },
        { 
            path: '/installation/error',
            name: 'extension-error',
            component: () => import('@app/js/views/Extension/Error'),
        },
        {
            path: '/offboarding/checkup/:userId/assign-new-managers',
            name: 'offboarding-assign-new-managers',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Processes/Offboarding/AssignNewManagers'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_PROCESSES,
            },
        },
        {
            path: '/offboarding/checkup/:userId/assign-missing-managers',
            name: 'offboarding-assign-missing-managers',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Processes/Offboarding/AssignMissingManagers'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_PROCESSES,
            },
        },
        {
            path: '/offboarding/checkup/:userId/invite-inactive-managers',
            name: 'offboarding-invite-inactive-managers',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Processes/Offboarding/InviteInactiveManagers'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_PROCESSES,
            },
        },
        {
            path: '/processes',
            name: 'processes-list',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Processes/List'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_PROCESSES,
            },
        },
        {
            path: '/processes/:id',
            name: 'process-overview',
            component: () => import(/* webpackChunkName:"authenticated" */ '@app/js/views/Processes/Overview'),
            meta: {
                requiresAuth: true,
                permission: permissions.SEE_PROCESSES,
            },
        },
        {
            path: '/google-workspace/callback',
            name: 'gworkspace-connect',
            component: () => import('@app/js/views/Sources/GoogleWorkspaceCallback'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/impersonate/:email',
            name: 'impersonate',
            component: () => import('@app/js/views/Security/Impersonate'),
            meta: {
                requiresAuth: true,
                layout: "security",
            },
        },
        {
            path: '/subscription/checkout',
            name: 'subscription.checkout',
            component: () => import('@app/js/views/Billing/Checkout'),
        },
        {
            path: '/oauth/sellsy',
            name: 'sellsy.oauth',
            component: () => import('@app/js/views/Sources/Sellsy/OAuthCallback'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/oauth/spendesk',
            name: 'spendesk.oauth',
            component: () => import('@app/js/views/Sources/Spendesk/OAuthCallback'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/oauth/quickbooks',
            name: 'quickbooks.oauth',
            component: () => import('@app/js/views/Sources/Quickbooks/OAuthCallback'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/oauth/xero',
            name: 'xero.oauth',
            component: () => import('@app/js/views/Sources/Xero/OAuthCallback'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/oauth/bridge',
            name: 'bridge.oauth',
            component: () => import('@app/js/views/Sources/Bridge/Callback'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/forbidden',
            name: 'system.forbidden',
            component: () => import('@app/js/views/Error/Forbidden'),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "*",
            name: 'notfound',
            component: () => import('@app/js/views/Error/NotFound'),
        },
    ],
    mode: 'history'
});

router.beforeEach((to, from, next) => {

    store.commit('closedAMenu');

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        if (store.getters["user/isAuthenticated"]) {
            analytics.page(to.name ?? to.path);
            next();
            // if not, redirect to login page.
        } else {
            next({
                path: "/login"
            });
        }
    } else {
        next(); // make sure to always call next()!
    }
});

new OnboardingGuard(router, store).setupRouter();
new PermissionRouterGuard(router).setupRouter();

export default router;