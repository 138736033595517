import OffboardingAPI from "../api/offboarding";

const 

  CREATING          = "CREATING",
  CREATING_SUCCESS  = "CREATING_SUCCESS",
  CREATING_ERROR    = "CREATING_ERROR",

  STARTING          = "STARTING",
  STARTING_SUCCESS  = "STARTING_SUCCESS",
  STARTING_ERROR    = "STARTING_ERROR",

  PREPARING         = "PREPARING",
  PREPARING_SUCCESS = "PREPARING_SUCCESS",
  PREPARING_ERROR   = "PREPARING_ERROR";

export default {

    namespaced: true,

    state: {
        isLoading:      false,
        errors:         null,
        preparing:      null,
        offboardings:   [],
    },

    getters: {

        isLoading(state) {
            return state.isLoading;
        },

        hasErrors(state) {
            return state.errors !== null;
        },

        errors(state) {
            return state.errors;
        },

        preparing(state) {
            return state.preparing;
        },

        getNextPreparationRoute: (state) => (userId) => {

            if(!state.preparing){
                return;
            }

            const orderedSteps = {
                offboardingAlreadyExists: {
                    error: "offboarding",
                    route: () => {
                        return {
                            name: "process-overview",
                            params: {
                                id: state.preparing.errors.offboarding.id,
                            },
                        };
                    },
                },
                assignNewManagers: {
                    error: "licencesManaged",
                    route: {
                        name: "offboarding-assign-new-managers",
                        params: {
                            userId: userId,
                        },
                    },
                },
                assignMissingManagers: {
                    error: "licencesWithoutManager",
                    route: {
                        name: "offboarding-assign-missing-managers",
                        params: {
                            userId: userId,
                        },
                    },
                },
                inviteInactiveManagers: {
                    error: "inactiveManagers",
                    route: {
                        name: "offboarding-invite-inactive-managers",
                        params: {
                            userId: userId,
                        },
                    },
                },
            };

            let route;
            const availableErrors = Object.keys(state.preparing.errors);
            Object.keys(orderedSteps).some(step => {

                const nextStep = availableErrors.some(error => orderedSteps[step].error === error);

                if(nextStep){
                    route = (orderedSteps[step].route instanceof Function) ? orderedSteps[step].route() : orderedSteps[step].route;
                }
                return route;
            });

            return route;
        },

    },

    mutations: {

        [PREPARING](state)
        {
            state.preparing = {};
        },

        [PREPARING_SUCCESS](state)
        {
            state.preparing = null;
        },

        [PREPARING_ERROR](state, error)
        {
            if(error.response.status === 403){
                state.preparing.errors = error.response.data;
            }
        },

        [CREATING](state)
        {
            state.isLoading = true;
        },

        [CREATING_SUCCESS](state)
        {
            state.isLoading = false;
        },

        [CREATING_ERROR](state)
        {
            state.isLoading = false;
        },

        [STARTING](state)
        {
            state.isLoading = true;
        },

        [STARTING_SUCCESS](state)
        {
            state.isLoading = false;
        },

        [STARTING_ERROR](state)
        {
            state.isLoading = false;
        },

    },

    actions: {

        async prepare({commit}, userId)
        {
            try {
                commit(PREPARING);
                const response = await OffboardingAPI.can(userId);
                commit(PREPARING_SUCCESS, response.data);

                return response.data;

            } catch (error) {
                commit(PREPARING_ERROR, error);
                return null;
            }
        },

        async create({ commit }, userId)
        {
            try {
                commit(CREATING);
                const response = await OffboardingAPI.create({
                    user: userId,
                });

                commit('process/FETCHING_ONE_SUCCESS', response.data, {
                    root: true,
                });
                commit(CREATING_SUCCESS);
                return response.data;

            } catch (error) {
                commit(CREATING_ERROR);
            }
        },

        async start({ commit }, id)
        {
            try {
                commit(STARTING);
                await OffboardingAPI.start(id);
                commit(STARTING_SUCCESS);

            } catch (error) {
                commit(STARTING_ERROR, error);
            }
        }

    },

}