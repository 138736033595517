<template>
    <div>
        <div 
            class="menu__item justify-space-between cpointer" 
            @click="handleCollapseGroup(group.icon)"
        >
            <div class="d-flex">
                <div v-if="group.icon" :class="'d-flex align-center justify-center'+((isCollapse) ? ' mr-0' : ' mr-3')">
                    <v-badge v-if="hasSubItemsDot" dot color="red-30">
                        <span style="color: white;font-size: 24px;" :class="'icon-'+group.icon"/>
                    </v-badge>
                    <span v-else style="color: white;font-size: 24px;" :class="'icon-'+group.icon"/>
                </div>
                <span v-if="group.title && !isCollapse" class="d-flex align-center fw-450">
                    {{ group.title }}
                </span>
            </div>
            <span
                :ref="'icon-chevron-'+group.icon"
                :class="'show-more__icon' + ((isCollapse)?' d-none':'')"
            >
                <icon-base
                    icon-name="icon-chevron-down"
                    color="white"
                >
                    <icon-chevron-down/>
                </icon-base> 
            </span>
        </div>
        <div 
            :ref="'sub-item-'+group.icon"
            :class="'items__collapse mt-1' + ((isCollapse)?' d-none':'')"
        >
            
            <div
                v-for="item in group.subItems"
                :key="item.title"
            > 
                <menu-item 
                    v-if="!item.permissions || isGranted(item.permissions)"
                    :item="item" 
                    :isCollapse="isCollapse"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import IconBase from '../../IconBase';
    import IconChevronDown from '../../icons/IconChevronDown';
    import menuItem from './MenuItem';
    import Security from '../../../Mixins/Security';

    export default {
        name: "menu-group",
        mixins: [Security],
        components: {
            IconBase,
            IconChevronDown,
            menuItem
        },
        props: {
            group: {
                type: Object,
                default: {},
            },
            isCollapse: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            handleCollapseGroup(id) {
                if(this.isCollapse) {
                    this.$emit('openMenu')
                }

                if(!this.$refs['icon-chevron-' + id].classList.contains('show-more__icon--open')) {
                    this.closeOtherGroup()
                }

                setTimeout(() => {
                    this.$refs['icon-chevron-' + id].classList.toggle('show-more__icon--open')
                    this.$refs['sub-item-' + id].classList.toggle('items__collapse--open')
                }, "0");
            },
            closeOtherGroup() {

                if(document.getElementsByClassName("show-more__icon--open").length > 0){
                    document.getElementsByClassName("show-more__icon--open")[0].classList.remove('show-more__icon--open')
                }
                if(document.getElementsByClassName("items__collapse--open").length > 0){
                    document.getElementsByClassName("items__collapse--open")[0].classList.remove('items__collapse--open')
                }
            }
        },
        computed: {
            hasSubItemsDot() { 
                return this.group.subItems.some((item) => item.hasDot)
            }   
        }
    }
    
</script>

<style lang="scss" scoped>
    .items {
        &__collapse {
            height: auto;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out;
            display: flex;
            flex-direction: column;
            gap: 4px;
            &--open {
                max-height: 356px;
            }
        }
    }

    .show-more__icon {
        transform: rotate(0deg);
        transition: transform 0.3s ease-out;
        &--open {
            transform: rotate(180deg);
        }
    }

    .menu {
        overflow: hidden;
        scrollbar-color: var(--v-gray-30-base) var(--v-gray-10-base);
        scrollbar-width: thin;
        &__item {
            padding: 4px 8px;
            color: white !important;
            display: flex;
            align-items: center;
            &:hover {
                border-radius: 4px;
                background: var(--v-gray-30-base);
            }
            &--active {
                border-radius: 4px;
                background: var(--v-gray-30-base);
            }
        }
    }
</style>