import client from "./client";

export default {

    getAuthUrl() {
        return client.get("/api/quickbooks/authorize");
    },
    
    getAccessToken(code, realmId) {
        return client.get(
            "/api/quickbooks/access-token",
            {
                params: {
                    code: code,
                    realmId: realmId,
                },
            }
        );
    },

    deleteToken() {
        return client.delete("/api/quickbooks/token");
    },

    get() {
        return client.get("/api/quickbooks/token");
    },

};
