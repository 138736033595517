<template>
    <div :class="'menu__item cpointer' + ((item.routeName == actualRoute)? ' menu__item--active':'') + (item.icon ? '' : ' ml-9')">
        <v-tooltip right content-class="right-tip" nudge-left="-27">
            <template v-slot:activator="{ on }">
                <div v-on="on" v-if="item.icon" :class="'d-flex align-center justify-center' + (isCollapse ? ' mr-0' : ' mr-3')">
                    <v-badge 
                        v-if="item.hasDot" 
                        dot 
                        color="red-30" 
                        offset-x="4" 
                        offset-y="4" 
                    >
                        <span :class="'f-2 white--text icon-' + item.icon" />
                    </v-badge>
                    <span v-else :class="'f-2 white--text icon-' + item.icon" />
                </div>
                
            </template>
            <span class="m-10">{{ item.title }}</span>
        </v-tooltip>
        <div v-if="!isCollapse" class="relative w-100 fw-450">
            <v-badge 
                v-if="item.hasDot && !item.icon"
                dot 
                color="red-30" 
                offset-x="0" 
                offset-y="4" 
            >
                {{ item.title }}
            </v-badge>
            <span v-else>
                {{ item.title }}
            </span>
            <span v-if="item.new" class="info--text absolute new-feature-menu">{{ $t('commons.new') }}</span>
        </div>
    </div>
</template>
<script>

    export default {
        name: "menu-item-content",
        props: {
            item: {
                type: Object,
                default: {},
            },
            isCollapse: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            actualRoute() {
                return  this.$route.name
            }
        },
    }
</script>
