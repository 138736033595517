var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "d-flex flex-column align-center justify-space-between",
      attrs: { id: "onboarding-content" },
    },
    [
      _c("stepper", {
        staticClass: "mt-6",
        attrs: {
          steps: _vm.steps,
          actualStep: _vm.actualStep,
          transitionDuration: _vm.steps[_vm.actualStep - 1]
            ? _vm.steps[_vm.actualStep - 1].animationDuration
            : 2000,
        },
      }),
      _vm._v(" "),
      _vm.isOnboardingUrl
        ? _c(
            "transition",
            { attrs: { name: "slide", mode: "out-in" } },
            [_c("router-view")],
            1
          )
        : _c("loader"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "onboarding-content__logo w-fit mb-10 mt-6" },
        [
          _c("v-img", {
            attrs: { "max-height": "24", "max-width": "143", src: _vm.logoSrc },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("snackbar", { staticClass: "onboarding-content__snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }