import client from "./client";

export default {

    getAuthUrl() {
        return client.get("/v2/spendesk/authorize");
    },
    
    getAccessToken(code) {
        return client.get("/v2/spendesk/access-token", {
            params: {
                code: code
            }
        });
    },

    list() {
        return client.get("/v2/spendesk/connections");
    },

    get() {
        return client.get("/v2/spendesk");
    },

    deleteToken(id) {
        return client.delete("/v2/spendesk/connections/" + id);
    },

};
