<template>
    <v-app v-if="isAuthenticated">
        <div 
            v-if="isAMenuOpened" 
            :style="protectorStyle"
            class="protector"
            @click.stop
        ></div>
        <v-system-bar v-if="company.sandbox"
            app
            window
            color="blue-80"
            class="gray-0--text"
            id="system-bar"
            height="40"
        >
            <v-spacer></v-spacer>
            <i18n path="sandbox.system-callout" tag="div">
                <template v-slot:really>
                    <i class="text-lowercase">{{ $t('commons.really') }}</i>
                </template>
                <template v-slot:clickHere>
                    <a @click.stop="continueOnboarding" class="font-weight-bold text-lowercase underline">{{ $t('commons.buttons.click-here') }}</a>
                </template>
            </i18n>
            <v-spacer></v-spacer>
            <sandbox-to-real-data-modal @mounted="setOpenChooseSandboxOrRealDataModal" v-model="openSandboxPopup" :step="this.step"></sandbox-to-real-data-modal>
        </v-system-bar>
        
        <trial-system-topbar></trial-system-topbar>

        <main-menu></main-menu>

        <topbar></topbar>

        <!-- Sizes your content based upon application components -->
        <v-main>
            <v-container 
                fluid 
                style="position: relative;" 
                class="h-100 d-block align-start ma-0 py-0 px-0 mb-0">
                <v-alert 
                    v-if="company && company.isProcessing && ['accountprocessing'].indexOf($route.name) < 0"
                    class="mt-10 mb-0 mx-12"
                    border="left"
                    type="info" 
                    icon="mdi-information-outline"
                    color="info"
                    text>
                    {{ $t('commons.alerts.processing') }}
                </v-alert>
                <router-view v-if="isAccessAllowed"></router-view>
                <manage-account v-else :subscriptionState="subscriptionState"></manage-account>
                <unsaved-change-modal></unsaved-change-modal>
                <snackbar></snackbar>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
    
    import {mapGetters} from 'vuex';
    import mixin from '../Mixins/mixin.js';
    import Security from '../Mixins/Security.js';
    import Snackbar from "../components/Snackbar"
    import MainMenu from "../components/layout/menu/Menu";
    import Topbar from "../components/Topbar";
    import TrialSystemTopbar from "../components/systemTopbar/Trial";
    import ManageAccount from "../components/subscription/ManageAccount";
    import SandboxToRealDataModal from "../components/modal/SandboxToRealDataModalAndConfirmEmail";
    import UnsavedChangeModal from "../components/modal/UnsavedChange.vue";
    import permissions from '../services/Security/Permissions.js';
    import dsManager from '../services/DataSource/FinancialDataSourcesManager';

    export default {
        name: "layout",
        mixins: [mixin, Security],
        components: {
            MainMenu,
            Topbar,
            ManageAccount,
            Snackbar,
            SandboxToRealDataModal,
            TrialSystemTopbar,
            UnsavedChangeModal
        },
        data() {
            return {
                langs: ['en', 'fr'],
                snackbar: false,
                background: false,
                openSandboxPopup: false,
                isFirstEmailSend: false,
                step: 1
            }
        },
        async created(){

            if(this.isGranted(permissions.SEE_ADMIN_SECTIONS)){
                dsManager.fetch();
            }
        },
        computed: {
            protectorStyle() {
                return {
                    "z-index": this.$store.getters.getZindexProtector
                };
            },
            isAMenuOpened(){
                return this.$store.getters.isAMenuOpened
            },
            isAccessAllowed(){
                return ['active', 'in_trial'].includes(this.subscriptionState);
            },
            ...mapGetters('subscription', [
                'isAppSumo',
            ]),
            ...mapGetters('user', {
                user : 'get',
                isAuthenticated : 'isAuthenticated',
            }),
            ...mapGetters('company', {
                company : 'get',
            }),
            ...mapGetters('subscription', {
                subscriptionState: 'getState'
            }),
            ...mapGetters(['hasRBACError']),
            isAuthenticated() {
                return this.$store.getters['user/isAuthenticated']
            },
            
        },
        watch: {
            '$route' (to, from) {
                this.$store.commit('changeTopNav', {});
            },
            hasRBACError: function(hasError) {

                if(hasError){
                    this.$router.push({name:"system.forbidden"});
                }
            },
        },

        methods: {

            setOpenChooseSandboxOrRealDataModal(){
                if(window.localStorage.getItem('onboarded') === "0"){
                    this.openSandboxPopup = true;
                    window.localStorage.removeItem('onboarded');
                }
            },

            async sendEmail() {
                if(!this.isFirstEmailSend) {
                    await this.$store.dispatch("user/resendConfirmation", {user: this.user});
                    this.isFirstEmailSend = true
                }
            },

            async continueOnboarding() {
                if(!this.user.isAccountValidated) {
                    this.sendEmail();
                    this.step = 2;
                } else {
                    await this.$store.dispatch("company/update", {onboardingStep: 1});
                    this.$router.push({
                        name: 'onboarding.user',
                    });
                }
                this.openSandboxPopup=true
            }

        },
    }
</script>
<style lang="scss" scoped>
    .protector{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    :deep(.listitem){
        .v-list-item__title{
            color: var(--v-gray-90-base);
        }
        &:active, &.active, &:hover{
            background-color: var(--v-gray-30-base) !important;
            .v-list-item__title{
                color: #FFFFFF !important;
                font-weight: 600;
            }
        }
    } 
    .v-main>.v-main__wrap{
        display: flex;
    }
    #system-bar {
        box-sizing: border-box;
        box-shadow: 0px -1px 0px 0px var(--v-blue-40-base) inset;
        z-index: 5;
    }
</style>