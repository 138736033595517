/**
 * This code is also used on our Webflow marketing website:
 * - Any modification should be reported in the Webflow script
 * - This file should not use any external library as it not possible to import them in Webflow
 * 
 * Best option may be to package this code as an independant npm package:
 * - It'll build a ready to production code version, including potential dependencies
 * - It could be hosted on unpkg.com, jsdelivr.com, cdnjs.com so Webflow can use it
 */
export default class UtmManager
{
    #cookieMaxAge = 7776000; // 90 days
    #cookie = [];
    #utm = {};
    #utmPrefix = "utm_";
    // domain should be changed if you want to test on localhost (or other)
    #cookieDomain = "licence.one";

    #authorizedParameters = [
        "source",
        "medium",
        "campaign",
        "term",
        "content",
    ];

    /**
     * Parse cookies to get previous UTM loaded.
     */
    #parseCookies() {
        const utm = Object.fromEntries(document.cookie.split('; ').map(v=>v.split(/=(.*)/s).map(decodeURIComponent))).utm;

        if(utm !== undefined){
            try{
                this.#cookie = JSON.parse(utm);
            }catch(error){
                this.#resetUtmCookie();
            }
        }
    }

    /**
     * Load UTMs from query parameters.
     * e.g: ?utm_source=share&utm_medium=member_android
     */
    #loadFromQuery() {
        const params = new URLSearchParams(window.location.search);
        for(const [name, value] of params.entries()){

            for(const authorizedParameter of this.#authorizedParameters){
                if(name === this.#utmPrefix + authorizedParameter){
                    this.#utm[authorizedParameter] = value;
                }
            }
        }
    }

    /**
     * Store UTMs into cookie.
     */
    #store() {
        if(Object.keys(this.#utm).length === 0){
            return;
        }

        if(this.#cookie === undefined){
            this.#cookie = [];
        }

        let alreadyStored = false;
        for (const utm of this.#cookie){
            // utm is copied because it's a reference variable so updating it modified this.#cookie
            const utmCopy = {...utm};
            delete utmCopy.date;

            if(JSON.stringify(utmCopy) === JSON.stringify(this.#utm)){
                alreadyStored = true;
                break;
            }
        }

        if(!alreadyStored){
            const now = new Date();
            this.#cookie.push({
                ...this.#utm,
                date: now.toISOString(),
            });
        }

        this.#saveUtmCookie(this.#cookie, this.#cookieMaxAge);
    }

    #loadReferrer() {

        if(!document.referrer){
            return;
        }

        // Referers from our domains should not be saved
        const referer = new URL(document.referrer);
        if(referer.hostname.includes(this.#cookieDomain)){
            return;
        }

        this.#utm['referrer'] = document.referrer
    }

    /**
     * @param {Object} cookie
     * @param {Number} maxAge
     */
    #saveUtmCookie(cookie, maxAge) {
        document.cookie = "utm=" + JSON.stringify(cookie) + "; max-age=" + maxAge.toString() + "; domain=." + this.#cookieDomain + "; path=/";
    }

    #resetUtmCookie() {
        this.#saveUtmCookie({}, 0);
    }

    /**
     * To avoid any crash, the whole applicative code is encapsulated into a try/catch statement.
     */
    boot() {

        try{
            this.#parseCookies();
            this.#loadReferrer();
            this.#loadFromQuery();
            this.#store();
        }catch(e){
            console.error(e);
        }
    }

}
