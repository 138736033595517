import client from "./client";

export default {

    getAuthUrl() {
        return client.get("/api/sellsy/authorize");
    },
    
    getAccessToken(code) {
        return client.get("/api/sellsy/access-token/" + code);
    },

    deleteToken() {
        return client.delete("/api/sellsy/token");
    },

    get() {
        return client.get("/api/sellsy/token");
    },

};
