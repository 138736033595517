import NotificationAPI from "../api/notification";

const 
  UPDATING          = "UPDATING",
  UPDATING_SUCCESS  = "UPDATING_SUCCESS",
  UPDATING_ERROR    = "UPDATING_ERROR",
  FETCHING          = "FETCHING",
  FETCHING_SUCCESS  = "FETCHING_SUCCESS",
  FETCHING_ERROR    = "FETCHING_ERROR";

export default {

    namespaced: true,

    state: {
        isLoading:          false,
        isSlackConnected:   null,
        error:              null,
        installationError:  null,
    },

    getters: {

        isLoading(state) {
            return state.isLoading;
        },

        hasErrors(state) {
            return state.error !== null;
        },

        error(state) {
            return state.error;
        },

        installationError(state) {
            return state.installationError;
        },

        isSlackConnected(state) {
            return state.isSlackConnected;
        }

    },

    mutations: {

        [UPDATING](state)
        {
            state.isLoading = true;
        },

        [UPDATING_SUCCESS](state)
        {
            state.isLoading = false;
        },

        [UPDATING_ERROR](state, error)
        {
            state.isLoading         = false;
            state.installationError = error;
        },

        [FETCHING](state)
        {
            state.isLoading         = true;
            state.isSlackConnected  = false;
        },

        [FETCHING_SUCCESS](state, isSlackConnected)
        {
            state.isLoading         = false;
            state.isSlackConnected  = isSlackConnected;
        },

        [FETCHING_ERROR](state, error)
        {
            state.isLoading         = false;
            state.isSlackConnected  = false;
            state.error             = error;
        },

    },

    actions: {
        async slack({commit}){
            commit(FETCHING);
            
            try {

                let response = await NotificationAPI.slack();
                commit(FETCHING_SUCCESS, response.data);

                return response.data;

            } catch (error) {
                commit(FETCHING_ERROR, error);
                return null;
            }
        },
        async update({commit}, payload)
        {
            commit(UPDATING);

            try {

                let response = await NotificationAPI.update(payload);
                commit(UPDATING_SUCCESS, response.data);

                return response.data;

            } catch (error) {
                commit(UPDATING_ERROR, error);
                return null;
            }

        },
        async unsetSlack({commit})
        {
            commit(UPDATING);

            try {

                let response = await NotificationAPI.unsetSlack();
                commit(UPDATING_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(UPDATING_ERROR, error);
                return null;
            }

        }

    },

}