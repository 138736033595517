import router from '../../router';

/**
 * Used to merge some specific app analytics with marketing website analytics (e.g: /register page view).
 * Should not be used to record analytics from the whole app as it will bring confusion to marketing website analytics.
 */
class Matomo {

    setup() {
        if(this.#isAvailable()){
            this.#setupRouterGuard();
            this.#setupTagManager();
        }
    }

    #isAvailable(){
        return process.env.MATOMO_HOST && process.env.MATOMO_CONTAINER_ID;
    }

    #setupTagManager() {
        const _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        const d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=process.env.MATOMO_HOST + '/js/container_' + process.env.MATOMO_CONTAINER_ID + '.js'; s.parentNode.insertBefore(g,s);
    }

    #setupRouterGuard() {

        router.onReady(() => this.#setMTMEnabled(router.currentRoute));

        router.beforeEach((to, from, next) => {
            this.#setMTMEnabled(to);
            next();
        });
    }

    #setMTMEnabled(route) {
        window.MTMEnabled = route.meta?.publicAnalytics ?? false;
    }

};

export default new Matomo();