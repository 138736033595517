var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isGranted(_vm.permissions.SEE_ADMIN_SECTIONS)
    ? _c("l1-modal", {
        attrs: {
          persistent: _vm.persistent,
          needPadding: false,
          actions: _vm.actions,
          width: "923px",
          needBorderRadius: "",
          noTitle: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "opener",
              fn: function () {
                return [_vm._t("openerModal")]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "d-flex trial-modal" }, [
                    _c("div", { staticClass: "trial-modal__info relative" }, [
                      _c("img", {
                        staticClass: "trial-modal__illu",
                        attrs: { src: _vm.subscriptionModalImg },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "trial-info d-flex flex-column justify-space-between border-bottom py-14 px-10",
                        },
                        [
                          _c("span", [
                            _c("h2", { staticClass: "fw-7 f-3 mb-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "company.subscription." +
                                      _vm.subscriptionModalState
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "f-5 mb-0" }, [
                              _vm._v(
                                _vm._s(_vm.$t("company.subscription.desc"))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "mb-6" }, [
                            _c("h3", { staticClass: "f-5 fw-6 mb-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("company.subscription.link.title")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "f-6 pb-2" }, [
                              _vm._v(
                                _vm._s(_vm.$t("company.subscription.link.desc"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class:
                                  "trial-modal__link cpointer d-flex align-center justify-center",
                                on: {
                                  mouseover: function ($event) {
                                    _vm.isLinkHover = true
                                  },
                                  mouseleave: function ($event) {
                                    _vm.isLinkHover = false
                                  },
                                  click: _vm.copyText,
                                },
                              },
                              [
                                !_vm.checkoutUrl
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        width: 2,
                                        size: 23,
                                        color: "grey",
                                      },
                                    })
                                  : _vm.isLinkCopied
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-center",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("commons.buttons.copied")
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : !_vm.isLinkHover
                                  ? _c(
                                      "span",
                                      { staticClass: "d-block truncate" },
                                      [_vm._v(_vm._s(_vm.checkoutUrl))]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-center",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "commons.buttons.copy-link"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", {
                                          class: "f-2 gray-50--text icon-copy",
                                        }),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "trial-testimonial d-flex align-center justify-center pa-10",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "trial-testimonial__photo" },
                            [_c("img", { attrs: { src: _vm.victorImg } })]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pt-2 pb-0 d-flex justify-center flex-column",
                            },
                            [
                              _c("img", {
                                staticClass: "mb-2 trial-testimonial__logo",
                                attrs: { src: _vm.sellsyImg },
                              }),
                              _vm._v(" "),
                              _c("p", {
                                staticClass: "mb-2 f-6",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("company.trial.ended.testi.text")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "grey-5--text f-6" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("company.trial.ended.testi.author")
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "trial-modal__payment border-left py-8 px-6",
                      },
                      [
                        _c("tab-system", {
                          staticClass: "mb-6 w-fit",
                          attrs: {
                            defaultValue: _vm.billingPeriod,
                            tabs: _vm.types,
                          },
                          model: {
                            value: _vm.billingPeriod,
                            callback: function ($$v) {
                              _vm.billingPeriod = $$v
                            },
                            expression: "billingPeriod",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex gap-4 mb-6" },
                          [
                            _c("large-checkbox", {
                              attrs: {
                                id: "individual",
                                isActive: _vm.activeChoice == "individual",
                              },
                              on: {
                                updateChoice: function ($event) {
                                  _vm.activeChoice = "individual"
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "mb-0 f-5 fw-6" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "company.subscription.track.yourself"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-0 f-7 fw-4 gray-40--text",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.billingPeriod ==
                                                        "year"
                                                        ? _vm.totalIndividualYearlyPrice /
                                                            12
                                                        : _vm.totalIndividualMonthlyPrice *
                                                            12,
                                                      _vm.getSystemCurrency()
                                                    )
                                                  ) +
                                                  "\n                                    " +
                                                  _vm._s(
                                                    _vm.billingPeriod == "year"
                                                      ? _vm.$t(
                                                          "billing.account.usage.listing.by-month"
                                                        )
                                                      : _vm.$t(
                                                          "billing.account.usage.listing.by-year"
                                                        )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "mb-0 f-4 fw-7" },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      _vm.billingPeriod ==
                                                        "year"
                                                        ? _vm.totalIndividualYearlyPrice
                                                        : _vm.totalIndividualMonthlyPrice,
                                                      _vm.getSystemCurrency()
                                                    )
                                                  ) +
                                                  "\n                                    " +
                                                  _vm._s(
                                                    _vm.billingPeriod == "year"
                                                      ? _vm.$t(
                                                          "billing.account.usage.listing.by-year"
                                                        )
                                                      : _vm.$t(
                                                          "billing.account.usage.listing.by-month"
                                                        )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1916748832
                              ),
                            }),
                            _vm._v(" "),
                            _vm.numberOfTrackedEmployees > 1
                              ? _c("large-checkbox", {
                                  attrs: {
                                    id: "employees",
                                    isActive: _vm.activeChoice == "employees",
                                  },
                                  on: {
                                    updateChoice: function ($event) {
                                      _vm.activeChoice = "employees"
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c(
                                              "p",
                                              { staticClass: "mb-0 f-5 fw-6" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "company.subscription.track.employees",
                                                      _vm.numberOfTrackedEmployees,
                                                      {
                                                        count:
                                                          _vm.numberOfTrackedEmployees,
                                                      }
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 f-7 fw-4 gray-40--text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          _vm.billingPeriod ==
                                                            "year"
                                                            ? _vm.totalYearlyPrice /
                                                                12
                                                            : _vm.totalMonthlyPrice *
                                                                12,
                                                          _vm.getSystemCurrency()
                                                        )
                                                      ) +
                                                      "\n                                    " +
                                                      _vm._s(
                                                        _vm.billingPeriod ==
                                                          "year"
                                                          ? _vm.$t(
                                                              "billing.account.usage.listing.by-month"
                                                            )
                                                          : _vm.$t(
                                                              "billing.account.usage.listing.by-year"
                                                            )
                                                      ) +
                                                      "                                    \n                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "mb-0 f-4 fw-7",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          _vm.billingPeriod ==
                                                            "year"
                                                            ? _vm.totalYearlyPrice
                                                            : _vm.totalMonthlyPrice,
                                                          _vm.getSystemCurrency()
                                                        )
                                                      ) +
                                                      "\n                                    " +
                                                      _vm._s(
                                                        _vm.billingPeriod ==
                                                          "year"
                                                          ? _vm.$t(
                                                              "billing.account.usage.listing.by-year"
                                                            )
                                                          : _vm.$t(
                                                              "billing.account.usage.listing.by-month"
                                                            )
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1856498944
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "grey-8--text mb-8" },
                          [
                            _vm._l(_vm.billingItems, function (item) {
                              return _c(
                                "span",
                                { key: item.label },
                                [
                                  !item.isDisabled
                                    ? _c("billing-item", {
                                        attrs: {
                                          label: item.label,
                                          price: item.price,
                                          oldPrice: item.oldPrice ?? null,
                                          descPrice: item.descPrice ?? null,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between mt-4",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-left grey-8--text f-4 fw-7",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("billing.total")))]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "text-right" }, [
                                  _c(
                                    "span",
                                    { staticClass: "grey-8--text f-4 fw-7" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatNumber(
                                            _vm.priceTotal,
                                            _vm.getSystemCurrency()
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("billing.excl-vat"))),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-lg mb-2 white--text",
                            attrs: {
                              color: "grey-8",
                              loading: !_vm.checkoutUrl,
                              disabled: !_vm.checkoutUrl,
                              block: "",
                              elevation: "0",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.redirectTo(_vm.checkoutUrl)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("company.trial.ended.card.subscribe")
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-lg",
                            attrs: {
                              color: "gray-90",
                              block: "",
                              elevation: "0",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openChat()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("company.trial.ended.card.chat"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.opened,
          callback: function ($$v) {
            _vm.opened = $$v
          },
          expression: "opened",
        },
      })
    : _c("request-subscription-modal", {
        model: {
          value: _vm.opened,
          callback: function ($$v) {
            _vm.opened = $$v
          },
          expression: "opened",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }