<template>
    <v-navigation-drawer
        mobile-breakpoint="sm"
        app
        v-model="drawer"
        color="bg-menu"
        width="240"
        :mini-variant="mini"
        class="menu"
        mini-variant-width="72"
    >
        <div class="menu-wrapper">
            <div 
                :class="'pt-5 pb-2 d-flex ' + ((mini) ? 'justify-center' : 'px-3 justify-space-between') + ' align-center'" 
                style="max-height: 64px;"
            >
                <div
                    @mouseover="hover = true"
                >
                    
                    <router-link to="/">
                        <v-img contain :style="(mini) ? '' : 'margin-left: 0.5rem;'" position="left center" :width="(mini) ? '24' : '140'" :max-height="(mini) ? '24' : '28'" :src="(mini) ? logoMiniSrc : logoSrc"/>
                    </router-link>
                </div>
                <div
                    v-if="mini && hover"
                    class="pa-1 cpointer absolute menu__selected-logo"
                    @click="updateMiniValue"
                    @mouseleave="hover = false"
                >
                    <i class="icon-expend f-2 white--text d-flex"></i>
                </div>
                <div
                    v-if="!mini"
                    :class="'cpointer ' + ((expandHover) ? 'menu__selected-logo' : '') + ' pa-1'"
                    @mouseover="expandHover = true"
                    @mouseleave="expandHover = false"
                    @click="updateMiniValue"
                >
                    <i class="icon-collapse f-2 white--text d-flex"></i>
                </div>
            </div>
            <div
                v-for="(category, index) in menuItemsData"
                :key="category.id"
                :id="'menu-'+category.id"
                class="menu-item-category"
            >
                <div 
                    v-if="(!category.permissions || isGranted(category.permissions)) && !isCategoryEmpty(category.items)"
                    :class="'category' + ((mini)?' d-flex align-center justify-center flex-column': (' px-4 ' + ((category.id == 'workspace')?'pt-2':'pt-4')))"
                >
                    <menu-title v-if="index > 0" :title="category.title" :isCollapse="mini"/>
                    <div v-if="category.items" class="mt-2 d-flex flex-column gap-1">
                        <div
                            v-for="item in category.items"
                            :key="item.title"
                        >  
                            <menu-group 
                                v-if="item.subItems" 
                                :group="item" 
                                :isCollapse="mini"
                                @openMenu="mini = false"
                            />
                            <menu-item 
                                v-else-if="!item.permissions || isGranted(item.permissions)"
                                :item="item" 
                                :isCollapse="mini"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>
<script>
    import logoSrc from '@app/images/logo-white.svg';
    import logoMiniSrc from '@app/images/logo-mini.svg';
    import mixin from '../../../Mixins/mixin';
    import Security from '../../../Mixins/Security';
    import SearchEngine from '../../../services/SearchEngine';
    import IconBase from '../../IconBase';
    import IconChevronDown from '../../icons/IconChevronDown';
    import IconMenu from '../../icons/IconMenu';
    import IconMenuOpen from '../../icons/IconMenuOpen';
    import {mapGetters} from 'vuex';
    import permissions from '../../../services/Security/Permissions';
    import menuTitle from './MenuTitle';
    import menuItem from  './MenuItem';
    import menuGroup from './MenuGroup'

    export default {
        name: "menu-licenceone",
        mixins: [mixin, Security],
        components: {
            IconBase,
            IconMenu,
            IconMenuOpen,
            IconChevronDown,
            menuTitle,
            menuGroup,
            menuItem
        },
        data () {
            return {
                logoSrc: logoSrc,
                logoMiniSrc: logoMiniSrc,
                mini: this.storageMenuValue() === true,
                searchEngine: null,
                expandHover: false,
                hover: false,
                drawer: true,
            }
        },
        created(){
            if(this.needMenuCollapse()) {
                this.mini = true
            } 
            this.loadLogo();
            this.searchEngine = new SearchEngine([
                {
                    type: 'createdDate',
                    getter: entity => entity.createdAt,
                },
            ], this.$store);

            this.$store.dispatch("licence/list", {
                "status.step": ["active", "todo"],
            });

            this.$store.dispatch('chat/load');

            if(this.isGranted(permissions.SEE_ADMIN_SECTIONS)){
                this.$store.dispatch("activity/getLastBrowserUsages");
            }
        },
        methods: {
            async loadLogo(){
                if (this.company.logoUrl) {

                    const img = new Image();
                    img.src = this.company.logoUrl;

                    this.logoSrc = await img.decode().then(
                        () => this.company.logoUrl,
                        () => this.logoSrc,
                    );
                }
            },
            updateMiniValue() {
                this.mini = !this.mini
                window.localStorage.setItem('drawer-mini', this.mini)
            },
            storageMenuValue() {
                return (window.localStorage.getItem('drawer-mini') === 'true')
            },
            needMenuCollapse() {
                return this.$route.matched[0].path == "/settings"
            },
            isCategoryEmpty(items) {
                return !items.some((item) => !item.permissions || this.isGranted(item.permissions))
            },
        },
        computed: {

            ...mapGetters([
                'hasSyncIssue',
            ]),

            hasDataSourceDot() {
                return this.hasSyncIssue || !this.hasDataSource || (this.percentageUsersConnected < this.limitPercentageUsersOnExtension && this.lastBrowserUsages.length > 0)
            },

            menuItemsData() {
                return [
                    {
                        id: 'general',
                        items: [
                            {
                                title: this.$i18n.t('commons.layout.dashboard.title'),
                                icon: 'dashboard',
                                routeName: 'dashboard',
                                permissions: permissions.SEE_DASHBOARD,
                            },
                            {
                                title: this.$tc('licences.title', 2),
                                icon: 'application',
                                routeName: 'licences',
                            },
                        ]
                    },
                    {
                        title: this.$t('menu.spend.title'),
                        id:'spend',
                        items: [
                            {
                                title: this.$i18n.t('spends.title'), 
                                icon: 'spend', 
                                routeName: 'spend', 
                                new: false,
                                permissions: permissions.SEE_TRANSACTIONS,
                            },
                            {
                                title: this.$tc('licences.renewals.title', 2),
                                icon: 'renewal',
                                hasDot: this.numberOfLicenceRequiredRenewals > 0,
                                routeName: 'renewal',
                                permissions: permissions.SEE_RENEWALS,
                            }
                        ]
                    },
                    {
                        title: this.$t('menu.user.title'),
                        id: 'user',
                        items: [
                            {
                                title: this.$tc('employee.titles', 2),
                                icon: 'employee',
                                routeName: 'team',
                            },
                            { 
                                title: this.$i18n.t('process.title'), 
                                icon: 'processe', 
                                routeName: 'processes-list', 
                                permissions: permissions.SEE_PROCESSES
                            }, 
                        ]
                    }, 
                    {
                        id: 'workspace',
                        items: [
                            {
                                title: this.$tc('source.title', 2),
                                icon:'database',
                                hasDot: this.hasDataSourceDot,
                                permissions: permissions.SEE_ADMIN_SECTIONS,
                                subItems : [
                                    { 
                                        title: this.$t('menu.workspace.source.integrations'), 
                                        routeName: 'source',
                                        hasDot: this.hasDataSourceDot,
                                    },
                                    { 
                                        title: this.$t('menu.workspace.source.payments-log'), 
                                        routeName: 'transactions',
                                    },
                                ]
                            },
                            {
                                title: this.$t('account.settings.title'),
                                icon:'setting',
                                subItems : [
                                    { 
                                        title: this.$t('account.settings.account.company'), 
                                        routeName: 'settings.company',
                                        permissions: permissions.SEE_ADMIN_SECTIONS, 
                                    },
                                    { 
                                        title: this.$t('account.settings.account.my-profile'), 
                                        routeName: 'settings.profile', 
                                    },
                                    { 
                                        title: this.$tc('users.title', 2), 
                                        routeName: 'settings.users', 
                                        permissions: permissions.SEE_ADMIN_SECTIONS, 
                                    },
                                    { 
                                        title: this.$t('billing.title'), 
                                        routeName: 'settings.billing', 
                                        permissions: permissions.SEE_ADMIN_SECTIONS, 
                                    },
                                    { 
                                        title: this.$t('commons.buttons.logout'), 
                                        action: this.logout,
                                    },
                                ]
                            },
                            {
                                title: this.$t('menu.support.title'),
                                icon:'support',
                                subItems : [
                                    {
                                        title: this.$t('menu.support.chat'),
                                        action: this.openChat,
                                        hasDot: this.unreadMessagesCount > 0,
                                    },
                                    { 
                                        title: this.$t('commons.buttons.faq'), 
                                        externalLink: 'https://faq.licence.one/',
                                    },
                                    { 
                                        title: this.$t('menu.support.changelog'), 
                                        externalLink: 'https://644a918382413.sleekplan.app',
                                    }
                                ]
                            },
                            { 
                                title: this.$t('menu.invite'), 
                                icon: 'invite', 
                                routeName: 'settings.users',
                                permissions: permissions.SEE_ADMIN_SECTIONS,
                            },
                            { 
                                title: this.$t('menu.refer'), 
                                icon: 'refer', 
                                permissions: permissions.SEE_ADMIN_SECTIONS,
                            }
                        ]
                    }
                ]
            },

            ...mapGetters('licence', {
                paidLicencesWithNoRenewal: "paidLicencesWithNoRenewal",
                licencesWithOutdatedRenewal: "licencesWithOutdatedRenewal"
            }),

            ...mapGetters('activity', {
                lastBrowserUsages : 'lastBrowserUsages',
            }),

            ...mapGetters('chat', ['unreadMessagesCount']),

            numberOfLicenceRequiredRenewals() {
                return this.paidLicencesWithNoRenewal.length + this.licencesWithOutdatedRenewal.length
            },
        },
        watch: {
            '$route' (to, from) {
                if(this.needMenuCollapse()) {
                    this.mini = true
                } else {
                    if( this.storageMenuValue() !== this.mini ) {
                        this.mini = this.storageMenuValue()
                    }
                }
            },
            "company.sandbox": async function(newValue, previousValue) {
                if(!newValue) {
                    await Promise.all([
                        this.$store.dispatch("licence/list", {
                            "status.step": ["active", "todo"],
                        }),
                    ])
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .menu {
        scrollbar-color: var(--v-gray-30-base) var(--v-gray-10-base);
        scrollbar-width: thin;
        &__padding {
            padding-bottom: 90px;
        }
        &__selected-logo{
            background-color: var(--v-gray-30-base) !important;
            border-radius: 4px;
        }
        .menu-wrapper{
            display: flex;
            flex-direction: column;
            min-height: 100%;
            position: relative;
            overflow-y: auto;
            .menu-item-category{
                flex-grow: 0;
                flex-shrink: 0;
            }
            #menu-workspace{
                margin-top: auto;
                padding: 16px 0;
            }
        }
    }
    .v-navigation-drawer:not(.v-navigation-drawer--mini-variant) {
        #menu-workspace{
            .category{
                border-top: 1px solid #6B7280;
            }
        }
    }
</style>