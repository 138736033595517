var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        width: _vm.width,
        "retain-focus": false,
        persistent: _vm.persistent,
        disabled: _vm.disabled,
        fullscreen: _vm.fullscreen,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on }) {
              return [
                _c(
                  "div",
                  _vm._g({ class: _vm.inlineOpener ? "d-inline" : "" }, on),
                  [_vm._t("opener")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.isOpened,
        callback: function ($$v) {
          _vm.isOpened = $$v
        },
        expression: "isOpened",
      },
    },
    [
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-card",
            { class: _vm.needBorderRadius ? "rounded-lg" : "" },
            [
              _c(
                "div",
                { class: _vm.needPadding ? "pa-8" : "" },
                [
                  _c(
                    "v-card-title",
                    {
                      class: _vm.noTitle
                        ? "pa-0 closable-no-title"
                        : "display-2 pa-0 pb-8 d-flex justify-space-between",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _vm._t("title"),
                      _vm._v(" "),
                      _vm.isClosable
                        ? _c(
                            "div",
                            {
                              class: _vm.noTitle ? "cpointer pb-4" : "cpointer",
                              style: _vm.noTitle ? "margin-left: auto;" : "",
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "icon-base",
                                {
                                  attrs: {
                                    "icon-name": "icon-cross",
                                    width: "29",
                                    height: "29",
                                  },
                                },
                                [
                                  _c("icon-cross", {
                                    tag: "component",
                                    attrs: { fill: "black" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [_vm._t("content")],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.actions ? _c("v-divider") : _vm._e(),
              _vm._v(" "),
              _vm.actions
                ? _c(
                    "v-card-actions",
                    { staticClass: "py-4 px-8" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.isCancellable
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "grey-8", text: "" },
                              on: { click: _vm.close },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("commons.buttons.close")) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("actions"),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "text-center d-flex align-center justify-center",
              attrs: { height: "400px" },
            },
            [_c("loader")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }