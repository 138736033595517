import client from "./client";

export default {

    update(data) {
        return client.put("/api/notification/slack", data);
    },

    slack() {
        return client.get("/api/notification/slack");
    },

    unsetSlack() {
        return client.delete("/api/notification/slack/uninstall");
    },

};
