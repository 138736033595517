<template>
    <v-dialog
      v-model="isOpened"
      :width="width"
      :retain-focus="false"
      :persistent="persistent"
      :disabled="disabled"
      :fullscreen="fullscreen"
    >
        <template v-slot:activator="{ on }">
            <div v-on="on" :class="(inlineOpener) ? 'd-inline' : ''">
                <slot name="opener"></slot>
            </div>
        </template>

        <v-card v-if="!loading" :class="(needBorderRadius)? 'rounded-lg' : '' ">
            <div :class="(needPadding)? 'pa-8' : ''">
                <v-card-title
                    :class="(noTitle) ? 'pa-0 closable-no-title' : 'display-2 pa-0 pb-8 d-flex justify-space-between'"
                    primary-title
                >
                    <slot name="title"></slot>
                    <div v-if="isClosable" :class="(noTitle)?'cpointer pb-4' : 'cpointer'" :style="(noTitle)?'margin-left: auto;' : ''" @click="close">
                        <icon-base icon-name="icon-cross" width="29" height="29">
                            <component :fill="'black'" :is="'icon-cross'"></component>
                        </icon-base>
                    </div>
                </v-card-title>

                <v-card-text class="pa-0">
                    <slot name="content"></slot>
                </v-card-text>
            </div>

            <v-divider v-if="actions"></v-divider>

            <v-card-actions class="py-4 px-8" v-if="actions">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="isCancellable"
                    color="grey-8"
                    text
                    @click="close"
                >
                    {{ $t('commons.buttons.close') }}
                </v-btn>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
        <v-card v-else class="text-center d-flex align-center justify-center" height="400px">
            <loader></loader>
        </v-card>
    </v-dialog>
</template>

<script>
    
    import {mapGetters} from 'vuex';
    import Loader from  '../../components/loaders/Loader.vue'
    import IconBase from '../../components/IconBase';
    import IconCross from "../../components/icons/IconCross";

    export default {
        data () {
            return {
                dialog: this.opened,
            }
        },
        components: {
            Loader,
            IconBase,
            IconCross
        },
        props: {
            inlineOpener: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            noTitle: {
                type: Boolean,
                default: false
            },
            actions: {
                type: Boolean,
                default: true
            },
            persistent: {
                type: Boolean,
                default: false
            },
            isCancellable: {
                type: Boolean,
                default: true
            },
            isClosable: {
                type: Boolean,
                default: false
            },
            opened: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: "500px"
            },
            value: {
                type: Boolean,
            },
            loading: {
                type: Boolean,
                default: false
            },
            needPadding: {
                type: Boolean,
                default: true
            },
            needBorderRadius: {
                type: Boolean,
                default: false
            },
            fullscreen: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            opened(val){
                this.dialog = val
            }
        },
        computed: {

            isOpened: {

                get(){
                    return this.dialog || this.value;
                },

                set(val){
                    this.dialog = val;
                    this.$emit('input', val);
                }
            },

        },
        methods: {
            close(){
                this.$emit('close');
                this.isOpened = false;
            },
            open(){
                this.isOpened = true;
            }
        }
    }
</script>

<style>
    .v-btn .v-btn__content{
        text-transform: none;
    }
    .closable-no-title{
        position: absolute !important;
        right: 32px;
    }
</style>