var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "mx-0 px-12 topbar",
      attrs: { app: "", light: "", color: "#FFFFFF", flat: "" },
    },
    [
      _vm.topNav.link
        ? _c(
            "router-link",
            { staticClass: "grey-8--text", attrs: { to: _vm.topNav.link } },
            [
              _c(
                "div",
                {
                  class:
                    "topbar__link py-1 pr-2 rounded-lg d-flex align-center" +
                    (_vm.topNav.link ? "" : "topbar__link--hidden"),
                },
                [
                  _c(
                    "icon-base",
                    { attrs: { "icon-name": "icon-chevron-left" } },
                    [_c("icon-chevron-left")],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "ma-0" }, [
                    _vm._v(_vm._s(_vm.topNav.label)),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("searchbar"),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _vm.company.sandbox
        ? _c("sandbox-to-real-data-modal", {
            model: {
              value: _vm.sandboxToRealDataModalDialog,
              callback: function ($$v) {
                _vm.sandboxToRealDataModalDialog = $$v
              },
              expression: "sandboxToRealDataModalDialog",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inImpersonateMode
        ? _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                color: "red-30",
                small: "",
                fab: "",
                dark: "",
                depressed: "",
              },
              on: { click: _vm.stopImpersonateMode },
            },
            [_c("v-icon", [_vm._v("\n            mdi-drama-masks\n        ")])],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }