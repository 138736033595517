var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "svg-ico v-align-middle",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
        "aria-labelledby": _vm.iconName,
        role: "presentation",
      },
    },
    [
      _c(
        "g",
        _vm._g(
          {
            staticClass: "l1-ico",
            attrs: { fill: _vm.iconColor, stroke: _vm.strokeColor },
          },
          _vm.click
        ),
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }