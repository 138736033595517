var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "stepper d-flex align-center justify-space-between mb-6" },
    _vm._l(_vm.steps, function (step, index) {
      return _c(
        "div",
        {
          key: step.id,
          staticClass: "stepper__item mr-4",
          class: {
            current__step: step.id == _vm.actualStepVerified,
            success__step: _vm.actualStepVerified > step.id,
          },
          style: "transition-delay: " + (_vm.transitionDuration - 500) + "ms;",
        },
        [
          _c("div", { staticClass: "d-flex align-center gap-2" }, [
            _c(
              "div",
              {
                staticClass: "stepper__number",
                style:
                  "transition-delay: " + (_vm.transitionDuration - 500) + "ms;",
              },
              [
                _vm._v(
                  "\n                " + _vm._s(step.id) + "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "stepper__title" }, [
              _vm._v(
                "\n                " + _vm._s(step.label) + " \n            "
              ),
            ]),
            _vm._v(" "),
            step.id !== _vm.steps.length
              ? _c("div", { staticClass: "stepper__progress" }, [
                  _c("div", {
                    staticClass: "stepper__bar",
                    style:
                      "transition: all " +
                      _vm.transitionDuration +
                      "ms ease;" +
                      (index < _vm.actualStepVerified - 1
                        ? " width:100%;"
                        : ""),
                  }),
                ])
              : _vm._e(),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }