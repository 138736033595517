import AccountingApi from "../api/Accounting";

const 
    FETCHING_CONNECTIONS          = "FETCHING_CONNECTIONS",
    FETCHING_CONNECTIONS_SUCCESS  = "FETCHING_CONNECTIONS_SUCCESS",
    FETCHING_CONNECTIONS_ERROR    = "FETCHING_CONNECTIONS_ERROR"
;

export default {

    namespaced: true,

    state: {
        isLoading:  false,
        errors:     null,
        connections: [],
    },

    getters: {

        isLoading(state) {
            return state.isLoading;
        },

        connections(state) {
            return state.connections;
        },

        hasOneFunctionalSync(state) {

            if(!state.connections){
                return false;
            }

            return state.connections.filter(connection => connection.state === 'callable').length > 0;
        },

    },

    mutations: {

        [FETCHING_CONNECTIONS](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [FETCHING_CONNECTIONS_SUCCESS](state, connections)
        {
            state.isLoading     = false;
            state.errors        = null;
            state.connections   = [];

            for (const connection of connections){
                if(connection.state !== 'available'){
                    state.connections.push(connection);
                }
            }
        },

        [FETCHING_CONNECTIONS_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

    },

    actions: {

        async connections({commit})
        {
            commit(FETCHING_CONNECTIONS);

            try {

                const response = await AccountingApi.connection.list();
                commit(FETCHING_CONNECTIONS_SUCCESS, response.data.data);

                return response.data.data;

            } catch (error) {

                commit(FETCHING_CONNECTIONS_ERROR, error);
                return null;
            }
        },

    },

}