var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        "large-checkbox relative pa-4 w-100 cpointer" +
        (_vm.isActive ? " large-checkbox--active" : ""),
      on: {
        click: function ($event) {
          return _vm.$emit("updateChoice")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "large-checkbox__info d-flex flex-column justify-space-between h-100",
        },
        [_vm._t("content")],
        2
      ),
      _vm._v(" "),
      _c("span", { staticClass: "large-checkbox__radio absolute" }, [
        _c("span", {
          class:
            "large-checkbox__radio--checked" + (_vm.isActive ? "" : " d-none"),
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }