export default class LicenceHelper {

    static isLicenceInactive(licence) {
      return licence?.status?.step === "inactive";
    }

    static filterByStatus(licences, status){
      return licences.filter(licence => licence.status.id === status.id);
    }
    
    static getSystemFilter(statuses, step){
      return statuses.find(status => status.step === step && status.isSystem);
    }

}