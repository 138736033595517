<template>
    <v-tooltip bottom :disabled="!disabled || tooltipTxt === ''">
        <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
                <v-btn 
                    :to="to"
                    v-on="on"
                    :disabled="disabled || isLoading"
                    color="grey-8" 
                    :class="'primary-btn text-none white--text ' + classNames" 
                    @click="emitAction"
                    :id="id"
                    :loading="isLoading"
                    depressed>
                    {{ text }}
                </v-btn>
            </div>
        </template>
        <span>
            {{ tooltipTxt }}
        </span>
    </v-tooltip>
</template>
<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "l1-button",
        data(){
            return {
            }
        },
        props: {
            classNames:{
                default: '',
                type: String,
                required: false
            },
            text:{
                default: 'Press me',
                type: String,
                required: false
            },
            to:{
                default: undefined,
                type: String,
                required: false
            },
            isLoading:{
                default: false,
                type: Boolean,
                required: false
            },
            id:{
                default: undefined,
                type: String,
                required: false
            },
            disabled:{
                default: false,
                type: Boolean,
                required: false
            },
            needToBeAdmin: {
                default: true,
                type: Boolean,
                required: false
            },
            "tooltip-text": {
                type: String,
                required: false,

            }
        },
        directives: {
        },
        computed:{
            tooltipTxt(){
                return this['tooltipText'] ?? this.$t('commons.tooltip.insufficient-rights');
            },
        },
        methods: {
            emitAction(){
                this.$emit('action');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .primary-btn {
        &:hover {
            background-color: rgba(0, 0, 0, 0.7) !important;
        }
    }
</style>