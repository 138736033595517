import client from "./client";

export default {
    
    get() {
        return client.get("/api/settings/tasks");
    },

    update(data) {
        return client.put("/api/settings/tasks", data);
    },

};
