<template>
    <section class="stepper d-flex align-center justify-space-between mb-6">
        <div v-for="(step, index) in steps" :key="step.id" class="stepper__item mr-4" :class="{ 'current__step': step.id == actualStepVerified, 'success__step': actualStepVerified > step.id }" :style="'transition-delay: ' + (transitionDuration-500) + 'ms;'">
            <div class="d-flex align-center gap-2">
                <div class="stepper__number" :style="'transition-delay: ' + (transitionDuration-500) + 'ms;'">
                    {{ step.id }}
                </div>
                <div class="stepper__title">
                    {{ step.label }} 
                </div>
                <div v-if="step.id !== steps.length" class="stepper__progress">
                    <div class="stepper__bar" :style="'transition: all ' + transitionDuration + 'ms ease;' + ((index < actualStepVerified-1)? ' width:100%;' : '')">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import mixin from '../../Mixins/mixin';

    export default {
        name: "stepper",
        mixins: [mixin],
        components: {
        },
        data () {
            return {
            }
        },
        props: {
        
            steps: {
                type: Array,
                default: () => {
                    return [
                        {
                            step: 1,
                            name: "lorem-ipsum",
                            label: "Lorem Ipsum",
                        },
                    ];
                },
            },

            actualStep: {
                type: Number,
                default: 1,
            },

            transitionDuration: {
                type: Number,
                default: 500
            }

        },
        computed: {
            actualStepVerified() {
                if(this.actualStep > this.steps.length) {
                    return this.steps.length
                }
                return this.actualStep
            }
        }
    }
</script>
<style lang="scss" scoped>
.stepper{
    position: relative;

    &__progress{
        width: 64px;
        background-color: var(--v-gray-70-base);
        height: 2px;
    }

    &__bar{
        height: 100%;
        width: 0%;
        background-color: black;
    }
    &__item{
        color: black;
        transition-property: all;
        transition-timing-function: ease;
        transition-duration: 500ms;
    }
    &__number{
        height: 28px;
        width: 28px;
        display: grid;
        place-items: center;
        border-radius: 100%;
        border: 1px solid black;
        position: relative;
        font-size: 14px;
        transition-property: all;
        transition-timing-function: ease;
        transition-duration: 500ms;
    }
}

.stepper__item.success__step{
    .stepper {
        &__number{
            border-color: black;
            background-color: black;
            color: #fff;
            font-weight: 600;
        }
    
        &__title{
            color: black;
        }
    }
}

.stepper__item.current__step{
    .stepper {
        &__number{
            border-color: black;
            background-color: black;
            color: #fff;
            font-weight: 600;
        }

        &__title{
            color: var(--v-gray-20-base);
        }
    } 
}
</style>