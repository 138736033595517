var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "", disabled: !_vm.disabled || _vm.tooltipTxt === "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        class:
                          "primary-btn text-none white--text " + _vm.classNames,
                        attrs: {
                          to: _vm.to,
                          disabled: _vm.disabled || _vm.isLoading,
                          color: "grey-8",
                          id: _vm.id,
                          loading: _vm.isLoading,
                          depressed: "",
                        },
                        on: { click: _vm.emitAction },
                      },
                      on
                    ),
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.text) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("span", [_vm._v("\n        " + _vm._s(_vm.tooltipTxt) + "\n    ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }