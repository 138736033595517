import { AxiosError } from "axios";
import SpendeskAPI from "../api/Spendesk";
import remove from 'lodash/remove';

const 
  CONNECTING = "CONNECTING",
  LOADING = "LOADING",
  LIST = "LIST",
  CONNECTION_ADDED = "CONNECTION_ADDED",
  CONNECTION_DELETED = "CONNECTION_DELETED",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        connecting: false,
        loading: false,
        connections: [],
    },

    getters: {

        error(state) {
            return state.error;
        },

        connecting(state) {
            return state.connecting;
        },

        loading(state) {
            return state.loading;
        },

        connections(state) {
            return state.connections;
        },

        hasSyncIssue(state) {
            if(state.connections.length === 0){
                return false;
            }

            return state.connections.some(connection => connection.status !== 0);
        },

        hasOneFunctionalSync(state) {
            return state.connections.some(connection => connection.status === 0);
        },

    },

    mutations: {

        /**
         * @param {Boolean} connecting
         */
        [CONNECTING](state, connecting)
        {
            state.connecting = connecting;
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        [CONNECTION_ADDED](state, connection)
        {
            const existingConnectionIndex = state.connections.findIndex(stateConnection => stateConnection.id === connection.id);
            if(existingConnectionIndex !== -1){
                state.connections.splice(existingConnectionIndex, 1, connection);
            }else{
                state.connections.push(connection);
            }
        },

        [CONNECTION_DELETED](state, id)
        {
            remove(state.connections, connection => {
                return connection.id === id;
            });
        },

        /**
         * @param {Array} connections
         */
        [LIST](state, connections)
        {
            state.connections = connections;
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

    },

    actions: {

        async connect({commit}, code)
        {
            commit(CONNECTING, true);
            commit(ERROR, null);
            try {

                const response = await SpendeskAPI.getAccessToken(code);
                commit(CONNECTING, false);
                commit(CONNECTION_ADDED, response.data);
                return response;

            } catch (error) {

                commit(CONNECTING, false);
                commit(ERROR, error.response.status);
                return null;
            }
        },

        async disconnect({commit}, id)
        {
            commit(CONNECTING, true);
            try {

                const response = await SpendeskAPI.deleteToken(id);
                commit(CONNECTING, false);
                commit(CONNECTION_DELETED, id);
                commit(ERROR, null);
                return response;

            } catch (error) {

                commit(CONNECTING, false);
                return null;
            }
        },

        async list({commit}) {

            commit(LOADING, true);
            const response = await SpendeskAPI.list();
            commit(LIST, response.data);
            commit(LOADING, false);

            return response;
        },

    },

}