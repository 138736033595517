var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLayoutSecurity
    ? _c("v-app", [_c("layout-security")], 1)
    : _vm.isLayoutOnboarding
    ? _c("v-app", [_c("layout-onboarding")], 1)
    : _vm.isLayoutApp
    ? _c("v-app", { attrs: { id: "content-app" } }, [_c("layout")], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }