import { AxiosError } from "axios";
import QuickbooksAPI from "../api/Quickbooks";

const 
  CONNECTING = "CONNECTING",
  LOADING = "LOADING",
  ACCESS = "ACCESS",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        connecting: false,
        loading: false,
        access: null,
    },

    getters: {

        error(state) {
            return state.error;
        },

        connecting(state) {
            return state.connecting;
        },

        loading(state) {
            return state.loading;
        },

        access(state) {
            return state.access;
        },

        hasSyncIssue(state) {
            if(!state.access){
                return false;
            }

            return state.access.status !== 0;
        },

    },

    mutations: {

        /**
         * @param {Boolean} connecting
         */
        [CONNECTING](state, connecting)
        {
            state.connecting = connecting;
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {Object} access
         */
        [ACCESS](state, access)
        {
            state.access = access;
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

    },

    actions: {

        async connect({commit}, payload)
        {
            commit(CONNECTING, true);
            commit(ERROR, null);
            try {

                const response = await QuickbooksAPI.getAccessToken(payload.code, payload.realmId);
                commit(CONNECTING, false);
                commit(ACCESS, response.data);
                return response;

            } catch (error) {

                commit(CONNECTING, false);
                commit(ERROR, error.response.status);
                
                return null;
            }
        },

        async disconnect({commit})
        {
            commit(CONNECTING, true);
            try {

                const response = await QuickbooksAPI.deleteToken();
                commit(CONNECTING, false);
                commit(ACCESS, null);
                commit(ERROR, null);
                return response;

            } catch (error) {

                commit(CONNECTING, false);
                return null;
            }
        },

        async get({commit})
        {
            commit(LOADING, true);
            try {

                const response = await QuickbooksAPI.get();
                commit(LOADING, false);
                commit(ACCESS, response.data);

                if(response.data.status !== 0){
                    commit(ERROR, response.data.status);
                }else{
                    commit(ERROR, null);
                }

                return response;

            } catch (error) {

                commit(LOADING, false);
                return null;
            }
        }

    },

}