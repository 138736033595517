import { AxiosError } from "axios";
import LicenceStatusApi from "../api/licenceStatus";
import Vue from "vue";

const 
  FETCHED = "FETCHED",
  UPDATED = "UPDATED",
  CREATED = "CREATED",
  REMOVED = "REMOVED",
  LOADING = "LOADING",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        loading: false,
        statuses: [],
        status: null,
    },

    getters: {

        error(state) {
            return state.error;
        },

        loading(state) {
            return state.loading;
        },

        statuses(state) {
            return state.statuses;
        },

        statusesByGroup(state){
          return state.statuses.reduce((groups, item) => {
              const group = (groups[item.step] || []);
              group.push(item);
              groups[item.step] = group;
              return groups;
          }, {});
        }
    },

    mutations: {

        /**
         * @param {Array} statuses
         */
        [FETCHED](state, statuses)
        {
          state.statuses = statuses;
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {Array} updatedStatus
         */
        [UPDATED](state, updatedStatus)
        {
          const statusIndex = state.statuses.findIndex(function(element){
            return element.id === updatedStatus.id;
          });
          Vue.set(state.statuses, statusIndex, updatedStatus);
        },

        /**
         * @param {Array} status
         */
        [CREATED](state, status)
        {
          Vue.set(state.statuses, state.statuses.length, status)
        },

        /**
         * @param {int} removedStatusId
         */
        [REMOVED](state, removedStatusId)
        {
          state.statuses.forEach(function(statusA, i) {
            if (statusA.id == removedStatusId){
              state.statuses.splice(i, 1);
              return;
            } 
          });
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

    },

    actions: {

      /**
       * @param {Object} status
       */
      async create({ commit }, status) {
        
        commit(LOADING, true);
        commit(ERROR, null);

        try {

          delete status.id;
          delete status.isSystem;
          delete status.isDefault;
          const response = await LicenceStatusApi.create(status);

          commit(LOADING, false);
          commit(CREATED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },

      /**
       * @param {Object} status
       */
      async update({ commit }, status) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {
          delete status.isSystem;
          delete status.isDefault;
          const response = await LicenceStatusApi.update(status);

          commit(LOADING, false);
          commit(UPDATED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },

      async get({ commit }) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {
          const response = await LicenceStatusApi.get();

          commit(LOADING, false);
          commit(FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },

      async setAsDefault({ commit }, statusId) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {
          const response = await LicenceStatusApi.setAsDefault(statusId);

          commit(LOADING, false);
          commit(UPDATED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },

      async delete({ commit }, statusId) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {
          const response = await LicenceStatusApi.delete(statusId);

          commit(LOADING, false);
          commit(REMOVED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      }
    },

}