<template>
    <div class="d-flex justify-space-between mb-4">
        <div class="text-left f-6" v-html="label" />
        <div :class="'text-right' + (price != 0 ? '' : '  grey-4--text')">
            <span v-if="oldPrice" class="oldPrice">
                {{ formatNumber(oldPrice, getSystemCurrency()) }}
            </span>
            <span class="price">
              {{
                  (price != 0)
                  ? formatNumber(price, getSystemCurrency())
                  : $t("billing.account.usage.listing.placeholders.included")
              }}
            </span>
            <span v-if="descPrice">{{ descPrice }}</span>
        </div>
  </div>
</template>

<script>
import mixin from "@app/js/Mixins/mixin.js";
export default {
  mixins: [mixin],
  data() {
    return {};
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      default: 0,
    },
    oldPrice: {
      type: Number,
    },
    descPrice: {
      type: String,
    },
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
  .oldPrice {
    color: var(--v-red-30-base);
    text-decoration:line-through;
    padding-right: 16px;
  }
  .price {
    min-width: 65px;
    display: inline-block;
  }
</style>