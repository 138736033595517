import ls from 'localstorage-slim';

// 604800 seconds = 1 week
const tokenTtl = 604800;
const authManager = new class AuthManager {

    constructor(){
        this.storage = ls;
        this.tokens = {};
    }

    get accessToken(){
        return this.tokens ? this.tokens.access : null;
    }

    set accessToken(token){
        this.tokens.access = token;
    }

    get refreshToken(){
        return this.tokens ? this.tokens.refresh : null;
    }

    setTokens(tokens){
        this.verifyTokens(tokens);
        this.tokens = tokens;
    }

    verifyTokens(tokens){

        if(!tokens){
            return;
        }

        if(!tokens.access){
            throw new Error('No access token');
        }

        if(!tokens.refresh){
            throw new Error('No refresh token');
        }
    }

    storeTokens(tokens){
        this.setTokens(tokens);
        this.storage.set('tokens', tokens, {
            ttl: tokenTtl,
        });
    }

    loadTokensFromStorage(){
        const tokens = this.storage.get('tokens');
        this.setTokens(tokens);
    }

    reset(){
        this.storeTokens(null);
    }

};

authManager.loadTokensFromStorage();

export default authManager;