import ActivityLogsAPI from "../api/activityLog";

const GETTING_ACTIVITY_LOGS = "GETTING_ACTIVITY_LOGS",
  GETTING_ACTIVITY_LOGS_SUCCESS = "GETTING_ACTIVITY_LOGS_SUCCESS",
  GETTING_ACTIVITY_LOGS_ERROR = "GETTING_ACTIVITY_LOGS_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    activityLogs: {}
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    getActivityLogs(state) {
      return state.activityLogs;
    }
  },
  mutations: {
    [GETTING_ACTIVITY_LOGS](state) {
      state.isLoading = true;
      state.error = null;
      state.activityLogs = {};
    },
    [GETTING_ACTIVITY_LOGS_SUCCESS](state, activityLogs) {
      state.isLoading = false;
      state.error = null;
      state.activityLogs = activityLogs;
    },
    [GETTING_ACTIVITY_LOGS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.activityLogs = {};
    },
  },
  actions: {
    async getBy({ commit }, payload) {
      commit(GETTING_ACTIVITY_LOGS);
      try {
        let response = await ActivityLogsAPI.getBy(payload);
        commit(GETTING_ACTIVITY_LOGS_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(GETTING_ACTIVITY_LOGS_ERROR, error);
        return null;
      }
    }
  }
};