<template>
    <v-img
        v-if="item"
        :width="width"
        class="rounded-lg v-align-middle d-inline-block"
        @error="changeLogoSource"
        :src="logoSrc"
        transition="false"
    >
    </v-img>
</template>

<script>

    import appPlaceholderSrc from '@app/images/app-placeholder.svg';

    export default {
        name: "licence-logo",
        props: {
            width:{
                default: '30px',
                type: String,
                required: false
            },
            item:{
                required: true
            }
        },
        data: () => ({
            placeholder: appPlaceholderSrc,
            source: 'logo.provider',
        }),
        methods: {
            changeLogoSource(){

                switch(this.source){
                    case 'logo.provider':
                        this.source = 'favicon';
                        break;
                    case 'favicon':
                        this.source = 'placeholder';
                        break;
                }
            },
        },
        computed: {
            appDomain(){
                return new URL(this.item?.website).origin;
            },

            logoSrc(){

                switch(this.source){
                    case 'logo.provider':
                        try{
                            return 'https://logo.clearbit.com/' + this.appDomain;
                        }catch(e){
                            this.source = 'placeholder';
                            return this.placeholder;
                        }

                    case 'favicon':
                        return this.appDomain + "/favicon.ico";

                    case 'placeholder':
                        return this.placeholder;
                }
            }
        }
    }
</script>