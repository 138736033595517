import client from "./client";

const apiUrl = "/v2/licence-tags"

export default {
  get() {
    return client.get(apiUrl);
  },
  create(data) {
    return client.post("/api/licence-tag", data);
  },

  apiUrl:apiUrl
};