var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "M5.95163 9H4.84987V14.1406H4.78146L1.02974 9H0V16H1.11255V10.8662H1.18096L4.92908 16H5.95163V9Z M8.28428 16H12.9505V15.0908H9.39684V12.9478H12.6661V12.042H9.39684V9.90918H12.9073V9H8.28428V16Z M16.547 16H17.6703L19.2221 10.7568H19.2797L20.8316 16H21.9513L24 9H22.8262L21.3932 14.4243H21.3248L19.8306 9H18.6713L17.177 14.4209H17.1086L15.672 9H14.5019L16.547 16Z",
      fill: "#0A0D10",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }