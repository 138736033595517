var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c("lottie-player", {
        staticStyle: { margin: "auto", width: "100px", height: "100px" },
        attrs: {
          src: _vm.loaderLottie,
          background: "transparent",
          speed: "1",
          loop: "",
          autoplay: "",
        },
      }),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("commons.loading")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }