import store from '../../store';

/**
 * Roles ordered from the highest to the lowest.
 * This order SHOULD NOT be changed as it is used by getHighestRole method.
 */
const roleHierarchy = {
    ROLE_OWNER: ['ROLE_ADMIN'],
    ROLE_ADMIN: ['ROLE_VIEWER', 'ROLE_MONITOR', 'ROLE_TEAM_MANAGER'],
    ROLE_TEAM_MANAGER: ['ROLE_APP_MANAGER'],
    ROLE_MONITOR: ['ROLE_APP_MANAGER'],
    ROLE_VIEWER: ['ROLE_MONITOR'],
    ROLE_APP_MANAGER: ['ROLE_EMPLOYEE'],
    ROLE_EMPLOYEE: ['ROLE_USER'],

    ROLE_RESTRICTED: ['ROLE_USER'],
    ROLE_USER: null,
};

const orderedRoles = Object.keys(roleHierarchy);

export default new class Security {

    #permissions = null

    set authorization(authorization){
        this.#permissions = authorization.permissions;
    }

    /**
     * @param {string} permission
     * @param {Object=} resource
     * @returns {Boolean}
     */
    isGranted(permission, resource){

        // if permission is a role, authenticated user (or resource argument) roles are checked
        if(orderedRoles.includes(permission)){

            const user = resource ?? store.getters["user/get"];
            if(!user){
                return false;
            }

            return this.#flattenRoles(user.roles).includes(permission);
        }

        // else, resource permissions are checked
        this.#guard(permission, resource);
        return resource ? resource.permissions[permission] : this.#permissions[permission];
    }

    /**
     * @param {Object} user
     * @param {Array} user.roles
     * 
     * @returns {string}
     */
    getHighestRole(user) {

        const flattenRoles = this.#flattenRoles(user.roles);
        let role;
        for (role of orderedRoles){

            if(flattenRoles.includes(role)){
                break;
            }
        }

        return role;
    }

    /**
     * @param {Array} roles
     * @param {Array} flattenRoles
     * @returns {Array}
     */
    #flattenRoles(roles, flattenRoles = []) {

        roles.forEach(role => {

            flattenRoles.push(role);

            if(roleHierarchy[role]){
                flattenRoles.concat(this.#flattenRoles(roleHierarchy[role], flattenRoles));
            }
        });

        return flattenRoles;
    }

    /**
     * @param {string} permission
     * @param {Object=} resource
     */
    #guard(permission, resource){

        if(!permission){
            throw new Error('permission should be provided');
        }

        if(resource){
            if(!resource.permissions){
                throw new Error('The resource does not have a permissions property');
            }

            if(resource.permissions[permission] === undefined){
                throw new Error('Permission ' + permission + ' does not exist on the resource');
            }

            return;
        }

        if(!this.#permissions){
            throw new Error('Permissions have not been initialized yet');
        }

        if(this.#permissions[permission] === undefined){
            throw new Error('Permission ' + permission + ' does not exist');
        }
    }

}