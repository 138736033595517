import VueRouter from "vue-router";
import Security from "./Security";
import permissions from "./Permissions";

export default class PermissionRouterGuard
{
    /** @type {VueRouter} */
    #router

    /**
     * @param {VueRouter} router
     */
    constructor(router)
    {
        this.#router = router;
    }

    setupRouter()
    {
        return this.#router.beforeEach(this.#verifyPermissions.bind(this));
    }

    #verifyPermissions(to, from, next)
    {
        if(!to.meta.permission){
            return next();
        }

        if(Security.isGranted(to.meta.permission)){
            return next();
        }

        // On the page load, if the user can't see the dashboard, we redirect it to another page instead of redirecting him to the forbidden page
        if(!from.name && to.name === 'dashboard' && !Security.isGranted(permissions.SEE_DASHBOARD)){
            return next({
                name: 'licences',
            });
        }

        return next({
            name: 'system.forbidden',
        });
    }

}
