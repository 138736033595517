const SET_AVOID_REDIRECTION = "SET_AVOID_REDIRECTION",
WANT_QUIT_PAGE = "WANT_QUIT_PAGE",
CANCEL_REDIRECTION = "CANCEL_REDIRECTION",
RESET = "RESET"

export default {
    namespaced: true,
    state: {
        show: false,
        avoidRedirectionWithChanges: false,
        nextRouteUrl: null
    },
    getters: {
        isShown(state){
            return state.show
        },
        getNextRouteUrl(state) {
            return state.nextRouteUrl
        },
        getAvoidRedirectionWithChanges(state) {
            return state.avoidRedirectionWithChanges
        }
    },
    mutations: {
        [SET_AVOID_REDIRECTION](state) {
            state.avoidRedirectionWithChanges = true;
        },
        [WANT_QUIT_PAGE](state, params) {
            state.nextRouteUrl = params.nextRouteUrl;
            state.show = true;
        },
        [CANCEL_REDIRECTION](state) {
            state.nextRouteUrl = null;
            state.show = false;
        },
        [RESET](state) {
            state.avoidRedirectionWithChanges = false;
            state.show = false;
            state.nextRouteUrl = null;
        },
        
    },
    actions: {
        setAvoidRedirection ({commit}) {
            commit(SET_AVOID_REDIRECTION);
        },
        wantQuitPage({commit}, params) {
            commit(WANT_QUIT_PAGE, params);
        },
        cancelRedirection({commit}) {
            commit(CANCEL_REDIRECTION);
        },
        reset({commit}) {
            commit(RESET);
        },
    }
}