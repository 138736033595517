var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "menu__item justify-space-between cpointer",
        on: {
          click: function ($event) {
            return _vm.handleCollapseGroup(_vm.group.icon)
          },
        },
      },
      [
        _c("div", { staticClass: "d-flex" }, [
          _vm.group.icon
            ? _c(
                "div",
                {
                  class:
                    "d-flex align-center justify-center" +
                    (_vm.isCollapse ? " mr-0" : " mr-3"),
                },
                [
                  _vm.hasSubItemsDot
                    ? _c("v-badge", { attrs: { dot: "", color: "red-30" } }, [
                        _c("span", {
                          class: "icon-" + _vm.group.icon,
                          staticStyle: { color: "white", "font-size": "24px" },
                        }),
                      ])
                    : _c("span", {
                        class: "icon-" + _vm.group.icon,
                        staticStyle: { color: "white", "font-size": "24px" },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.group.title && !_vm.isCollapse
            ? _c("span", { staticClass: "d-flex align-center fw-450" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.group.title) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            ref: "icon-chevron-" + _vm.group.icon,
            class: "show-more__icon" + (_vm.isCollapse ? " d-none" : ""),
          },
          [
            _c(
              "icon-base",
              { attrs: { "icon-name": "icon-chevron-down", color: "white" } },
              [_c("icon-chevron-down")],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "sub-item-" + _vm.group.icon,
        class: "items__collapse mt-1" + (_vm.isCollapse ? " d-none" : ""),
      },
      _vm._l(_vm.group.subItems, function (item) {
        return _c(
          "div",
          { key: item.title },
          [
            !item.permissions || _vm.isGranted(item.permissions)
              ? _c("menu-item", {
                  attrs: { item: item, isCollapse: _vm.isCollapse },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }