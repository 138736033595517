import client from "./client";

export default {
    getBy(data) {
      return client.get("/api/activity-logs",{
        params:{
          filters: data
        }
      });
    }
};