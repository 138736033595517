import { AxiosError } from "axios";
import AppAPI from "../api/app";

const CREATING = "CREATING",
  FETCHING = "FETCHING",
  LISTING = "LISTING",
  LOADING = "LOADING",
  ERROR = "ERROR";

export default {

  namespaced: true,

  state: {
    isLoading: false,
    error: null,
    apps: [],
    app: null,
  },

  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasApps(state) {
      return state.apps.length > 0;
    },
    apps(state) {
      return state.apps;
    },
    app(state) {
      return state.app;
    }
  },
  mutations: {

    [LOADING](state, loading)
    {
        state.loading = loading;
    },

    [CREATING](state, app)
    {
        state.app = app;
    },

    [FETCHING](state, app)
    {
        state.app = app;
    },

    [LISTING](state, apps)
    {
        state.apps = apps;
    },

    [ERROR](state, error)
    {
        state.error = error;
    }

  },
  
  actions: {

    async create({ commit }, payload) 
    {
      commit(LOADING, true);

      try {

        const response = await AppAPI.create(payload);

        commit(LOADING, false);
        commit(CREATING, response.data);
        commit(ERROR, null);
        
        return response.data;

      } catch (error) {

        commit(LOADING, false);
        commit(ERROR, error.response.status);
        return null;
      }
    },

    async getFromWebsite({ commit }, payload) 
    {
      
      commit(LOADING, true);

      try {

        const response = await AppAPI.getFromWebsite(payload);
        commit(LOADING, false);
        commit(FETCHING, response.data);
        commit(ERROR, null);

        return response.data;

      } catch (error) {
        
        commit(LOADING, false);
        commit(ERROR, error.response.status);
        return null;
      }
    },

    async apps({ commit }, payload) {

      commit(LOADING, true);

      try {

        const response = await AppAPI.apps(payload);
        commit(LOADING, false);
        commit(LISTING, response.data);
        commit(ERROR, null);
        
        return response.data;

      } catch (error) {
        
        commit(LOADING, false);
        commit(ERROR, error.response.status);
        return null;
      }
    },
  }
};