import client from "./client";

export default {
  getOne(id) {
    return client.get("/v2/tasks/" + id);
  },
  getBy(filters) {
    return client.get("/v2/tasks", {
      params: filters
    });
  },
  update(id, data) {
    return client.post("/api/tasks/update/"+id, {
      data: data
    });
  },
};