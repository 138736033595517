var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "M4.5 17.55C4.35 17.55 4.22933 17.5 4.138 17.4C4.046 17.3 4 17.1833 4 17.05C4 16.9 4.046 16.7793 4.138 16.688C4.22933 16.596 4.35 16.55 4.5 16.55H20.5C20.65 16.55 20.771 16.596 20.863 16.688C20.9543 16.7793 21 16.9 21 17.05C21 17.1833 20.9543 17.3 20.863 17.4C20.771 17.5 20.65 17.55 20.5 17.55H4.5ZM4.5 12.775C4.35 12.775 4.22933 12.725 4.138 12.625C4.046 12.525 4 12.4083 4 12.275C4 12.125 4.046 12.004 4.138 11.912C4.22933 11.8207 4.35 11.775 4.5 11.775H20.5C20.65 11.775 20.771 11.825 20.863 11.925C20.9543 12.025 21 12.1417 21 12.275C21 12.425 20.9543 12.546 20.863 12.638C20.771 12.7293 20.65 12.775 20.5 12.775H4.5ZM4.5 8C4.35 8 4.22933 7.954 4.138 7.862C4.046 7.77067 4 7.65 4 7.5C4 7.36667 4.046 7.25 4.138 7.15C4.22933 7.05 4.35 7 4.5 7H20.5C20.65 7 20.771 7.05 20.863 7.15C20.9543 7.25 21 7.36667 21 7.5C21 7.65 20.9543 7.77067 20.863 7.862C20.771 7.954 20.65 8 20.5 8H4.5Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }