import client from "./client";

export default {

    list() {
        return client.get("/v2/processes");
    },

    view(id) {
        return client.get("/v2/processes/" + id);
    },

};