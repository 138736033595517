import client from "./client";

export default {
  connectInstitution(data) {
    return client.get("/api/bank/institution/connect", {
      params: data
    });
  },
  updatePlaidItem(itemId) {
    return client.get("/api/bank/plaid/update/"+itemId);
  },

  connectBridge(payload) {
    return client.get('/api/bank/bridge/connect', {
      params: payload,
    })
  },

  connectPlaid() {
    return client.get("/api/bank/plaid/connect");
  },
  validatePro() {
    return client.get("/api/bank/bridge/pro/validation");
  },
  deleteItem(itemid) {
    return client.delete("/api/bank/deleteItem/" + itemid);
  },
  updateItem(itemid) {
    return client.get("/api/bank/bridge/updateItem/" + itemid);
  },
  getItems() {
    return client.get("/api/bank/items");
  },
  institutions(data) {
    return client.get("/api/bank/institutions", {
      params: data
    });
  },
  exchangePlaidToken(data) {
    return client.post("/api/bank/plaid/exchange", data);
  },
  addNordigenItem(data) {
    return client.post("/api/bank/nordigen/createItem", data);
  },

  updateNordigenItem(payload)
  {
    return client.put("/api/bank/nordigen/" + payload.id, {
      requisitionId: payload.requisitionId,
    });
  },

  updateNordigenConnection(payload)
  {
    return client.put("api/bank/nordigen/" + payload.id + "/connect");
  }
};