import { AxiosError } from "axios";
import CurrencyAPI from "../api/Currency";

const 
  LOADING = "LOADING",
  SET = "SET",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        loading: false,
        currencies: [],
    },

    getters: {

        error(state) {
            return state.error;
        },

        loading(state) {
            return state.loading;
        },

        currencies(state) {
            return state.currencies;
        },

    },

    mutations: {

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {Array} currencies
         */
        [SET](state, currencies)
        {
            state.currencies = currencies;
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

    },

    actions: {

        async list({commit}){

            commit(LOADING, true);
            try {

                const response = await CurrencyAPI.list();

                commit(LOADING, false);
                commit(SET, response.data);
                commit(ERROR, null);

                return response;

            } catch (error) {

                commit(LOADING, false);
                commit(ERROR, error);

                return null;
            }
        },

    },

}