<template>
    <l1-modal width="490px" v-model="showModal" :loading="false" :isCancellable="false" :persistent="false" :isClosable="true" :actions="true" @close="cancelModal">
        <template v-slot:title>
            <span class="f-2">
                {{ $t('commons.unsaved-change.title') }}
            </span>
        </template>
        <template v-slot:content>
            <span class="f-5">
                {{ $t('commons.unsaved-change.desc') }}
            </span>
        </template>
        <template v-slot:actions >
            <v-btn 
                color="grey-8"
                class="mr-2"
                text
                @click="cancelModal"
            >
                {{ $t('commons.buttons.cancel') }}
            </v-btn>
            <v-btn
                color="grey-8"
                class="white--text"
                @click="redirectWithChanges"
                depressed
            >
                {{ $t('commons.buttons.discard') }}
            </v-btn>
        </template>
    </l1-modal>
</template>

<script>
    import {mapGetters} from 'vuex';
    import mixin from '../../Mixins/mixin.js';
    import L1Modal from "./Modal";

    export default {
        mixins: [mixin],
        components: {
            L1Modal,
        },

        computed: {
            ...mapGetters('preventRedirect', {
                show: 'isShown',
                nextRouteUrl : "getNextRouteUrl"
            }),

            showModal: {
                get(){
                    return this.show;
                },
                set(value){
                    this.cancelModal()
                    return this.show
                }
            },
        },

        methods: {
            async redirectWithChanges(){    
                let routeToNavigate = this.nextRouteUrl
                await this.$store.dispatch('preventRedirect/reset')
                this.$router.push({path: routeToNavigate})
            },
            async cancelModal() {
                await this.$store.dispatch('preventRedirect/cancelRedirection')
            }
        },
    }
</script>
<style lang="scss" scoped>
</style>