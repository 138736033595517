var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("l1-modal", {
    attrs: {
      width: "490px",
      loading: false,
      isCancellable: false,
      persistent: false,
      isClosable: true,
      actions: true,
    },
    on: { close: _vm.cancelModal },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("span", { staticClass: "f-2" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("commons.unsaved-change.title")) +
                  "\n        "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("span", { staticClass: "f-5" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("commons.unsaved-change.desc")) +
                  "\n        "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                staticClass: "mr-2",
                attrs: { color: "grey-8", text: "" },
                on: { click: _vm.cancelModal },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.buttons.cancel")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "white--text",
                attrs: { color: "grey-8", depressed: "" },
                on: { click: _vm.redirectWithChanges },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("commons.buttons.discard")) +
                    "\n        "
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }