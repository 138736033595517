import { AxiosError } from "axios";
import SpendsApi from "../api/Spends";

const 
  FETCHED = "FETCHED",
  LOADING = "LOADING",
  RESET = "RESET",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        loading: false,
        spends: [],
    },

    getters: {

        error(state) {
            return state.error;
        },

        loading(state) {
            return state.loading;
        },

        spends(state) {
            return state.spends;
        },

    },

    mutations: {

        /**
         * @param {Array} spends
         */
        [FETCHED](state, spends)
        {
          state.spends = spends;
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

        [RESET](state) {
          state.spends = [];
        },

    },

    actions: {

      async get({ commit }, query) {
        commit(LOADING, true);
        commit(ERROR, null);
        
        try {
          const response = await SpendsApi.get(query);

          commit(LOADING, false);
          commit(FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(RESET);
          commit(ERROR, error);
        }
      },

      reset({commit}) {
        commit(RESET);
      },

    },
}