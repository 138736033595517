<template>
    <span 
        :class="(isCollapse)?'menu__title--line':'menu__title'"
    > 
        <span v-if="!isCollapse && title">{{ title }}</span>
        <span v-else-if="!isCollapse && !title" class="w-100" style="border-top: 1px solid #6B7280;"></span>
    </span>
</template>
<script>
    export default {
        name: "menu-title",
        props: {
            title: {
                type: String,
                default: ''
            },
            isCollapse: {
                type: Boolean,
                default: false,
            }
        },
    }
</script>
<style lang="scss" scoped>
    .menu__title {
        color: var(--v-gray-50-base);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-transform: capitalize;
        &--line {
            display: block;
            height: 14px;
            border: 0;
            width:50%;
            border-bottom: 2px solid var(--v-gray-50-base);
        }
    }
</style>

