import { AxiosError } from "axios";
import LicenceTagApi from "../api/licenceTag";
import Vue from "vue";

const 
  FETCHED = "FETCHED",
  CREATED = "CREATED",
  LOADING = "LOADING",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        loading: false,
        tags: [],
        tag: null,
    },

    getters: {

        error(state) {
            return state.error;
        },

        loading(state) {
            return state.loading;
        },

        tags(state) {
            return state.tags;
        },

        tag(state) {
            return state.tag;
        },
    },

    mutations: {

        /**
         * @param {Array} tags
         */
        [FETCHED](state, tags)
        {
          state.tags = tags;
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {Array} tag
         */
        [CREATED](state, tag)
        {
          state.tag = tag;
          Vue.set(state.tags, state.tags.length, tag)
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.tag = null;
            state.error = error;
        },

    },

    actions: {

      /**
       * @param {Object} tag
       */
      async create({ commit }, tag) {
        
        commit(LOADING, true);
        commit(ERROR, null);

        try {

          const response = await LicenceTagApi.create(tag);

          commit(LOADING, false);
          commit(CREATED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },

      async get({ commit }) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {
          const response = await LicenceTagApi.get();

          commit(LOADING, false);
          commit(FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },
    },
}