var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12.75 10.5L10.5 10.75V11.25H11C11.25 11.25 11.25 11.4399 11.25 11.7515V15C11.25 15.3599 11.1123 15.4194 10.5 15.5V16H13.5V15.5C12.8877 15.4194 12.75 15.3599 12.75 15V10.5ZM12 9.5C12.4142 9.5 12.75 9.16421 12.75 8.75C12.75 8.33579 12.4142 8 12 8C11.5858 8 11.25 8.33579 11.25 8.75C11.25 9.16421 11.5858 9.5 12 9.5Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }