import CompanyAPI from "../api/company";
import GoogleAPI from "../api/google";

const PROVIDING_DATA_ON_REFRESH_SUCCESS = "PROVIDING_DATA_ON_REFRESH_SUCCESS",
  SANDBOX_DISABLED = "SANDBOX_DISABLED",
  SANDBOX_ENABLED = "SANDBOX_ENABLED",
  GOOGLE_ACCESS_REVOKED = "GOOGLE_ACCESS_REVOKED",
  FETCHING = "FETCHING",
  FETCHING_SUCCESS = "FETCHING_SUCCESS",
  FETCHING_ERROR = "FETCHING_ERROR",
  PLAID_REMOVE_ITEM = "PLAID_REMOVE_ITEM",
  ACCOUNT_DELETED = "ACCOUNT_DELETED",
  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",
  DEFAULT = "DEFAULT",
  DEFAULT_SUCCESS = "DEFAULT_SUCCESS",
  DEFAULT_ERROR = "DEFAULT_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    company: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    get(state) {
      return state.company;
    },
    onboarded(state) {
      return state.company.onboardingStep === -1;
    },
  },
  mutations: {
    [UPDATING](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_SUCCESS](state, company) {
      state.isLoading = false;
      state.error = null;
      state.company = company;
    },
    [UPDATING_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_SUCCESS](state, company) {
      state.isLoading = false;
      state.error = null;
      state.company = company;
    },
    [FETCHING_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [PROVIDING_DATA_ON_REFRESH_SUCCESS](state, company) {
      state.isLoading = false;
      state.error = null;
      state.company = company;
    },
    [GOOGLE_ACCESS_REVOKED](state){
      state.error                   = null;
      state.isLoading               = false;
      state.company.isGsuiteEnabled = false;
    },
    [PLAID_REMOVE_ITEM](state, id){
      state.error              = null;
      state.isLoading          = false;
      const indexToDelete = _.findIndex(state.company.plaidItems, function(i) { return i.plaidId == id; })
      if(state.company.plaidItems[indexToDelete]){
        state.company.plaidItems.splice(indexToDelete, 1)
      }
    },

    [SANDBOX_DISABLED](state) {
      state.error           = null;
      state.isLoading       = false;
      state.company.sandbox = false;
    },
    [SANDBOX_ENABLED](state) {
      state.error           = null;
      state.isLoading       = false;
      state.company.sandbox = true;
    },

    [ACCOUNT_DELETED](state) {
      state.error = null;
      state.isLoading = false;
    },

    [DEFAULT](state) {
      state.isLoading = true;
      state.error = null;
    },
    [DEFAULT_SUCCESS](state, company) {
      state.isLoading = false;
      state.error = null;
    },
    [DEFAULT_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },

  },
  actions: {
    removePlaidItem({commit}, plaidId) {
      commit(PLAID_REMOVE_ITEM, plaidId);
    },
    onRefresh({commit}, company) {
      commit(PROVIDING_DATA_ON_REFRESH_SUCCESS, company);
    },
    async update({commit}, payload) {
        commit(UPDATING);
        try {
            let response = await CompanyAPI.update(payload);
            commit(UPDATING_SUCCESS, response.data);
            return response.data;
        } catch (error) {
            commit(UPDATING_ERROR, error);
            return null;
        }
    },
    async get({commit}) {
      commit(FETCHING);
      try {
        let response = await CompanyAPI.get();
        commit(FETCHING_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_ERROR, error);
        return null;
      }
    },

    async revokeGoogleAccess({commit}) {

      commit(UPDATING);

      try {

        let response = await GoogleAPI.revoke();
        commit(GOOGLE_ACCESS_REVOKED);
        return response;

      } catch (error) {

        commit(UPDATING_ERROR, error);
        return null;
      }
    },

    async enableSandbox({commit}) {

      commit(UPDATING);

      try {
        await CompanyAPI.enableSandbox();
        commit(SANDBOX_ENABLED);
      } catch (error) {
        commit(UPDATING_ERROR, error);
      }
    },

    async disableSandbox({commit}) {

      commit(UPDATING);

      try {
        await CompanyAPI.disableSandbox();
        commit(SANDBOX_DISABLED);
      } catch (error) {
        commit(UPDATING_ERROR, error);
      }
    },

    async delete({commit}) {

      commit(UPDATING);

      try{
        await CompanyAPI.delete();
        commit(ACCOUNT_DELETED);
      }catch(error) {
        commit(UPDATING_ERROR, error);
      }
    },

    async create({commit}, payload) {
      commit(DEFAULT);
        try {
            let response = await CompanyAPI.create(payload);
            commit(DEFAULT_SUCCESS, response.data);
            return response.data;
        } catch (error) {
            commit(DEFAULT_ERROR, error);
            return null;
        }
    }

  }
}