<template>
    <l1-modal 
        v-if="isGranted(permissions.SEE_ADMIN_SECTIONS)" 
        :persistent="persistent" 
        :needPadding="false"
        :actions="actions" 
        v-model="opened" 
        width="923px"
        needBorderRadius
        noTitle 
    >
        <template v-slot:opener>
            <slot name="openerModal"/>
        </template>
        <template v-slot:content>
            <div class="d-flex trial-modal">
                <div class="trial-modal__info relative">
                    <img class="trial-modal__illu" :src="subscriptionModalImg">
                    <div class="trial-info d-flex flex-column justify-space-between border-bottom py-14 px-10">
                        <span>
                            <h2 class="fw-7 f-3 mb-3">{{ $t('company.subscription.'+subscriptionModalState) }}</h2>
                            <p class="f-5 mb-0">{{ $t('company.subscription.desc')}}</p>
                        </span>
                        <span class="mb-6" >
                            <h3 class="f-5 fw-6 mb-2">{{$t('company.subscription.link.title')}}</h3>
                            <p class="f-6 pb-2">{{$t('company.subscription.link.desc')}}</p>
                            <span 
                                :class="'trial-modal__link cpointer d-flex align-center justify-center'"
                                @mouseover="isLinkHover = true" 
                                @mouseleave="isLinkHover = false"
                                @click="copyText"
                            >
                                <v-progress-circular
                                    v-if="!checkoutUrl"
                                    indeterminate
                                    :width="2"
                                    :size="23"
                                    color="grey"
                                />
                                <div v-else-if="isLinkCopied" class="d-flex align-center justify-center">
                                    <span>{{ $t('commons.buttons.copied')}}</span>
                                </div>
                                <span v-else-if="!isLinkHover" class="d-block truncate">{{checkoutUrl}}</span>
                                <div v-else class="d-flex align-center justify-center">
                                    <span>{{ $t('commons.buttons.copy-link')}}</span>
                                    <span :class="'f-2 gray-50--text icon-copy'" />
                                </div>
                            </span>
                        </span>
                    </div>
                    <div class="trial-testimonial d-flex align-center justify-center pa-10">
                        <div class="trial-testimonial__photo">
                            <img :src="victorImg">
                        </div>
                        <div class="pt-2 pb-0 d-flex justify-center flex-column">
                            <img class="mb-2 trial-testimonial__logo" :src="sellsyImg">
                            <p class="mb-2 f-6" v-html="$t('company.trial.ended.testi.text')"/>
                            <div class="grey-5--text f-6">{{ $t('company.trial.ended.testi.author') }}</div>
                        </div>
                    </div>
                </div>
                <div class="trial-modal__payment border-left py-8 px-6">
                    <tab-system class="mb-6 w-fit" v-model="billingPeriod" :defaultValue="billingPeriod" :tabs="types"/>
                    <div class="d-flex gap-4 mb-6">
                        <large-checkbox :id="'individual'" :isActive="activeChoice == 'individual'" @updateChoice="activeChoice = 'individual'">
                            <template #content>
                                <p class="mb-0 f-5 fw-6">{{ $t('company.subscription.track.yourself') }}</p>
                                <span>
                                    <p class="mb-0 f-7 fw-4 gray-40--text">
                                        {{formatNumber((billingPeriod == 'year')? (totalIndividualYearlyPrice)/12 : (totalIndividualMonthlyPrice)*12, getSystemCurrency())}}
                                        {{(billingPeriod == 'year')? $t('billing.account.usage.listing.by-month'):$t('billing.account.usage.listing.by-year')}}
                                    </p>
                                    <p class="mb-0 f-4 fw-7">
                                        {{formatNumber((billingPeriod == 'year')? totalIndividualYearlyPrice : totalIndividualMonthlyPrice, getSystemCurrency())}}
                                        {{(billingPeriod == 'year')? $t('billing.account.usage.listing.by-year'):$t('billing.account.usage.listing.by-month')}}
                                    </p>
                                </span>
                            </template>
                        </large-checkbox>
                        <large-checkbox v-if="numberOfTrackedEmployees > 1" :id="'employees'" :isActive="activeChoice == 'employees'" @updateChoice="activeChoice = 'employees'">
                            <template #content>
                                <p class="mb-0 f-5 fw-6">{{ $tc('company.subscription.track.employees', numberOfTrackedEmployees, {'count': numberOfTrackedEmployees}) }}</p>
                                <span>
                                    <p class="mb-0 f-7 fw-4 gray-40--text">
                                        {{formatNumber((billingPeriod == 'year')? (totalYearlyPrice)/12 : (totalMonthlyPrice)*12, getSystemCurrency())}}
                                        {{(billingPeriod == 'year')? $t('billing.account.usage.listing.by-month'):$t('billing.account.usage.listing.by-year')}}                                    
                                    </p>
                                    <p class="mb-0 f-4 fw-7">
                                        {{formatNumber((billingPeriod == 'year')? totalYearlyPrice : totalMonthlyPrice, getSystemCurrency())}}
                                        {{(billingPeriod == 'year')? $t('billing.account.usage.listing.by-year'):$t('billing.account.usage.listing.by-month')}}
                                    </p>
                                </span>
                            </template>
                        </large-checkbox>
                    </div>
                    <div class="grey-8--text mb-8">
                        <span 
                            v-for="item in billingItems" 
                            :key="item.label"
                        >
                            <billing-item 
                                v-if="!item.isDisabled"
                                :label="item.label"
                                :price="item.price"
                                :oldPrice="item.oldPrice ?? null"
                                :descPrice="item.descPrice ?? null"
                            />
                        </span>
                        <div class="d-flex justify-space-between mt-4">
                            <div class="text-left grey-8--text f-4 fw-7">{{ $t('billing.total') }}</div>
                            <div class="text-right">
                                <span class='grey-8--text f-4 fw-7'>{{ formatNumber(priceTotal, getSystemCurrency()) }}</span>
                                <span>{{ $t('billing.excl-vat') }}</span>
                            </div>
                        </div>
                    </div>
                    <v-btn color="grey-8" :loading="!checkoutUrl" :disabled="!checkoutUrl" block elevation="0" class="rounded-lg mb-2 white--text" @click="redirectTo(checkoutUrl)">{{$t('company.trial.ended.card.subscribe')}}</v-btn>
                    <v-btn color="gray-90" block elevation="0" class="rounded-lg" @click="openChat()">{{ $t('company.trial.ended.card.chat') }}</v-btn>
                </div>
            </div>
        </template>
    </l1-modal>
    <request-subscription-modal v-model="opened" v-else/>
</template>

<script>
    import {mapGetters} from 'vuex';
    import TabSystem from '@app/js/components/TabSystem';
    import L1Button from '@app/js/components/form/button/Button';
    import mixin from '@app/js/Mixins/mixin.js';
    import Security from '@app/js/Mixins/Security';
    import L1Modal from '@app/js/components/modal/Modal';
    import IconBase from '@app/js/components/IconBase';
    import IconArrowRight from '@app/js/components/icons/IconArrowRight';
    import LargeCheckbox from '@app/js/components/form/LargeCheckbox';
    import BillingItem from '@app/js/components/modal/subscription/BillingItem';
    import VictorImg from '@app/images/victor.png'
    import SellsyImg from '@app/images/sellsy.png'
    import ReturningSubscriptionImg from '@app/images/subscription-returning.svg'
    import UpgradingSubscriptionImg from '@app/images/subscription-upgrading.svg'
    import EndingSubscriptionImg from '@app/images/subscription-ending.svg'


    export default {
        name: "trial-modal",
        mixins: [mixin, Security],
        data () {
            return {
                loading: true,
                billingPeriod: "month",
                activeChoice: 'individual',
                isLinkHover: false,
                types: [
                    {value: 'month', label: this.$i18n.t('licences.cycle.monthly')},
                    {value: 'year', label: this.$i18n.t('licences.cycle.yearly')},
                ],
                victorImg: VictorImg,
                sellsyImg: SellsyImg,
                subscriptionModalImg: this.returningSubscriptionImg,
                returningSubscriptionImg: ReturningSubscriptionImg,
                upgradingSubscriptionImg: UpgradingSubscriptionImg,
                endingSubscriptionImg: EndingSubscriptionImg,
                isLinkCopied: false,
            }
        },
        async created(){
            let promises = []
            promises.push(this.$store.dispatch("subscription/get"))
            
            if(this.isGranted(this.permissions.SEE_ADMIN_SECTIONS)){
                promises.push(this.$store.dispatch("subscription/getPlansUrl"))
            }

            await Promise.all(promises);

            this.loading = false;
        },
        watch: {
            opened(val){
                if(val){
                    this.$store.dispatch("subscription/updateSubscriptionInitiator")
                    this.$store.dispatch("user/list");
                    const isAdmin = this.isGranted(this.permissions.SEE_ADMIN_SECTIONS)
                    if(this.state == 'end_trial'){
                        if(isAdmin){
                            this.logToSegment('endTrialModalAdmin')
                        }else{
                            this.logToSegment('endTrialModalViewer')
                        }
                    }else if(this.state == 'cancelled'){
                        if(isAdmin){
                            this.logToSegment('cancelSubscriptionModalAdmin')
                        }else{
                            this.logToSegment('cancelSubscriptionModalViewer')
                        }
                    }else if(this.state == 'in_trial'){
                        if(isAdmin){
                            this.logToSegment('inTrialSubscriptionModalAdmin')
                        }else{
                            this.logToSegment('inTrialSubscriptionModalViewer')
                        }
                        
                    }
                }
            }
        },
        components: {
            L1Button,
            L1Modal,
            IconBase,
            TabSystem,
            IconArrowRight,
            LargeCheckbox,
            BillingItem
        },
        props: {
            value: {
                type: Boolean,
            },
            persistent: {
                type: Boolean,
                default: true
            },
            actions: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            copyText() {
                navigator.clipboard.writeText(this.checkoutUrl);
                this.isLinkCopied = true;
                setTimeout(() => {
                    this.isLinkCopied = false;                
                }, 2000)
            },
        },
        computed: {

            checkoutUrl(){
                if(this.billingPeriod == 'year'){
                    if(this.activeChoice == "individual") {
                        return this.checkoutIndividualYearly
                    }
                    return this.checkoutYearly
                }else{
                    if(this.activeChoice == "individual") {
                        return this.checkoutIndividualMonthly
                    }
                    return this.checkoutMonthly
                }
            },

            ...mapGetters('subscription', {
                subscription: 'get',
                checkoutYearly: 'getCheckoutYearlyUrl',
                checkoutMonthly: 'getCheckoutMonthlyUrl',
                checkoutIndividualYearly: 'getCheckoutIndividualYearlyUrl',
                checkoutIndividualMonthly: 'getCheckoutIndividualMonthlyUrl'
            }),
            ...mapGetters('company', {
                company : 'get',
            }),

            numberOfEmployeesWantedToTrack() {
                if(this.activeChoice == 'employees') {
                    return this.numberOfTrackedEmployees
                } else if(this.activeChoice == "individual") {
                    return 1
                }
            },

            totalYearlyPrice() {
                return (this.inventoryPricePerYear - this.discountedInventoryPrice) + (this.unitPricePerYear - this.discountedUnitPrice) * this.numberOfTrackedEmployees
            },

            totalIndividualYearlyPrice() {
                return (this.inventoryPricePerYear - this.discountedInventoryPrice) + (this.unitPricePerYear - this.discountedUnitPrice)
            },

            totalMonthlyPrice() {
                return this.inventoryPricePerMonth + this.unitPricePerMonth  * this.numberOfTrackedEmployees
            },

            totalIndividualMonthlyPrice() {
                return this.inventoryPricePerMonth +this.unitPricePerMonth
            },

            discountedUnitPrice(){
                return this.unitPricePerMonth * this.freeMonths
            },

            discountedInventoryPrice(){
                return this.inventoryPricePerMonth * this.freeMonths
            },

            referalDiscountPrice() {
                if(this.activeChoice == 'employees') {
                    return (this.billingPeriod === 'year')? this.totalYearlyPrice * this.yearlyReferralDiscountPercent : this.totalMonthlyPrice * this.monthlyReferralDiscountPercent
                } else if(this.activeChoice == "individual") {
                    return (this.billingPeriod === 'year')? this.totalIndividualYearlyPrice * this.yearlyReferralDiscountPercent : this.totalIndividualMonthlyPrice * this.monthlyReferralDiscountPercent
                }
            },

            billingItems() {
                return [
                    {
                        label: this.$t('billing.account.usage.listing.app-inventory'),
                        price: 
                            this.billingPeriod === 'year' 
                            ? this.inventoryPricePerYear - this.discountedInventoryPrice
                            : this.inventoryPricePerMonth,
                        oldPrice: 
                            this.billingPeriod === 'year' 
                            ? this.inventoryPricePerYear
                            : null,
                        descPrice: 
                            this.billingPeriod === 'year' 
                            ? null : this.$t('billing.account.usage.listing.by-month')
                    },
                    {
                        label: this.$tc('billing.account.usage.listing.tracked-employee-'+this.billingPeriod, this.numberOfEmployeesWantedToTrack),
                        price: 
                            this.billingPeriod === 'year' 
                            ? (this.unitPricePerYear - this.discountedUnitPrice) * this.numberOfEmployeesWantedToTrack
                            : this.unitPricePerMonth * this.numberOfEmployeesWantedToTrack,
                        oldPrice: 
                            this.billingPeriod === 'year' 
                            ? this.unitPricePerYear * this.numberOfEmployeesWantedToTrack
                            : null,
                        descPrice: this.billingPeriod === 'year' ? null : this.$t('billing.account.usage.listing.by-month')
                    },
                    { label: this.$t('billing.account.usage.listing.tracking-app'), price: 0 },
                    { label: this.$t('billing.account.usage.listing.tracking-date'), price: 0 },
                    { label: this.$t('billing.account.usage.listing.user-offboarding'), price: 0 },
                    { label: this.$t('billing.account.usage.listing.notification'), price: 0 },
                    { 
                        label: this.$t('billing.account.usage.referral'), 
                        price: -this.referalDiscountPrice, 
                        isDisabled: this.subscription && !this.subscription.ucc
                    }
                ];
            },

            priceTotal() {
                let totalPrice = 0

                if(this.activeChoice == 'employees') {
                    totalPrice = (this.billingPeriod === 'year')? this.totalYearlyPrice  : this.totalMonthlyPrice 
                } else if(this.activeChoice == "individual") {
                    totalPrice = (this.billingPeriod === 'year')? this.totalIndividualYearlyPrice : this.totalIndividualMonthlyPrice
                }

                if (this.subscription.ucc?.length == 11) {
                    return  (this.billingPeriod === 'year')? totalPrice - (totalPrice*this.yearlyReferralDiscountPercent) : totalPrice - (totalPrice*this.monthlyReferralDiscountPercent);
                }

                return totalPrice;

            }, 

            opened: {
                get(){
                    return this.value;
                },
                set(value){
                    this.$emit('input', value);
                }
            },

            subscriptionModalState() {
               if(this.subscription.status == "cancelled") {
                    if(this.subscription.subscriptionEndDate) {
                        this.subscriptionModalImg = this.returningSubscriptionImg
                        return 'returning'
                    }
                     this.subscriptionModalImg = this.endingSubscriptionImg
                    return 'endingTrial'
                }
                this.subscriptionModalImg = this.upgradingSubscriptionImg
                return 'upgrading'
            }
        }
    }
</script>
<style lang="scss">
    .trial-modal {
        &__info {
            width: 42%;
        }
        &__payment {
            width: 58%;
            background: var(--v-gray-90-base);
            border-radius: 0 4px 4px 0;
        }
        &__link {
            border-radius: 4px;
            height: 44px;
            padding: 12px 16px;
            border: 1px solid var(--v-gray-70-base);
            background: var(--v-gray-80-base);
            color: var(--v-gray-40-base);
        }
        &__illu {
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
    .trial-info {
        height: 65%;
    }
    .trial-testimonial {
        height: 35%;
        &__photo {
            height: 113px;
        }
        &__logo {
            height: 20px;
            width: fit-content;
        }
    }
</style>
