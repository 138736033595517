var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        "menu__item cpointer" +
        (_vm.item.routeName == _vm.actualRoute ? " menu__item--active" : "") +
        (_vm.item.icon ? "" : " ml-9"),
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            right: "",
            "content-class": "right-tip",
            "nudge-left": "-27",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _vm.item.icon
                    ? _c(
                        "div",
                        _vm._g(
                          {
                            class:
                              "d-flex align-center justify-center" +
                              (_vm.isCollapse ? " mr-0" : " mr-3"),
                          },
                          on
                        ),
                        [
                          _vm.item.hasDot
                            ? _c(
                                "v-badge",
                                {
                                  attrs: {
                                    dot: "",
                                    color: "red-30",
                                    "offset-x": "4",
                                    "offset-y": "4",
                                  },
                                },
                                [
                                  _c("span", {
                                    class:
                                      "f-2 white--text icon-" + _vm.item.icon,
                                  }),
                                ]
                              )
                            : _c("span", {
                                class: "f-2 white--text icon-" + _vm.item.icon,
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", { staticClass: "m-10" }, [_vm._v(_vm._s(_vm.item.title))]),
        ]
      ),
      _vm._v(" "),
      !_vm.isCollapse
        ? _c(
            "div",
            { staticClass: "relative w-100 fw-450" },
            [
              _vm.item.hasDot && !_vm.item.icon
                ? _c(
                    "v-badge",
                    {
                      attrs: {
                        dot: "",
                        color: "red-30",
                        "offset-x": "0",
                        "offset-y": "4",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.item.title) + "\n        "
                      ),
                    ]
                  )
                : _c("span", [
                    _vm._v(
                      "\n            " + _vm._s(_vm.item.title) + "\n        "
                    ),
                  ]),
              _vm._v(" "),
              _vm.item.new
                ? _c(
                    "span",
                    { staticClass: "info--text absolute new-feature-menu" },
                    [_vm._v(_vm._s(_vm.$t("commons.new")))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }