var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex radio-btn-group filter grey-1 radius tabSystem" },
    _vm._l(_vm.tabs, function (n, index) {
      return _c("div", { key: index, staticClass: "radio" }, [
        !n.isDisabled
          ? _c("div", { staticClass: "pa-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputModel,
                    expression: "inputModel",
                  },
                ],
                attrs: {
                  name: Math.random(),
                  id: _vm.timestamp + index,
                  type: "radio",
                },
                domProps: {
                  value: n.value,
                  checked: _vm._q(_vm.inputModel, n.value),
                },
                on: {
                  change: [
                    function ($event) {
                      _vm.inputModel = n.value
                    },
                    function ($event) {
                      _vm.updateValue(n.value)
                      n.track ? _vm.logToSegment(n.track) : ""
                    },
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "px-4 cpointer",
                  attrs: { for: _vm.timestamp + index },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(n.label) + "\n            "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }