var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M6.14645 9.64645C6.34171 9.45118 6.65829 9.45118 6.85355 9.64645L12 14.5L17.1464 9.64645C17.3417 9.45118 17.6583 9.45118 17.8536 9.64645C18.0488 9.84171 18.0488 10.1583 17.8536 10.3536L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.14645 10.3536C5.95118 10.1583 5.95118 9.84171 6.14645 9.64645Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }