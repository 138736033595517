var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "M4.19995 17.275C4.04995 17.275 3.92895 17.225 3.83695 17.125C3.74562 17.025 3.69995 16.9083 3.69995 16.775C3.69995 16.625 3.74562 16.5043 3.83695 16.413C3.92895 16.321 4.04995 16.275 4.19995 16.275H15.2C15.3333 16.275 15.45 16.321 15.55 16.413C15.65 16.5043 15.7 16.625 15.7 16.775C15.7 16.9083 15.65 17.025 15.55 17.125C15.45 17.225 15.3333 17.275 15.2 17.275H4.19995ZM4.19995 12.5C4.04995 12.5 3.92895 12.45 3.83695 12.35C3.74562 12.25 3.69995 12.1333 3.69995 12C3.69995 11.85 3.74562 11.729 3.83695 11.637C3.92895 11.5456 4.04995 11.5 4.19995 11.5H12.2C12.3333 11.5 12.45 11.55 12.55 11.65C12.65 11.75 12.7 11.8666 12.7 12C12.7 12.15 12.65 12.271 12.55 12.363C12.45 12.4543 12.3333 12.5 12.2 12.5H4.19995ZM4.19995 7.72498C4.04995 7.72498 3.92895 7.67898 3.83695 7.58698C3.74562 7.49564 3.69995 7.37498 3.69995 7.22498C3.69995 7.09164 3.74562 6.97498 3.83695 6.87498C3.92895 6.77498 4.04995 6.72498 4.19995 6.72498H15.2C15.3333 6.72498 15.45 6.77498 15.55 6.87498C15.65 6.97498 15.7 7.09164 15.7 7.22498C15.7 7.37498 15.65 7.49564 15.55 7.58698C15.45 7.67898 15.3333 7.72498 15.2 7.72498H4.19995ZM16.9 12L20.15 15.25C20.25 15.3333 20.3 15.4456 20.3 15.587C20.3 15.729 20.25 15.85 20.15 15.95C20.05 16.0666 19.9333 16.125 19.8 16.125C19.6666 16.125 19.55 16.0666 19.45 15.95L16.05 12.575C15.8833 12.4083 15.8 12.2166 15.8 12C15.8 11.7833 15.8833 11.5916 16.05 11.425L19.45 8.04998C19.5333 7.94998 19.646 7.89564 19.788 7.88698C19.9293 7.87898 20.05 7.93331 20.15 8.04998C20.25 8.14998 20.3 8.26664 20.3 8.39998C20.3 8.53331 20.25 8.64998 20.15 8.74998L16.9 12Z",
      fill: "#F9FAFB",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }