"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HydraCollectionFormat = void 0;
class HydraCollectionFormat {
    constructor(response) {
        Object.assign(this, response.data);
    }
    getCollection() {
        return this["hydra:member"];
    }
    ;
    getTotal() {
        return this["hydra:totalItems"];
    }
    getFirstPage() {
        const url = new URLSearchParams(this["hydra:view"]["hydra:first"]);
        return Number(url.get('page'));
    }
    ;
    getPreviousPage() {
        const url = new URLSearchParams(this["hydra:view"]["hydra:previous"]);
        return Number(url.get('page'));
    }
    getCurrentPage() {
        const url = new URLSearchParams(this["hydra:view"]["@id"]);
        return Number(url.get('page'));
    }
    getNextPage() {
        const url = new URLSearchParams(this["hydra:view"]["hydra:next"]);
        return Number(url.get('page'));
    }
    getLastPage() {
        const url = new URLSearchParams(this["hydra:view"]["hydra:last"]);
        return Number(url.get('page'));
    }
}
exports.HydraCollectionFormat = HydraCollectionFormat;
