<template>
    <section id="onboarding-content" class="d-flex flex-column align-center justify-space-between">
        <stepper :steps="steps" :actualStep="actualStep" :transitionDuration="(steps[actualStep-1])? steps[actualStep-1].animationDuration : 2000" class="mt-6"></stepper>

          <transition v-if="isOnboardingUrl"  name="slide" mode="out-in">
            <router-view />
          </transition>
            <loader v-else></loader>


        <div class="onboarding-content__logo w-fit mb-10 mt-6">
            <v-img max-height="24" max-width="143" :src="logoSrc"></v-img>
        </div>
        <snackbar class="onboarding-content__snackbar"></snackbar>
    </section>
</template>

<script>
    import logoSrc from '@app/images/logo.svg';
    import {mapGetters} from 'vuex';
    import Snackbar from "../../components/Snackbar"
    import mixin from '../../Mixins/mixin';
    import Stepper from "../../components/layout/Stepper";
    import Loader from  '../../components/loaders/Loader.vue'

    export default {
        name: 'layout-onboarding',
        mixins: [mixin],
        components: {
            Stepper,
            Snackbar,
            Loader
        },
        data() {
        return {
            logoSrc: logoSrc,
            steps: [
                {
                    id: 1,
                    urlName: "onboarding.user",
                    label: this.$t('users.onboarding.stepper.create-profile'),
                    animationDuration: 2000
                },
                {
                    id: 2,
                    urlName: "onboarding.data-sources",
                    label: this.$t('users.onboarding.stepper.detect-app'),
                    animationDuration: 10000
                },
                {
                    id: 3,
                    urlName: "onboarding.track-activity",
                    label: this.$t('users.onboarding.stepper.track-activity'),
                    animationDuration: 2000
                },
            ],
        }
        },

        computed: {
            ...mapGetters('company', {
                company: 'get',
            }),

            actualStep() {
                return this.company?.onboardingStep
            },

            isOnboardingUrl() {
              return this.onboardingStepUrl.indexOf(this.$route.name) !== -1 || this.doNotRedirectRoutesForOnboarding.indexOf(this.$route.name) !== -1
            }
        }
    } 
</script>

<style lang="scss" >
  #onboarding-content{
    height: 100%;
    background-image: url('/assets/images/onboarding-bg.svg'), linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    #row-content{
      height: 100%;
    }
  }
  .onboarding-content {
    &__logo {
        height: 24px;
        mix-blend-mode: luminosity;
        opacity: 0.5;
    }
    &__snackbar {
      margin-left: 150px;
    }
  }
  .onboarding {
    background-color: white;
    border: 1px solid var(--v-gray-70-base);
    border-radius: 8px;
  }

  .slide-enter-active{
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .slide-leave-active {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  @-webkit-keyframes slide-out-left{0%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}100%{-webkit-transform:translateX(-100vw);transform:translateX(-100vw);opacity:0}}@keyframes slide-out-left{0%{-webkit-transform:translateX(0);transform:translateX(0);opacity:1}100%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}}
  @-webkit-keyframes slide-left{0%{-webkit-transform:translateX(100vw);transform:translateX(100vw)}100%{-webkit-transform:translateX(0);transform:translateX(0)}}@keyframes slide-left{0%{-webkit-transform:translateX(100vw);transform:translateX(100vw)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}
</style>