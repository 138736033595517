export default {
  namespaced: true,
  state: {
    isOpen: false,
    context: {
      type: undefined,
      api: {
        endpoint: undefined,
        id: undefined
      }
    }
  },
  getters: {
    getContext: state => {
      return state.context
    },
    isOpen: state => {
      return state.isOpen
    },
  },
  mutations: {
    open(state, context){
      state.context = context;
      state.isOpen = true
    },
    close(state){
      state.context.type = undefined;
      state.context.id = undefined;
      state.isOpen = false;
    }
  }
};