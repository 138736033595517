var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c("v-img", {
        staticClass: "rounded-lg v-align-middle d-inline-block",
        attrs: { width: _vm.width, src: _vm.logoSrc, transition: "false" },
        on: { error: _vm.changeLogoSource },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }