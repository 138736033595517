import ProcessAPI from "../api/process";

const 
    FETCHING          = "FETCHING",
    FETCHING_SUCCESS  = "FETCHING_SUCCESS",
    FETCHING_ERROR    = "FETCHING_ERROR",

    FETCHING_ONE            = "FETCHING_ONE",
    FETCHING_ONE_SUCCESS    = "FETCHING_ONE_SUCCESS",
    FETCHING_ONE_ERROR      = "FETCHING_ONE_ERROR";

export default {

    namespaced: true,

    state: {
        isLoading:  false,
        errors:     null,
        processes:  [],
    },

    getters: {

        isLoading(state) {
            return state.isLoading;
        },

        hasErrors(state) {
            return state.errors !== null;
        },

        errors(state) {
            return state.errors;
        },

        list(state) {
            return state.processes;
        },

        inProgress(state) {
            return state.processes.filter(process => process.state === 'in_progress');
        },

        todo(state) {
            return state.processes.filter(process => process.state === 'todo');
        },

        done(state) {
            return state.processes.filter(process => process.state === 'done');
        },

        view: state => id => {
            let result = state.processes.filter(process => process.id === id);
            return result ? result[0] : null;
        },

    },

    mutations: {

        [FETCHING](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [FETCHING_SUCCESS](state, processes)
        {
            state.isLoading = false;
            state.errors    = null;
            state.processes = processes;
        },

        [FETCHING_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

        [FETCHING_ONE](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [FETCHING_ONE_SUCCESS](state, newProcess)
        {
            const processes = state.processes.filter(process => process.id !== newProcess.id);
            processes.push(newProcess);

            state.processes = processes;
            state.isLoading = false;
            state.errors    = null;
        },

        [FETCHING_ONE_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

    },

    actions: {

        async list({commit})
        {
            commit(FETCHING);

            try {

                let response = await ProcessAPI.list();
                commit(FETCHING_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(FETCHING_ERROR, error);
                return null;
            }
        },

        async view({commit}, id)
        {
            commit(FETCHING_ONE);
            try {

                let response = await ProcessAPI.view(id);
                commit(FETCHING_ONE_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(FETCHING_ONE_ERROR, error);
                return null;
            }
        },

    },

}