var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "M13.1353 8.15794C13.3242 7.95653 13.6407 7.9464 13.8421 8.13532L18 12L13.8421 15.8647C13.6407 16.0536 13.3242 16.0435 13.1353 15.8421C12.9464 15.6407 12.9565 15.3242 13.1579 15.1353L16.0337 12.5H7.5C7.22386 12.5 7 12.2761 7 12C7 11.7239 7.22386 11.5 7.5 11.5H16.0337L13.1579 8.86469C12.9565 8.67577 12.9464 8.35935 13.1353 8.15794Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }