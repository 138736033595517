var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M14.8535 6.14645C15.0488 6.34171 15.0488 6.65829 14.8535 6.85355L10 12L14.8535 17.1464C15.0488 17.3417 15.0488 17.6583 14.8535 17.8536C14.6583 18.0488 14.3417 18.0488 14.1464 17.8536L9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L14.1464 6.14645C14.3417 5.95118 14.6583 5.95118 14.8535 6.14645Z",
      fill: "#0A0D10",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }