var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-center",
      attrs: { id: "autocomplete-container" },
    },
    [
      _c("v-autocomplete", {
        ref: "topAutocomplete",
        staticClass: "shadow-none search-bar",
        attrs: {
          dense: "",
          "prepend-inner-icon": "icon-search",
          "background-color": "text-white",
          color: "grey-8",
          items: _vm.dataToSearchIn,
          "hide-details": "",
          "hide-selected": "",
          "solo-inverted": "",
          flat: "",
          "item-text": _vm.getItemName,
          label: _vm.$t("commons.layout.topbar.search.label"),
          "append-icon": null,
          "return-object": "",
        },
        on: {
          change: function ($event) {
            return _vm.logToSegment("usedSearchBar")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "v-list-item",
                  [
                    _c("v-list-item-title", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t("commons.layout.topbar.search.placeholder")
                          ) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                item.app
                  ? _c(
                      "div",
                      { staticClass: "searchRow" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticStyle: { "min-height": "46px" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "truncate rowContent",
                                staticStyle: { "max-width": "300px" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "rowIcon relative" },
                                  [
                                    _c("licence-logo", {
                                      staticClass: "vertical-align",
                                      attrs: { item: item.app },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "pl-4 rowTextContent" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          item.app
                                            ? item.app.name
                                            : item.customApp
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-caption rowSubtext",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$tc("licences.title", 1))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        item.department
                          ? _c("v-list-item-action", [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("v-chip", {
                                    staticClass: "ma-2 f-6",
                                    staticStyle: {
                                      width: "12px",
                                      height: "12px",
                                    },
                                    attrs: {
                                      color: item.department.color,
                                      label: "",
                                      small: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("strong", [
                                    _vm._v(_vm._s(item.department.name)),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.email
                  ? _c(
                      "div",
                      { staticClass: "searchRow" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticStyle: { "min-height": "46px" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "rowContent",
                                staticStyle: { "max-width": "300px" },
                              },
                              [
                                _c(
                                  "icon-base",
                                  {
                                    staticClass: "rowIcon",
                                    attrs: { "icon-name": "icon-users" },
                                  },
                                  [
                                    _c("icon-users-fill", {
                                      tag: "component",
                                      attrs: { fill: "gray" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "pl-4 rowTextContent truncate",
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(_vm._s(item.fullName)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-caption rowSubtext",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$tc("employee.titles", 1))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        item.department
                          ? _c("v-list-item-action", [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("v-chip", {
                                    staticClass: "ma-2 f-6",
                                    staticStyle: {
                                      width: "12px",
                                      height: "12px",
                                    },
                                    attrs: {
                                      color: item.department.color,
                                      label: "",
                                      small: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("strong", [
                                    _vm._v(_vm._s(item.department.name)),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }