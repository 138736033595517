import DepartmentAPI from "../api/department";

const 
    FETCHING_DEPARTMENTS = "FETCHING_DEPARTMENTS",
    FETCHING_DEPARTMENTS_SUCCESS = "FETCHING_DEPARTMENTS_SUCCESS",
    FETCHING_DEPARTMENTS_ERROR = "FETCHING_DEPARTMENTS_ERROR",
    DELETE_DEPARTMENT = "DELETE_DEPARTMENT",
    DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS",
    DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR",
    CREATE_DEPARTMENT = "CREATE_DEPARTMENT",
    CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS",
    CREATE_DEPARTMENT_ERROR = "CREATE_DEPARTMENT_ERROR",
    UPDATING_DEPARTMENT = "UPDATING_DEPARTMENTS",
    UPDATING_DEPARTMENT_SUCCESS = "UPDATING_DEPARTMENTS_SUCCESS",
    UPDATING_DEPARTMENT_ERROR = "UPDATING_DEPARTMENTS_ERROR";

export default {
  namespaced: true,
  state: {
    departments: [],
    isLoading: false,
    errors: null
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasDepartments(state) {
      return state.departments.length > 0;
    },
    departments(state) {
      return state.departments;
    },
    hasErrors(state) {
      return state.errors !== null;
    },
    errors(state) {
      return state.errors;
    },
  },
  mutations: {
    [UPDATING_DEPARTMENT](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [UPDATING_DEPARTMENT_SUCCESS](state) {
      state.isLoading = false;
      state.errors = null;
    },
    [UPDATING_DEPARTMENT_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
    },
    [FETCHING_DEPARTMENTS](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [FETCHING_DEPARTMENTS_SUCCESS](state, departments) {
      state.isLoading = false;
      state.errors = null;
      state.departments = departments;
    },
    [FETCHING_DEPARTMENTS_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
      state.departments = [];
    },
    [DELETE_DEPARTMENT](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [DELETE_DEPARTMENT_SUCCESS](state) {
      state.isLoading = false;
      state.errors = null;
    },
    [DELETE_DEPARTMENT_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
    },
    [CREATE_DEPARTMENT](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [CREATE_DEPARTMENT_SUCCESS](state, newDepartment) {
      state.isLoading = false;
      state.departments.push(newDepartment);
      state.errors = null;
    },
    [CREATE_DEPARTMENT_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
    },
  },
  actions: {
    async getAll({ commit }) {
        commit(FETCHING_DEPARTMENTS);
        try {
          let response = await DepartmentAPI.getAll();
          commit(FETCHING_DEPARTMENTS_SUCCESS, response.data);
          return response.data;
        } catch (error) {
          commit(FETCHING_DEPARTMENTS_ERROR, error);
          return null;
        }
    },
    async create({ commit }) {
        commit(CREATE_DEPARTMENT);
        try {
          let response = await DepartmentAPI.create();
          commit(CREATE_DEPARTMENT_SUCCESS, response.data);
          return response.data;
        } catch (error) {
          commit(CREATE_DEPARTMENT_ERROR, error);
          return null;
        }
    },
    async update({ commit }, payload) {
        commit(UPDATING_DEPARTMENT);
        try {
          let id = payload.id
          delete payload.id;
          let response = await DepartmentAPI.update(id, payload);
          commit(UPDATING_DEPARTMENT_SUCCESS, response.data);
          return response.data;
        } catch (error) {
          commit(UPDATING_DEPARTMENT_ERROR, error);
          return null;
        }
    },
    async delete({ commit }, id) {
        commit(DELETE_DEPARTMENT);
        try {
          let response = await DepartmentAPI.delete(id);
          commit(DELETE_DEPARTMENT_SUCCESS, response.data);
          return response.data;
        } catch (error) {
          commit(DELETE_DEPARTMENT_ERROR, error);
          return null;
        }
    },
  }
}