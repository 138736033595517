/**
 * List of available permissions to help with IDE autocomplete
 */
const permissions = {
    EDIT: "EDIT",
    EDIT_USER: "EDIT_USER",
    EDIT_LICENCE: "EDIT_LICENCE",
    EDIT_LICENCE_STATUSES: "EDIT_LICENCE_STATUSES",
    SEE_ADMIN_SECTIONS: "SEE_ADMIN_SECTIONS",
    SEE_ADMIN_TEAMS_SECTION: "SEE_ADMIN_TEAMS_SECTION",
    SEE_ADMIN_USERS_SECTION: "SEE_ADMIN_USERS_SECTION",
    SEE_DASHBOARD: "SEE_DASHBOARD",
    SEE_TASKS: "SEE_TASKS",
    SEE_RENEWALS: "SEE_RENEWALS",
    SEE_TRANSACTIONS: "SEE_TRANSACTIONS",
    SEE_ACTIVITIES: "SEE_ACTIVITIES",
    SEE_PROCESSES: "SEE_PROCESSES",
};

export default permissions;