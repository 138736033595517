import client from "./client";

export default {
  update(data) {
    return client.post("/api/company/update", data);
  },
  get() {
    return client.get("/v2/companies/me");
  },

  enableSandbox() {
    return client.post('/v2/companies/sandbox/enable', {});
  },

  disableSandbox() {
    return client.post('/api/company/sandbox/disable');
  },

  delete() {
    return client.delete('/api/company/schedule-deletion');
  },

  create(data) {
    return client.post('/api/company', data);
  },
};