import client from "./client";

export default {
    get() {
        return client.get("/api/subscription/get");
    },
    getLastInvoice() {
        return client.get("/api/subscription/getLastInvoice");
    },
    getPortalUrl() {
        return client.get("/api/subscription/getPortalUrl");
    },
    getPlansUrl() {
        return client.get("/api/subscription/getPlansUrl");
    },
    sendSubscriptionRequest() {
        return client.get("/api/subscription/sendSubscriptionRequest");
    },
    getSubscriptionEstimate(data) {
        return client.post("/api/subscription/getSubscriptionEstimate", {
            params: data
        });
    },
    updateSubscriptionInitiator() {
        return client.patch("/v2/subscription/initiator", {},{
            headers: {
              "Content-Type": "application/merge-patch+json"
            }
          });
    }
};