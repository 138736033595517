import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'

const opts = {
    lang: {
        locales: { fr, en },
        current: 'en',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {

                'gray-90': '#F9FAFB',
                'gray-80': '#F3F4F6',
                'gray-70': '#E5E7EB',
                'gray-60': '#D1D5DB',
                'gray-50': '#9CA3AF',
                'gray-40': '#6B7280',
                'gray-30': '#4B5563',
                'gray-20': '#374151',
                'gray-10': '#1F2937',
                'gray-0': '#0A0D10',

                'red-90': '#FFF0F1',
                'red-80': '#FEE5E6',
                'red-70': '#FDCACD',
                'red-60': '#FCB0B4',
                'red-50': '#F87171',
                'red-40': '#F72C36',
                'red-30': '#E30914',
                'red-20': '#CC0812',
                'red-10': '#9F060E',
                'red-0': '#72040A',

                'pink-90': '#FDF2F8',
                'pink-80': '#FCE7F3',
                'pink-70': '#FBCFE8',
                'pink-60': '#F9A8D4',
                'pink-50': '#F472B6',
                'pink-40': '#EC4899',
                'pink-30': '#DB2777',
                'pink-20': '#BE185D',
                'pink-10': '#9D174D',
                'pink-0': '#831843',

                'yellow-90': '#FFFBEB',
                'yellow-80': '#FEF3C7',
                'yellow-70': '#FDE68A',
                'yellow-60': '#FCD34D',
                'yellow-50': '#FBBF24',
                'yellow-40': '#F59E0B',
                'yellow-30': '#D97706',
                'yellow-20': '#B45309',
                'yellow-10': '#92400E',
                'yellow-0': '#78350F',

                'green-90': '#ECFDF5',
                'green-80': '#D1FAE5',
                'green-70': '#A7F3D0',
                'green-60': '#6EE7B7',
                'green-50': '#34D399',
                'green-40': '#10B981',
                'green-30': '#059669',
                'green-20': '#047857',
                'green-10': '#065F46',
                'green-0': '#064E3B',

                'blue-90': '#EFF6FF',
                'blue-80': '#DBEAFE',
                'blue-70': '#BFDBFE',
                'blue-60': '#93C5FD',
                'blue-50': '#60A5FA',
                'blue-40': '#3B82F6',
                'blue-30': '#2563EB',
                'blue-20': '#1D4ED8',
                'blue-10': '#1E40AF',
                'blue-0': '#1E3A8A',

                'indigo-90': '#EEF2FF',
                'indigo-80': '#E0E7FF',
                'indigo-70': '#C7D2FE',
                'indigo-60': '#A5B4FC',
                'indigo-50': '#818CF8',
                'indigo-40': '#6366F1',
                'indigo-30': '#4F46E5',
                'indigo-20': '#4338CA',
                'indigo-10': '#3730A3',
                'indigo-0': '#312E81',

                'purple-90': '#F5F3FF',
                'purple-80': '#EDE9FE',
                'purple-70': '#DDD6FE',
                'purple-60': '#C4B5FD',
                'purple-50': '#A78BFA',
                'purple-40': '#8B5CF6',
                'purple-30': '#7C3AED',
                'purple-20': '#6D28D9',
                'purple-10': '#5B21B6',
                'purple-0': '#4C1D95',

                'bg-menu': "#1A222E",

                // Legacy, plz do never add new things and do never use these again
                'grey-0': '#F9FAFB',
                'grey-1': '#F0F2F4',
                'grey-2': '#E2E5E9',
                'grey-3': '#C4CCD4',
                'grey-4': '#A7B3BE',
                'grey-5': '#818C98',
                'grey-6': '#4C5967',
                'grey-7': '#21262C',
                'grey-8': '#0A0D10',
                'red-1': '#A90404',
                'red-2': '#D10505',
                'red-3': '#FA1F1F',
                'red-4': '#FB5050',
                'red-5': '#FC7878',
                'red-6': '#FDA0A0',
                'red-7': '#FEC8C8',
                'red-8': '#FFF0F0',
                'blue-0': '#003366',
                'blue-1': '#004D99',
                'blue-2': '#0073E6',
                'blue-3': '#3399FF',
                'blue-4': '#66B3FF',
                'blue-5': '#99CCFF',
                'blue-6': '#CCE6FF',
                'blue-7': '#E6F2FF',
                'blue-8': '#F2F9FF',
                'green-1': '#6FCF97',
                'success': '#13EC92',
                'success-light': '#DCFCEF',
                'info': '#3399FF',
                'danger-light': '#FFF5F5',
                'danger': '#FC8282',
                'warning': '#FFBF66',
                'primary': '#0A0D10',
                'primary-interaction': '#0A0D10',
                'text-lightgrey': '#E2E5E9',
                'text-white': '#FAFAFA',
                'layout-background': '#F9FAFB',
                'layout-card': '#FFFFFF',
                'layout-grey': '#C4CCD4',
                'layout-lightgrey': '#E2E5E9',
                'heading': '#0A0D10',
                "marketing": "#60367D",
                "sales": "#9D429D",
                "finance": "#C24383",
                "hr": "#FC7373",
                "procurement": "#FFA375",
                "operations": "#FFC370",
                "rd": "#FFDD79",
                "notset": "#000000"
            },
        },
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
        values: {
            menuDown: 'M12.2 15.2333L15.9 10.3C16.1472 9.97038 15.912 9.5 15.5 9.5H8.5C8.08798 9.5 7.85279 9.97038 8.1 10.3L11.8 15.2333C11.9 15.3667 12.1 15.3667 12.2 15.2333Z',
            menuDownStroke: "M6.14645 9.64645C6.34171 9.45118 6.65829 9.45118 6.85355 9.64645L12 14.5L17.1464 9.64645C17.3417 9.45118 17.6583 9.45118 17.8536 9.64645C18.0488 9.84171 18.0488 10.1583 17.8536 10.3536L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.14645 10.3536C5.95118 10.1583 5.95118 9.84171 6.14645 9.64645Z",
            groupBy: "M6.6999 18.6C6.2999 18.6 5.9999 18.5 5.7999 18.2C5.5999 17.9 5.3999 17.6 5.3999 17.3V6.80002C5.3999 6.40002 5.4999 6.10002 5.7999 5.80002C6.0999 5.50002 6.3999 5.40002 6.6999 5.40002H17.1999C17.5999 5.40002 17.8999 5.50002 18.1999 5.80002C18.4999 6.10002 18.5999 6.40002 18.5999 6.80002V17.3C18.5999 17.7 18.4999 18 18.1999 18.2C17.8999 18.5 17.5999 18.6 17.1999 18.6H6.6999ZM6.6999 17.5H11.3999V6.50002H6.6999C6.5999 6.50002 6.5999 6.50002 6.4999 6.60002C6.4999 6.60002 6.3999 6.70002 6.3999 6.80002V17.3C6.3999 17.4 6.3999 17.4 6.4999 17.5C6.5999 17.5 6.5999 17.5 6.6999 17.5ZM12.4999 17.5H17.1999C17.2999 17.5 17.2999 17.5 17.3999 17.4C17.4999 17.3 17.4999 17.3 17.4999 17.2V12H12.4999V17.5ZM12.4999 10.9H17.4999V6.80002C17.4999 6.70002 17.4999 6.70002 17.3999 6.60002C17.2999 6.50002 17.2999 6.50002 17.1999 6.50002H12.4999V10.9Z",
        },
    },
}

Vue.use(Vuetify)

export default new Vuetify(opts)    