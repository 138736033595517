import client from "./client";

export default {
    createUsers(data) {
        return client.post("/api/team/createUsers", {
            data: data
        });
    },
    removeAccess(userId) {
        return client.delete("/api/team/removeAccess/" + userId);
    },
    inviteUsersExtension(data) {
        return client.post("/api/team/invite-extension", data);
    },
    resendInvitation(data) {
        return client.post("/api/team/resend-invitation", data);
      },
};