<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    class="svg-ico v-align-middle"
  >
    <g v-on="click" class="l1-ico" :fill="iconColor" :stroke="strokeColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    click: {
      default: ''
    },
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    strokeColor: {
      type: String,
      default: 'strokeColor'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    }
  }
}
</script>