var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.item.routeName
        ? _c(
            "router-link",
            { attrs: { to: { name: _vm.item.routeName } } },
            [
              _c("menu-item-content", {
                attrs: { item: _vm.item, isCollapse: _vm.isCollapse },
              }),
            ],
            1
          )
        : _vm.item.externalLink
        ? _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.redirectTo(_vm.item.externalLink, true)
                },
              },
            },
            [
              _c("menu-item-content", {
                attrs: { item: _vm.item, isCollapse: _vm.isCollapse },
              }),
            ],
            1
          )
        : _vm.item.action
        ? _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.item.action()
                },
              },
            },
            [
              _c("menu-item-content", {
                attrs: { item: _vm.item, isCollapse: _vm.isCollapse },
              }),
            ],
            1
          )
        : _vm.item.icon && _vm.item.icon == "refer"
        ? _c(
            "div",
            {
              attrs: {
                id: _vm.item.icon == "refer" ? "cello-launcher" : "",
                cello: "",
                "data-cello-badge": "false",
              },
            },
            [
              _c("menu-item-content", {
                attrs: { item: _vm.item, isCollapse: _vm.isCollapse },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }