import Vue from "vue";
import Vuex from "vuex";
import ErrorModule from "./Error";
import SnackModule from "./snackbar";
import PreventRedirectModule from "./preventRedirect";
import UserModule from "./user";
import CompanyModule from "./company";
import AuthorizationModule from "./Authorization";
import DashboardModule from "./dashboard";
import LicenceModule from "./licence";
import ActivityModule from "./activity";
import ActivitiesSettingsModule from "./ActivitiesSettings";
import InsightModule from "./Insight";
import AmortizationsSettingsModule from "./AmortizationsSettings";
import DepartmentModule from "./department";
import TeamModule from "./team";
import SubscriptionModule from "./subscription";
import NotificationModule from "./notification";
import TransactionModule from "./transaction";
import BankModule from "./bank";
import AppModule from "./app";
import SidePanel from "./sidePanel";
import TaskModule from "./task";
import TasksSettingsModule from "./TasksSettings";
import ProcessModule from "./process";
import OffboardingModule from "./offboarding";
import ActivityLog from "./activityLog";
import Accounting from "./Accounting";
import GoogleWorkspace from "./GoogleWorkspace";
import Sellsy from "./Sellsy";
import Quickbooks from "./Quickbooks";
import LicenceStatus from "./licenceStatus";
import LicenceTag from "./licenceTag";
import Spends from "./Spends";
import Spendesk from "./Spendesk";
import MatchingRule from "./matchingRule";
import Xero from "./Xero";
import Currency from "./Currency";
import Chat from "@app/store/modules/chat/item";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        topNav: {},
        menuOpened: false,
        zindexProtector: 7, // 8 is the default z-index for a menu so we position 1 index below
    },
    mutations: {
        openedAMenu(state, zindex){
            state.zindexProtector = zindex
            state.menuOpened = true
        },
        closedAMenu(state){
            state.menuOpened = false
        },
        changeTopNav(state, value){
            state.topNav = value
        }
    },
    getters: {
        getZindexProtector(state){
            return state.zindexProtector;
        },
        isAMenuOpened: state => {
            return state.menuOpened
        },
        topNav: state => {
            return state.topNav
        },

        hasBankDataSource: (state, getters) => {
            return (state.company.company.bridgeAccess && getters['bank/getItems'].length > 0)
                || getters['bank/getItems'].length > 0
            ;
        },

        hasAccountingDataSource: (state, getters) => {
            return getters['accounting/connections'].length > 0
                || getters['sellsy/access']
                || getters['quickbooks/access']
                || getters['xero/access'] !== null
            ;
        },

        hasProcessorDataSource: (state, getters) => {
            return getters['spendesk/connections'].length > 0;
        },

        hasRealDataSource: (state, getters) => {
            return getters.hasBankDataSource
                || state.company.company.isGsuiteEnabled
                || getters.hasAccountingDataSource
                || getters.hasProcessorDataSource
            ;
        },

        hasGoogleWorkspaceConnected: (state, getters) => {
            return state.company.company.isGsuiteEnabled;
        },

        hasDataSource(state, getters) {
            return state.company.company.sandbox || getters.hasRealDataSource;
        },

        hasSyncIssue(state, getters) {
            return getters['bank/hasSyncIssue']
                || getters['sellsy/hasSyncIssue']
                || getters['spendesk/hasSyncIssue']
                || getters['quickbooks/hasSyncIssue']
                || getters['xero/hasSyncIssue']
            ;
        },

        hasOneFunctionalSync(state, getters) {
            return getters['bank/hasOneFunctionalSync']
                || (getters['sellsy/access'] !== null && !getters['sellsy/hasSyncIssue'])
                || getters['spendesk/hasOneFunctionalSync']
                || (getters['quickbooks/access'] !== null && !getters['quickbooks/hasSyncIssue'])
                || (getters['xero/access'] !== null && !getters['xero/hasSyncIssue'])
                || getters['accounting/hasOneFunctionalSync']
            ;
        },

    },
    modules: {
        app: AppModule,
        error: ErrorModule,
        snackbar: SnackModule,
        preventRedirect: PreventRedirectModule,
        user: UserModule,
        company: CompanyModule,
        authorization: AuthorizationModule,
        dashboard: DashboardModule,
        licence: LicenceModule,
        transaction: TransactionModule,
        bank: BankModule,
        notification: NotificationModule,
        department: DepartmentModule,
        team: TeamModule,
        activity: ActivityModule,
        activitiesSettings: ActivitiesSettingsModule,
        insight: InsightModule,
        amortizationsSettings: AmortizationsSettingsModule,
        subscription: SubscriptionModule,
        task: TaskModule,
        tasksSettings: TasksSettingsModule,
        process: ProcessModule,
        offboarding: OffboardingModule,
        sidePanel: SidePanel,
        accounting: Accounting,
        'g.workspace': GoogleWorkspace,
        sellsy: Sellsy,
        quickbooks: Quickbooks,
        activityLog: ActivityLog,
        licenceStatus: LicenceStatus,
        licenceTag: LicenceTag,
        spends: Spends,
        spendesk: Spendesk,
        matchingRule: MatchingRule,
        xero: Xero,
        currency: Currency,
        chat: Chat,
    }
});