const SHOW_SNACK = "SHOW_SNACK",
RESET_SNACK = "RESET_SNACK",
DEFAULT_TIMEOUT = 6000;

export default {
    namespaced: true,
    state: {
        message: '',
        timeout: -1,
        redirectLink: '',
        redirectMsg: '',
        show: false
    },
    getters: {
        getMessage(state){
            return state.message
        },
        getTimeout(state){
            return state.timeout
        },
        getRedirectLink(state){
            return state.redirectLink
        },
        getRedirectMsg(state){
            return state.redirectMsg
        },
        isShown(state){
            return state.show
        }
    },
    mutations: {
        [SHOW_SNACK](state, params) {
            state.message = params.message;
            state.timeout = params.timeout ?? DEFAULT_TIMEOUT;
            state.redirectLink = params.redirectLink;
            state.redirectMsg = params.redirectMsg;
            state.show = true;
        },
        [RESET_SNACK](state) {
            state.message = '';
            state.timeout = -1;
            state.redirectLink = '';
            state.redirectMsg = '';
            state.show = false;
        }
    },
    actions: {
        reset({commit}){
            commit(RESET_SNACK);
        },
        setSnack ({commit}, params) {
            commit(SHOW_SNACK, params);
        },
    }
}
