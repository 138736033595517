var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAuthenticated
    ? _c(
        "v-app",
        [
          _vm.isAMenuOpened
            ? _c("div", {
                staticClass: "protector",
                style: _vm.protectorStyle,
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.company.sandbox
            ? _c(
                "v-system-bar",
                {
                  staticClass: "gray-0--text",
                  attrs: {
                    app: "",
                    window: "",
                    color: "blue-80",
                    id: "system-bar",
                    height: "40",
                  },
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("i18n", {
                    attrs: { path: "sandbox.system-callout", tag: "div" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "really",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "text-lowercase" }, [
                                _vm._v(_vm._s(_vm.$t("commons.really"))),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "clickHere",
                          fn: function () {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "font-weight-bold text-lowercase underline",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.continueOnboarding.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("commons.buttons.click-here"))
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3233422177
                    ),
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("sandbox-to-real-data-modal", {
                    attrs: { step: this.step },
                    on: { mounted: _vm.setOpenChooseSandboxOrRealDataModal },
                    model: {
                      value: _vm.openSandboxPopup,
                      callback: function ($$v) {
                        _vm.openSandboxPopup = $$v
                      },
                      expression: "openSandboxPopup",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("trial-system-topbar"),
          _vm._v(" "),
          _c("main-menu"),
          _vm._v(" "),
          _c("topbar"),
          _vm._v(" "),
          _c(
            "v-main",
            [
              _c(
                "v-container",
                {
                  staticClass: "h-100 d-block align-start ma-0 py-0 px-0 mb-0",
                  staticStyle: { position: "relative" },
                  attrs: { fluid: "" },
                },
                [
                  _vm.company &&
                  _vm.company.isProcessing &&
                  ["accountprocessing"].indexOf(_vm.$route.name) < 0
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "mt-10 mb-0 mx-12",
                          attrs: {
                            border: "left",
                            type: "info",
                            icon: "mdi-information-outline",
                            color: "info",
                            text: "",
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("commons.alerts.processing")) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAccessAllowed
                    ? _c("router-view")
                    : _c("manage-account", {
                        attrs: { subscriptionState: _vm.subscriptionState },
                      }),
                  _vm._v(" "),
                  _c("unsaved-change-modal"),
                  _vm._v(" "),
                  _c("snackbar"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }