<template>
    <div :class="'large-checkbox relative pa-4 w-100 cpointer' + ((isActive)? ' large-checkbox--active' :'')" @click="$emit('updateChoice')">
        <div class="large-checkbox__info d-flex flex-column justify-space-between h-100">
            <slot name="content"/>
        </div>
        <span class="large-checkbox__radio absolute">
            <span :class="'large-checkbox__radio--checked' + ((isActive)? '':' d-none')"/>
        </span>
    </div>
</template>
<script>
    
    export default {
        name: "large-checkbox",
        model: {
            event: 'change'
        },
        props: {

            isActive:{
                default:    false,
                type:       Boolean,
            },
            id: {
                type:       String,
                required:   true,
            },

        },
    }
</script>
<style lang="scss" scoped>
    .large-checkbox {
        height: 105px;
        background: white;
        border-radius: 4px;
        border: 1px solid var(--v-gray-70-base);
        &--active {
            border: 1px solid var(--v-gray-10-base);
        }
        &__radio {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            background: var(--v-gray-70-base);
            border-radius: 50%;
            bottom: 16px;
            right: 16px;
            &--checked {
                display: block;
                width: 10px;
                height: 10px;
                background: var(--v-gray-10-base);
                border-radius: 50%;
            }
        }
    }
</style>