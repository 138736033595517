import UserAPI from "../api/user";
import AuthManager from "../services/AuthManager";
import permissions from "../services/Security/Permissions";
import Security from "../services/Security/Security";
import {mutations as errorMutations} from "./Error"

const AUTHENTICATING = "AUTHENTICATING",
  AUTHENTICATING_SUCCESS = "AUTHENTICATING_SUCCESS",
  AUTHENTICATING_ERROR = "AUTHENTICATING_ERROR",

  FETCHING = "FETCHING",
  FETCHING_SUCCESS = "FETCHING_SUCCESS",
  FETCHING_ERROR = "FETCHING_ERROR",
  
  AUTHENTICATING_CELLO = "AUTHENTICATING_CELLO",
  AUTHENTICATING_CELLO_SUCCESS = "AUTHENTICATING_CELLO_SUCCESS",
  AUTHENTICATING_CELLO_ERROR = "AUTHENTICATING_CELLO_ERROR",

  FETCHING_LIST = "FETCHING_LIST",
  FETCHING_LIST_SUCCESS = "FETCHING_LIST_SUCCESS",
  FETCHING_LIST_ERROR = "FETCHING_LIST_ERROR",

  UPDATING_BATCH_SUCCESS= "UPDATING_BATCH_SUCCESS",

  LOGGING_OUT = "LOGGING_OUT",
  LOGGED_OUT = "LOGGED_OUT",
  LOGGING_OUT_ERROR = "LOGGING_OUT_ERROR",

  REGISTERING = "REGISTERING",
  REGISTERING_SUCCESS = "REGISTERING_SUCCESS",
  REGISTERING_ERROR = "REGISTERING_ERROR",
  PROVIDING_DATA_ON_REFRESH_SUCCESS = "PROVIDING_DATA_ON_REFRESH_SUCCESS",
  UPDATING_CONNECTED = "UPDATING_CONNECTED",
  UPDATING_CONNECTED_SUCCESS = "UPDATING_CONNECTED_SUCCESS",
  UPDATING_CONNECTED_ERROR = "UPDATING_CONNECTED_ERROR",
  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",
  RESETTING_PASSWORD = "RESETTING_PASSWORD",
  RESETTING_PASSWORD_SUCCESS = "RESETTING_PASSWORD_SUCCESS",
  RESETTING_PASSWORD_ERROR = "RESETTING_PASSWORD_ERROR",
  FETCHING_USER = "FETCHING_USER",
  FETCHING_USER_SUCCESS = "FETCHING_USER_SUCCESS",
  FETCHING_USER_ERROR = "FETCHING_USER_ERROR",

  FETCHING_MANAGERS         = "FETCHING_MANAGERS",
  FETCHING_MANAGERS_SUCCESS = "FETCHING_MANAGERS_SUCCESS",
  FETCHING_MANAGERS_ERROR   = "FETCHING_MANAGERS_ERROR",

  DEFAULT = "DEFAULT",
  DEFAULT_SUCCESS = "DEFAULT_SUCCESS",
  DEFAULT_ERROR = "DEFAULT_ERROR";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      resettingError: null,
      registerError: null,
      loginError: null,
      updateError: null,
      errors: null,
      isAuthenticated: false,
      user: null,
      oneUser: null,
      managers: [],
      snack: false,
      snackMessage: null,
      users: [],
      celloToken: null
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    readonly(state) {
      return !Security.isGranted(permissions.EDIT_USER);
    },
    hasRegisterError(state) {
      return state.registerError !== null;
    },
    hasLoginError(state) {
      return state.loginError !== null;
    },
    hasUpdateError(state) {
      return state.updateError !== null;
    },
    hasErrors(state) {
      return state.errors !== null;
    },
    resettingError(state) {
      return state.resettingError;
    },
    registerError(state) {
      return state.registerError;
    },
    loginError(state) {
      return state.loginError;
    },
    updateError(state){
      return state.updateError;
    },
    errors(state) {
      return state.errors;
    },
    get(state) {
      return state.user;
    },
    getOneUser(state) {
      return state.oneUser;
    },
    users(state) {
      return state.users;
    },

    getActiveUsers(state) {
      return state.users.filter(user => user.isActive === true);
    },

    getTrackedUsers(state) {
      return state.users.filter(user => user.isTracked === true);
    },

    getManagers(state)
    {
      return state.users.filter(user => Security.isGranted('ROLE_APP_MANAGER', user));
    },

    getInactiveManagers(state, getters)
    {
      return getters.getManagers.filter(manager => !manager.isActive);
    },

    getActiveManagers(state, getters)
    {
      return getters.getManagers.filter(manager => manager.isActive);
    },

    getCelloToken(state)
    {
      return state.celloToken;
    },

    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    numberOfPendingUser: (state) => {
      return state.users.filter(
        user => !user.isAccountValidated
      ).length;
    },
  },
  mutations: {
    [DEFAULT](state) {
      state.isLoading = true;
      state.errors = null;
    },

    [DEFAULT_ERROR](state, error) {
      state.isLoading = false;
      state.errors = error;
    },

    [DEFAULT_SUCCESS](state) {
      state.isLoading = false;
      state.errors = null;
    },

    [FETCHING](state) {
      state.isLoading = true;
    },
    [FETCHING_SUCCESS](state, user) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = user;
    },
    [FETCHING_ERROR](state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = null;
    },

    [AUTHENTICATING](state) {
      state.isLoading = true;
      state.loginError = null;
      state.isAuthenticated = false;
      state.user = null;
    },
    [AUTHENTICATING_SUCCESS](state, user) {
      state.isLoading = false;
      state.loginError = null;
      state.isAuthenticated = true;
      state.user = user;
    },
    [AUTHENTICATING_ERROR](state, errors) {
      state.isLoading = false;
      state.loginError = errors;
      state.isAuthenticated = false;
      state.user = null;
    },
    [AUTHENTICATING_CELLO](state) {
      state.isLoading = true;
      state.errors = null;
      state.celloToken = null;
    },
    [AUTHENTICATING_CELLO_SUCCESS](state, celloToken) {
      state.isLoading = false;
      state.errors = null;
      state.celloToken = celloToken;
    },
    [AUTHENTICATING_CELLO_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
      state.celloToken = null;
    },

    [LOGGING_OUT](state) {
      state.isLoading = true;
    },

    [LOGGING_OUT_ERROR](state, error) {
      state.isLoading = false;
      state.errors = error;
    },

    [LOGGED_OUT](state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = null;
    },

    [REGISTERING](state) {
      state.isLoading = true;
      state.registerError = null;
      state.isAuthenticated = false;
      state.user = null;
    },
    [REGISTERING_SUCCESS](state, user) {
      state.isLoading = false;
      state.registerError = null;
      state.isAuthenticated = false;
      state.user = user;
    },
    [REGISTERING_ERROR](state, errors) {
      state.isLoading = false;
      state.registerError = errors;
      state.isAuthenticated = false;
      state.user = null;
    },
    [PROVIDING_DATA_ON_REFRESH_SUCCESS](state, payload) {
      state.isLoading = false;
      state.errors = null;
      state.isAuthenticated = payload.isAuthenticated;
      state.user = payload.user;
    },
    [UPDATING_CONNECTED](state) {
      state.isLoading = true;
      state.updateError = null;
    },
    [UPDATING_CONNECTED_SUCCESS](state, user) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.updateError = null;
      state.user = user;
    },
    [UPDATING_CONNECTED_ERROR](state, errors) {
      state.isLoading = false;
      state.updateError = errors;
    },
    [UPDATING](state) {
      state.isLoading = true;
      state.updateError = null;
    },
    [UPDATING_SUCCESS](state, user) {
      state.isLoading = false;
      state.updateError = null;
      if(user){
        state.oneUser = user;
      }
    },
    [UPDATING_ERROR](state, errors) {
      state.isLoading = false;
      state.updateError = errors;
    },
    [RESETTING_PASSWORD](state) {
      state.isLoading = true;
      state.isAuthenticated = false;
      state.resettingError = null;
      state.user = null;
    },
    [RESETTING_PASSWORD_SUCCESS](state) {
      state.isLoading = false;
    },
    [RESETTING_PASSWORD_ERROR](state, errors) {
      state.isLoading = false;
      state.resettingError = errors;
    },
    [FETCHING_USER](state) {
      state.isLoading = true;
      state.errors = null;
      state.oneUser = null;
    },
    [FETCHING_USER_SUCCESS](state, user) {
      state.isLoading = false;
      state.oneUser = user;
    },
    [FETCHING_USER_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
      state.oneUser = null;
    },
    [FETCHING_LIST](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [UPDATING_BATCH_SUCCESS](state, users) {
      state.isLoading = false;
      state.errors = null;
      state.users = users;
    },
    [FETCHING_LIST_SUCCESS](state, users) {
      state.isLoading = false;
      state.errors = null;
      state.users = users;
    },
    [FETCHING_LIST_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
      state.users = [];
    },
    [FETCHING_MANAGERS](state) {
      state.isLoading = true;
      state.errors    = null;
      state.managers  = [];
    },

    [FETCHING_MANAGERS_SUCCESS](state, activeUsers)
    {
      state.isLoading = false;
      state.managers  = activeUsers;
    },

    [FETCHING_MANAGERS_ERROR](state, errors) {
      state.isLoading   = false;
      state.errors      = errors;
      state.managers    = [];
    },
  },
  actions: {

    async get({commit}) {
      commit(FETCHING);
      try {
        let response = await UserAPI.get();
        commit(FETCHING_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_ERROR, error);
        return null;
      }
    },

    async getOne({ commit }, payload) {
      commit(FETCHING_USER);
      try {
        let response = await UserAPI.getOne(payload.id);
        commit(FETCHING_USER_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_USER_ERROR, error);
        commit(errorMutations.SET_ERROR, error, { root: true })
        return null;
      }
    },
    async list({ commit }, payload) {
      commit(FETCHING_LIST);
      try {
        let response = await UserAPI.list(payload);
        commit(FETCHING_LIST_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_LIST_ERROR, error);
        return null;
      }
    },
    async confirmAccount({ commit }, payload) {
      commit(AUTHENTICATING);
      try {
        let response = await UserAPI.confirmAccount(payload);
        commit(AUTHENTICATING_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(AUTHENTICATING_ERROR, error);
        return null;
      }
    },
    async updateCurrentPassword({commit}, payload) {
      commit(UPDATING);
      try {
          let response = await UserAPI.updateCurrentPassword(payload);
          commit(UPDATING_SUCCESS, response.data);
          return response.data;
      } catch (errors) {
          commit(UPDATING_ERROR, errors);
          return null;
      }
    },
    async resetPassword({commit}, payload) {
      commit(RESETTING_PASSWORD);
      try {
          let response = await UserAPI.resetPassword(payload);
          commit(RESETTING_PASSWORD_SUCCESS, response.data);
          return response.data;
      } catch (errors) {
          commit(RESETTING_PASSWORD_ERROR, errors);
          return null;
      }
    },
    async forgotPassword({commit}, email) {
        commit(RESETTING_PASSWORD);
        try {
            const response = await UserAPI.forgotPassword(email);
            commit(RESETTING_PASSWORD_SUCCESS, response.data);
            return response.data;
        } catch (errors) {
            commit(RESETTING_PASSWORD_ERROR, errors);
            return null;
        }
    },
    async register({commit}, payload) {
        commit(REGISTERING);
        try {
            let response = await UserAPI.register(payload);
            commit(REGISTERING_SUCCESS, response.data);
            return response.data;
        } catch (errors) {
            commit(REGISTERING_ERROR, errors);
            return null;
        }
    },
    async resendConfirmation({commit}, payload) {
      commit(DEFAULT);
      try {
          let response = await UserAPI.resendConfirmation(payload);
          commit(DEFAULT_SUCCESS, response.data);
          return response.data;
      } catch (errors) {
          commit(DEFAULT_ERROR, errors);
          return null;
      }
    },
    async login({commit}, payload) {
        commit(AUTHENTICATING);
        try {
            let response = await UserAPI.login(payload.login, payload.password);
            commit(AUTHENTICATING_SUCCESS, response.data);
            return response.data;
        } catch (errors) {
            commit(AUTHENTICATING_ERROR, errors);
            return null;
        }
    },

    async logout({commit}) {

      commit(LOGGING_OUT);

      let response = null;
      try{
        response = await UserAPI.logout(AuthManager.refreshToken);
        commit(LOGGED_OUT);
      }catch(error){
        commit(LOGGING_OUT_ERROR, error);
      }

      AuthManager.reset();
      return response;
    },

    onRefresh({commit}, payload) {
      commit(PROVIDING_DATA_ON_REFRESH_SUCCESS, payload);
    },
    async updateMe({commit}, payload) {
        commit(UPDATING_CONNECTED);
        try {
            let response = await UserAPI.updateMe(payload);
            commit(UPDATING_CONNECTED_SUCCESS, response.data);
            return response.data;
        } catch (errors) {
            commit(UPDATING_CONNECTED_ERROR, errors);
            return null;
        }
    },
    async update({commit}, payload) {
        commit(UPDATING);
        try {
            let id = payload.id
            delete payload.id;
            let response = await UserAPI.update(id, payload);
            commit(UPDATING_SUCCESS, response.data);
            return response.data;
        } catch (errors) {
            commit(UPDATING_ERROR, errors);
            return null;
        }
    },

    async batch({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await UserAPI.batch(payload);
        commit(UPDATING_BATCH_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },

    async patch({commit}, payload) {
        commit(UPDATING);
        try {
            const response = await UserAPI.patch(payload.id, payload);
            commit(UPDATING_SUCCESS, response.data);
            return response.data;
        } catch (errors) {
            commit(UPDATING_ERROR, errors);
            return null;
        }
    },

    async create({commit}, payload) {
        try{
            const response = await UserAPI.create(payload);
            return response.data;
        }catch(e){
            return null;
        }
    },

    async authenticateCello({ commit })
    {
      commit(AUTHENTICATING_CELLO);
      try {

        let response = await UserAPI.authenticateCello();
        commit(AUTHENTICATING_CELLO_SUCCESS, response.data);
        return response.data;
      } catch (error) {

        commit(AUTHENTICATING_CELLO_ERROR, error);
        return null;
      }
    },

    async inviteToUseBrowserExtension({ commit }, payload)
    {
      try {
        const response = await UserAPI.inviteToUseBrowserExtension(payload);
        return response;
      }catch(e) {
        return null;
      }
    }
  }
}