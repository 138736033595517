import ls from 'localstorage-slim';

// 3600 seconds = 1 hour
const ttl = 3600;
const storageKey = "switch_user";

const impersonateMode = new class ImpersonateMode {

    constructor(){
        this.storage = ls;
    }

    isActive(){
        return this.storage.get(storageKey);
    }

    start(userEmail){
        this.storage.set(storageKey, userEmail, {
            ttl: ttl,
        });

        window.location = "/";
    }

    stop(){
        this.storage.remove(storageKey);
        window.location = "/";
    }

    getImpersonatedUserIdentifier(){
        return this.storage.get(storageKey);
    }
    
};

export default impersonateMode;