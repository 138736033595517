import Security from "../services/Security/Security";
import permissions from "../services/Security/Permissions";

import employeeSrc from '@app/images/employee.svg';
import appManagerSrc from '@app/images/app-manager.svg';
import teamManagerSrc from '@app/images/team-manager.svg';
import monitorSrc from '@app/images/monitor.svg';
import adminSrc from '@app/images/administrator.svg';

export default {

    data: function() {
        return {
            permissions: permissions,
            roles: [
                {
                    "label": this.$i18n.t('users.roles.employee.label'),
                    "value": "ROLE_EMPLOYEE",
                    "icon": employeeSrc,
                    "desc": this.$i18n.t('users.roles.employee.desc'),
                    "longerDesc": this.$i18n.t('users.roles.employee.longer-desc'),
                    "cant": this.$i18n.t('users.roles.employee.cant'),
                },
                {
                    "label": this.$i18n.t('users.roles.app-manager.label'),
                    "value": "ROLE_APP_MANAGER",
                    "icon": appManagerSrc,
                    "desc": this.$i18n.t('users.roles.app-manager.desc'),
                    "longerDesc": this.$i18n.t('users.roles.app-manager.longer-desc'),
                    "cant": this.$i18n.t('users.roles.app-manager.cant'),
                },
                {
                    "label": this.$i18n.t('users.roles.team-manager.label'),
                    "value": "ROLE_TEAM_MANAGER",
                    "icon": teamManagerSrc,
                    "desc": this.$i18n.t('users.roles.team-manager.desc'),
                    "longerDesc": this.$i18n.t('users.roles.team-manager.longer-desc'),
                    "cant": this.$i18n.t('users.roles.team-manager.cant'),
                },
                {
                    "label": this.$i18n.t('users.roles.viewer.label'),
                    "value": "ROLE_MONITOR",
                    "icon": monitorSrc,
                    "desc": this.$i18n.t('users.roles.viewer.desc'),
                    "longerDesc": this.$i18n.t('users.roles.viewer.longer-desc'),
                    "can": this.$i18n.t('users.roles.viewer.can'),
                },
                {
                    "label": this.$i18n.t('users.roles.admin.label'),
                    "value": "ROLE_ADMIN",
                    "icon": adminSrc,
                    "desc": this.$i18n.t('users.roles.admin.desc'),
                    "longerDesc": this.$i18n.t('users.roles.admin.longer-desc'),
                    "can": this.$i18n.t('users.roles.admin.can'),
                },
                {
                    "label": this.$i18n.t('users.roles.owner.label'),
                    "value": "ROLE_OWNER",
                    "disabled": true,
                    "desc": this.$i18n.t('users.roles.owner.desc'),
                    "longerDesc": this.$i18n.t('users.roles.owner.longer-desc')
                },
            ],
        };
    },

    methods: {

        /**
         * @param {string} permission
         * @param {Object=} resource
         * @returns {Boolean}
         */
        isGranted(permission, resource){
            return Security.isGranted(permission, resource);
        },

        redirectHome(){
            if(this.isGranted(permissions.SEE_DASHBOARD)){
                this.$router.push({name: 'dashboard'});
            }else{
                this.$router.push({name: 'licences'});
            }        
        },

        getRoleLabel(role){
            return this.roles.find(roleDesc => roleDesc.value === role).label;
        },

        getHighestRole(user){
            return Security.getHighestRole(user);
        },

        isUserHaveAccount(user){
            return user.isActive && user.isTracked && !this.isGranted('ROLE_RESTRICTED', user)
        }

    },

    computed: {

        /**
         * @returns {Array} All roles with a property disabled (boolean)
         */
        restrictedRoles(){

            const roles = [];
            this.roles.forEach((role) => {
                if(role.disabled === undefined){
                    role.disabled = !this.isGranted(role.value, this.user);
                }

                roles.push(role);
            });

            return roles;
        },

        fetchAllRolesByUserRole() {
            const roles = [];
            this.roles.forEach((role) => {
                role.disabled = !this.isGranted(role.value, this.user);

                roles.push(role);
            });

            return roles;
        },

        /**
         * @returns {Array} Only not disabled roles
         */
        filteredRoles(){
            return this.restrictedRoles.filter(
                role => !role.disabled
            );
        },

    }

};