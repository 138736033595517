import { AxiosError } from "axios";
import GoogleAPI from "../api/google";

const 
  ERRORS = "ERRORS";

export default {

    namespaced: true,

    state: {
        errors: null,
    },

    getters: {

        errors(state) {
            return state.errors;
        },

    },

    mutations: {

        /**
         * @param {AxiosError} errors
         */
        [ERRORS](state, errors)
        {
            state.errors = errors.response.data;
        },

    },

    actions: {

        async connect({commit}, payload)
        {
            try {
                await GoogleAPI.connectWorkspace(payload);
            } catch (error) {
                commit(ERRORS, error);
                return null;
            }
        },

    },

}