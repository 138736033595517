import client from "./client";

export default {

    lastUsages(query) {
      return client.get('/v2/activities/last-usages', {
        params: query,
      });
    },

    getChartActivities(id) {
      return client.get("/api/activity/chart-activities/" + id);
    },
    getLastBrowserUsages() {
      return client.get("/api/activity/last-browser-usages");
    }
};