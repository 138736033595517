import { AxiosError } from "axios";
import InsightApi from "../api/Insight";
import Vue from "vue";

const 
  FETCHED = "FETCHED",
  CREATED = "CREATED",
  LOADING = "LOADING",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        loading: false,
        insights: [],
        tag: null,
    },

    getters: {

        error(state) {
            return state.error;
        },

        loading(state) {
            return state.loading;
        },

        insights(state) {
            return state.insights;
        },

    },

    mutations: {

        /**
         * @param {Array} insights
         */
        [FETCHED](state, insights)
        {
          state.insights = insights;
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.insights = null;
            state.error = error;
        },

    },

    actions: {

      async getAll({ commit }) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {
          const response = await InsightApi.getAll();
          commit(LOADING, false);
          commit(FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },
    },
}