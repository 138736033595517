import TaskAPI from "../api/task";
import LicenceAPI from "../api/licence";
import remove from "lodash/remove";

const GETTING_TASKS = "GETTING_TASKS",
  GETTING_TASKS_SUCCESS = "GETTING_TASKS_SUCCESS",
  GETTING_TASKS_ERROR = "GETTING_TASKS_ERROR",
  GETTING_TASK = "GETTING_TASK",
  GETTING_TASK_SUCCESS = "GETTING_TASK_SUCCESS",
  GETTING_TASK_ERROR = "GETTING_TASK_ERROR",
  GETTING_TASKS_BY = "GETTING_TASKS_BY",
  GETTING_TASKS_BY_SUCCESS = "GETTING_TASKS_BY_SUCCESS",
  GETTING_TASKS_BY_ERROR = "GETTING_TASKS_BY_ERROR",
  UPDATING_TASK = "UPDATING_TASK",
  UPDATING_TASK_SUCCESS = "UPDATING_TASK_SUCCESS",
  UPDATING_TASK_ERROR = "UPDATING_TASK_ERROR",
  UPDATING_TASK_LICENCE = "UPDATING_TASK_LICENCE",
  UPDATING_TASK_LICENCE_SUCCESS = "UPDATING_TASK_LICENCE_SUCCESS",
  UPDATING_TASK_LICENCE_ERROR = "UPDATING_TASK_LICENCE_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    tasks: [],
    task: {},
    sidePanelTask: {}
  },
  getters: {
    getSidePanelTask(state){
      return state.sidePanelTask;
    },
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    getAll(state) {
      return state.tasks;
    },
    getCurrentTask(state){
      return state.task;
    },

    /**
     * @returns {Array}
     */
    getCurrentUserTasks(state, getters, store)
    {
      if(!store.user || !store.user.user){
        return [];
      }

      return state.tasks.filter((task) => {
        return task.assignee ? task.assignee.id === store.user.user.id : false;
      });
    },

    /**
     * @returns {Array}
     */
    getAssignedToOthersTasks(state, getters, store)
    {
      return state.tasks.filter((task) => {
        return task.assignee ? task.assignee.id !== store.user.user.id : false;
      });
    },

    /**
     * @returns {Array}
     */
    getUnassignedTasks(state)
    {
      return state.tasks.filter((task) => {
        return !task.assignee;
      });
    },

  },
  mutations: {
    [UPDATING_TASK_LICENCE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_TASK_LICENCE_SUCCESS](state, licence) {
      state.isLoading = false;
      state.error = null;
      state.task.licence = licence;
    },
    [UPDATING_TASK_LICENCE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [GETTING_TASK](state) {
      state.isLoading = true;
      state.error = null;
      state.task = [];
    },
    [GETTING_TASK_SUCCESS](state, task) {
      state.isLoading = false;
      state.error = null;
      state.task = task;
    },
    [GETTING_TASK_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.task = [];
    },
    [GETTING_TASKS](state) {
      state.isLoading = true;
      state.error = null;
      state.tasks = [];
    },
    [GETTING_TASKS_SUCCESS](state, tasks) {
      state.isLoading = false;
      state.error = null;
      state.tasks = tasks;
    },
    [GETTING_TASKS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.tasks = [];
    },
    [GETTING_TASKS_BY](state) {
      state.isLoading = true;
      state.error = null;
    },
    [GETTING_TASKS_BY_SUCCESS](state, tasks) {
      state.isLoading = false;
      state.error = null;
      state.tasks = tasks;
    },
    [GETTING_TASKS_BY_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.tasks = [];
    },
    [UPDATING_TASK](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_TASK_SUCCESS](state, task) {
      state.isLoading = false;
      state.error = null;
      state.task = task;

      remove(state.tasks, (stateTask) => {
        return stateTask.id === task.id;
      });

      state.tasks.push(task);
    },
    [UPDATING_TASK_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.task = '';
    },
  },
  actions: {
    async getOne({ commit }, payload) {
      commit(GETTING_TASK);
      try {
        let response = await TaskAPI.getOne(payload.id);
        commit(GETTING_TASK_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(GETTING_TASK_ERROR, error);
        return null;
      }
    },
    async getBy({ commit }, payload) {
      commit(GETTING_TASKS_BY);
      try {
        let response = await TaskAPI.getBy(payload);
        commit(GETTING_TASKS_BY_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(GETTING_TASKS_BY_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING_TASK);
      try {
        let id = payload.id
        delete payload.id;
        let response = await TaskAPI.update(id, payload);
        commit(UPDATING_TASK_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_TASK_ERROR, error);
        return null;
      }
    },
    async updateTaskLicence({ commit }, payload) {
      commit(UPDATING_TASK_LICENCE);
      try {
        let id = payload.id
        delete payload.id;
        let response = await LicenceAPI.update(id, payload);
        commit(UPDATING_TASK_LICENCE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_TASK_LICENCE_ERROR, error);
        return null;
      }
    },
  }
};