import { AxiosError } from "axios";
import BankAPI from "../api/bank";

const DEFAULT = "DEFAULT",
  DEFAULT_SUCCESS = "DEFAULT_SUCCESS",
  DEFAULT_ERROR = "DEFAULT_ERROR",
  DELETING_SUCCESS = "DELETING_SUCCESS",
  CONNECTING_BRIDGE_SUCCESS = "CONNECTING_BRIDGE_SUCCESS",
  CONNECTING_PLAID_SUCCESS = "CONNECTING_PLAID_SUCCESS",
  CONNECTING = "CONNECTING",
  CONNECTING_ERROR = "CONNECTING_ERROR",
  PRO_VALIDATION = "PRO_VALIDATION",
  PRO_VALIDATION_SUCCESS = "PRO_VALIDATION_SUCCESS",
  PRO_VALIDATION_ERROR = "PRO_VALIDATION_ERROR",
  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",
  FETCHING_BANK = "FETCHING_BANK",
  FETCHING_BANK_SUCCESS = "FETCHING_BANK_SUCCESS",
  FETCHING_BANK_ERROR = "FETCHING_BANK_ERROR",
  LISTING_BANKS = "LISTING_BANKS",
  LISTING_BANKS_SUCCESS = "LISTING_BANKS_SUCCESS",
  BRIDGE_VALIDE_STATUS = ['-2', '-3', '0'],
  LISTING_BANKS_ERROR = "LISTING_BANKS_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isBankSearching: false,
    error: null,
    bankUpdateUrl: null,
    validateProUrl: null,
    items: [],
    plaidTokenLink: null,
    institutions: []
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isBankSearching(state) {
      return state.isBankSearching;
    },

    /**
     * Used to verify system error (4xx, 5xx), not bank status
     */
    hasError(state) {
      return state.error instanceof AxiosError;
    },
    error(state) {
      return state.error;
    },
    getBankUpdateUrl(state) {
      return state.bankUpdateUrl;
    },
    getValidateProUrl(state) {
      return state.validateProUrl;
    },
    getItems(state) {
      return state.items;
    },
    getInstitutions(state) {
      return state.institutions;
    },
    hasSyncIssue(state) {

      if(!state.items){
        return false;
      }

      return state.items.filter(item => item.status != 0).length > 0;
    },

    /**
     * Verify if at least one synchronization is functionnal
     */
    hasOneFunctionalSync(state) {

      if(!state.items){
        return false;
      }

      return state.items.filter(item => item.status == 0).length > 0;
    },

  },
  mutations: {
    [DEFAULT](state) {
        state.isLoading = true;
        state.error = null;
    },
    [DEFAULT_SUCCESS](state) {
        state.isLoading = false;
        state.error = null;
    },
    [DEFAULT_ERROR](state, error) {
        state.isLoading = false;
        state.error = error;
    },
    [UPDATING](state) {
        state.isLoading = true;
        state.error = null;
        state.bankUpdateUrl = null;
    },
    [UPDATING_SUCCESS](state, response) {
        state.isLoading = false;
        state.error = null;
        state.bankUpdateUrl = response.bankUpdateUrl;
    },
    [UPDATING_ERROR](state, error) {
        state.isLoading = false;
        state.error = error;
        state.bankUpdateUrl = null;
    },
    [DELETING_SUCCESS](state, id) {
        state.isLoading = false;
        state.items = state.items.filter(function(item) {
          return item.id !== id
        })
        state.error = null;
    },
    [CONNECTING](state) {
        state.isLoading = true;
        state.error = null;
    },
    [CONNECTING_ERROR](state, error) {
        state.isLoading = false;
        state.error = error;
    },
    [CONNECTING_PLAID_SUCCESS](state, response) {
        state.isLoading = false;
        state.error = null;
        state.plaidTokenLink = response.link_token;
    },
    [CONNECTING_BRIDGE_SUCCESS](state, newItem) {
        state.isLoading = false;
        state.error = null;

        if(newItem){
          const items = state.items.filter(item => (item.id !== newItem.id || item.processor !== "bridge"));
          items.push(newItem);
          state.items = items;
        }
    },
    [PRO_VALIDATION](state) {
        state.isLoading = true;
        state.error = null;
        state.validateProUrl = null;
    },
    [PRO_VALIDATION_SUCCESS](state, response) {
        state.isLoading = false;
        state.error = null;
        state.validateProUrl = response.validateProUrl;
    },
    [PRO_VALIDATION_ERROR](state, error) {
        state.isLoading = false;
        state.error = error;
        state.validateProUrl = null;
    },
    [FETCHING_BANK](state) {
        state.isLoading = true;
        state.error = null;
    },
    [FETCHING_BANK_SUCCESS](state, response) {
        state.isLoading = false;
        state.error = null;
        state.items = response.items;
    },
    [FETCHING_BANK_ERROR](state, error) {
        state.isLoading = false;
        state.error = error;
        state.items = [];
    },
    [LISTING_BANKS](state) {
        state.isBankSearching = true;
        state.error = null;
        state.institutions = null;
    },
    [LISTING_BANKS_SUCCESS](state, response) {
        state.isBankSearching = false;
        state.error = null;
        state.institutions = response;
    },
    [LISTING_BANKS_ERROR](state, error) {
        state.isBankSearching = false;
        state.error = error;
        state.institutions = null;
    },
  },
  actions: {
    async institutions({ commit }, payload){
      commit(LISTING_BANKS);
      try {

        if(payload.country === 'EU'){
          payload.country = null;
        }

        let response = await BankAPI.institutions(payload);
        commit(LISTING_BANKS_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(LISTING_BANKS_ERROR, error);
        return null;
      }
    },
    async deleteItem({ commit }, payload) {
      commit(DEFAULT);
      try {
        let response = await BankAPI.deleteItem(payload.itemId);
        commit(DELETING_SUCCESS, payload.internalId);
        return response.data;
      } catch (error) {
        commit(DEFAULT_ERROR, error);
        return null;
      }
    },
    async updateItem({ commit }, itemid) {
      commit(UPDATING);
      try {
        let response = await BankAPI.updateItem(itemid);
        commit(UPDATING_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },

    async connectBridgeItem({ commit }, payload){
      commit(CONNECTING);
      try{
        const response = await BankAPI.connectBridge(payload);
        commit(CONNECTING_BRIDGE_SUCCESS, response.data);
        if(!BRIDGE_VALIDE_STATUS.includes(response.data.status)){
          commit(CONNECTING_ERROR, response.data.status);
        }

        return response.data;
      }catch(error){
        commit(CONNECTING_ERROR, error);
        return null;
      }
    },

    async connectInstitution({ commit }, payload) {
      commit(CONNECTING);
      try {
        let response = await BankAPI.connectInstitution(payload);
        commit(CONNECTING_BRIDGE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(CONNECTING_ERROR, error);
        return null;
      }
    },

    async updateNordigenConnection({ commit }, payload)
    {
      commit(CONNECTING);
      try {
        const response = await BankAPI.updateNordigenConnection(payload);
        commit(CONNECTING_BRIDGE_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(CONNECTING_ERROR, error);
        return null;
      }
    },

    async updateNordigenItem({ commit }, payload) {
      commit(DEFAULT);
      try {
        const response = await BankAPI.updateNordigenItem(payload);
        commit(DEFAULT_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(DEFAULT_ERROR, error);
        return null;
      }
    },

    async updatePlaidItem({ commit }, itemId) {
      commit(CONNECTING);
      try {
        let response = await BankAPI.updatePlaidItem(itemId);
        commit(CONNECTING_PLAID_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(CONNECTING_ERROR, error);
        return null;
      }
    },
    async connectPlaid({ commit }) {
      commit(CONNECTING);
      try {
        let response = await BankAPI.connectPlaid();
        commit(CONNECTING_PLAID_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(CONNECTING_ERROR, error);
        return null;
      }
    },
    async validatePro({ commit }) {
      commit(PRO_VALIDATION);
      try {
        let response = await BankAPI.validatePro();
        commit(PRO_VALIDATION_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(PRO_VALIDATION_ERROR, error);
        return null;
      }
    },
    async getItems({ commit }) {
      commit(FETCHING_BANK);
      try {
        const response = await BankAPI.getItems();
        commit(FETCHING_BANK_SUCCESS, response.data);

        response.data.items.forEach(bankItem => {

          if(bankItem.processor === 'bridge' && !BRIDGE_VALIDE_STATUS.includes(bankItem.status)){
            commit(DEFAULT_ERROR, bankItem.status);
          }
        });

        return response.data;
      } catch (error) {
        commit(FETCHING_BANK_ERROR, error);
        return null;
      }
    },
    async exchangePlaidToken({ commit }, payload) {
      commit(DEFAULT);
      try {
        let response = await BankAPI.exchangePlaidToken(payload);
        commit(DEFAULT_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(DEFAULT_ERROR, error);
        return null;
      }
    },
    async addNordigenItem({ commit }, payload) {
      commit(DEFAULT);
      try {
        let response = await BankAPI.addNordigenItem(payload);
        commit(DEFAULT_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(DEFAULT_ERROR, error);
        return null;
      }
    },
  }
};