import client from "./client";

export default {

  list(filters) {
    return client.get("/v2/transactions", {
      params: filters,
    });
  },

  archiveBatch(data){
    return client.post("/api/transactions/archiveBatch", data);
  },
  reAssign(data) {
    return client.post("/api/transactions/reAssign", data);
  },
  batch(data){
    return client.patch("/v2/batch/transactions", 
      data,
      {
        headers:{
          'Content-Type': "application/merge-patch+json"
        },
      }
  );
  },
};