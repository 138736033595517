var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex justify-space-between mb-4" }, [
    _c("div", {
      staticClass: "text-left f-6",
      domProps: { innerHTML: _vm._s(_vm.label) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { class: "text-right" + (_vm.price != 0 ? "" : "  grey-4--text") },
      [
        _vm.oldPrice
          ? _c("span", { staticClass: "oldPrice" }, [
              _vm._v(
                "\n              " +
                  _vm._s(
                    _vm.formatNumber(_vm.oldPrice, _vm.getSystemCurrency())
                  ) +
                  "\n          "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "price" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.price != 0
                  ? _vm.formatNumber(_vm.price, _vm.getSystemCurrency())
                  : _vm.$t(
                      "billing.account.usage.listing.placeholders.included"
                    )
              ) +
              "\n          "
          ),
        ]),
        _vm._v(" "),
        _vm.descPrice ? _c("span", [_vm._v(_vm._s(_vm.descPrice))]) : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }