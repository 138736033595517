"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Plaid {
    isAvailable() {
        return true;
    }
    initialize() { }
    prepareDomElement(element) {
        element.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
    }
    getInstance() {
        return window.Plaid;
    }
    errorHandler() { }
}
exports.default = Plaid;
