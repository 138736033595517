import { AxiosError } from "axios";
import MatchingRuleAPI from "../api/matchingRule";
import RulesFactory from "../Mixins/RulesFactory";

const 
  LOADING = "LOADING",
  LISTING = "LISTING",
  CREATING = "CREATING",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
        error: null,
        loading: false,
        matchingRules: null
    },

    getters: {

        error(state) {
            return state.error;
        },

        loading(state) {
            return state.loading;
        },

        get(state) {
            return state.matchingRule;
        },

        activeMatchingRule(state) {
            return RulesFactory.methods.getActiveMatchingRules(state.matchingRules);
        },
    },

    mutations: {

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {Object} matchingRule
         */
        [LISTING](state, matchingRules)
        {
            state.matchingRules = matchingRules;
        },

        /**
         * @param {Object} matchingRule
         */
        [CREATING](state, matchingRule)
        {
            state.matchingRule = matchingRule;
            let matchingRules = {...state.matchingRules};
            matchingRules.unshift(matchingRule);
            this.$set(state.matchingRules, state.matchingRules.length, matchingRule);
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

    },

    actions: {

        async list({commit}, payload)
        {
            commit(LOADING, true);

            try {

                const response = await MatchingRuleAPI.list(payload);
                commit(LOADING, false);
                commit(LISTING, response.data);
                commit(ERROR, null);

                return response;

            } catch (error) {

                commit(LOADING, false);
                return null;
            }
        },

        async update({commit}, payload)
        {
            commit(LOADING, true);

            try {

                const response = await MatchingRuleAPI.update(payload);
                commit(LOADING, false);
                commit(CREATING, response.data);
                commit(ERROR, null);

                return response;

            } catch (error) {

                commit(LOADING, false);
                return null;
            }
        },

        async create({commit}, payload)
        {
            commit(LOADING, true);

            try {

                const response = await MatchingRuleAPI.create(payload);
                commit(LOADING, false);
                commit(CREATING, response.data);
                commit(ERROR, null);

                return response;

            } catch (error) {

                commit(LOADING, false);
                return null;
            }
        }

    },

}