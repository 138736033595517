<template>
    <div>
        <router-link 
            v-if="item.routeName"
            :to="{name:item.routeName}" 
        >
            <menu-item-content :item="item" :isCollapse="isCollapse"/>
        </router-link>
        <div
            v-else-if="item.externalLink"
            @click="redirectTo(item.externalLink, true)"
        >
            <menu-item-content :item="item" :isCollapse="isCollapse"/>
        </div>
        <div 
            v-else-if="item.action"
            @click="item.action()" 
        >
            <menu-item-content :item="item" :isCollapse="isCollapse"/>
        </div>
         <div 
            v-else-if="item.icon && item.icon == 'refer'"
            :id="((item.icon == 'refer')?'cello-launcher':'')"
            cello
            data-cello-badge="false"
        >
            <menu-item-content :item="item" :isCollapse="isCollapse"/>
        </div>
    </div>
</template>
<script>
    import mixin from '../../../Mixins/mixin'
    import menuItemContent from './MenuItemContent'

    export default {
        name: "menu-item",
        mixins: [mixin],
        components: {
            menuItemContent
        },
        props: {
            item: {
                type: Object,
                default: {},
            },
            isCollapse: {
                type: Boolean,
                default: false,
            }
        },

    }
    
</script>
<style lang="scss" scoped>
    .menu {
        overflow: hidden;
        scrollbar-color: var(--v-gray-30-base) var(--v-gray-10-base);
        scrollbar-width: thin;
        &__item {
            padding: 4px 8px;
            color: white !important;
            display: flex;
            align-items: center;
            &:hover {
                border-radius: 4px;
                font-weight: 600;
                background: var(--v-gray-30-base);
            }
            &--active {
                border-radius: 4px;
                font-weight: 600;
                background: var(--v-gray-30-base);
            }
        }
    }
</style>