var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isTrial && !_vm.company.sandbox
        ? _c(
            "v-system-bar",
            {
              staticClass: "gray-0--text justify-center",
              attrs: {
                app: "",
                window: "",
                color: "blue-80",
                id: "system-bar",
                height: "40",
              },
            },
            [
              _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("company.trial.system-callout", {
                        days:
                          _vm.numberOfDaysRemaining +
                          " " +
                          _vm.$tc("commons.days", _vm.numberOfDaysRemaining),
                      })
                    ) +
                    "\n            "
                ),
                _vm.isGranted(_vm.permissions.SEE_ADMIN_SECTIONS)
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "ml-1 font-weight-bold text-lowercase underline",
                        on: { click: _vm.trialUpgradeClick },
                      },
                      [_vm._v(_vm._s(_vm.$t("commons.buttons.upgrade-now")))]
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTrial && !_vm.company.sandbox
        ? _c("subscription-modal", {
            attrs: { persistent: false },
            model: {
              value: _vm.openUpgradeModal,
              callback: function ($$v) {
                _vm.openUpgradeModal = $$v
              },
              expression: "openUpgradeModal",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }