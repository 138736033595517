import client from "./client";

const apiUrl = '/v2/users';

export default {

  get() {
    return client.get('/v2/me');
  },

  list(query) {
    return client.get(apiUrl, {
      params: query,
    });
  },

  refreshAccessToken(refreshToken) {
    return client.post("/api/token/refresh",
      {
        refresh_token: refreshToken,
      },
      {
        headers:{
          Authorization: ""
        },
        skipAuthRefresh: true,
      }
    );
  },

  getOne(id) {
    return client.get(apiUrl + '/' + id);
  },
  updateMe(payload) {
    return client.post("/api/user/updateMe", payload);
  },
  update(id, data) {
    return client.post("/api/user/update/" + id, {
      data: data
    });
  },

  batch(data){
    return client.patch("/v2/batch/users", 
          data,
        {
          headers:{
            'Content-Type': "application/merge-patch+json"
          },
        }
    );
  },

  patch(id, payload){
    return client.patch("/v2/users/" + id, payload, {
      headers: {
        "Content-Type": "application/merge-patch+json"
      }
    });
  },

  create(payload){
    return client.post("/v2/users", payload);
  },

  login(login, password) {

    const params = new URLSearchParams();
    params.append('username', login);
    params.append('password', password);

    return client.post("/api/login_check", params);
  },

  logout(refreshToken) {
    return client.get('/api/token/invalidate', {
      params: {
        refresh_token: refreshToken,
      }
    });
  },

  register(data) {
    return client.post("/api/public/registration/register", data);
  },
  resendConfirmation(data) {
    return client.post("/api/registration/resend-confirmation", data);
  },
  forgotPassword(email) {
    return client.post("/api/public/forgot-password", {
      email: email,
    });
  },
  confirmAccount(data) {
    return client.post("/api/security/confirmAccount", data);
  },
  updateCurrentPassword(payload) {
    return client.post("/api/security/updateCurrentPassword", payload);
  },
  resetPassword(data) {
    return client.post("/api/public/reset-password/" + data.token, {
      plainPassword: data.plainPassword,
    });
  },
  authenticateCello() {
    return client.get("/api/user/cello/authenticate");
  },

  inviteToUseBrowserExtension(payload) {
    return client.post(apiUrl + '/invite/browser-extension', payload);
  },

  apiUrl: apiUrl,
};