<template>
  <div id="security-content">
    <router-view></router-view>
  </div>
</template>

<script>

  import UtmManager from "../../services/Analytics/UtmManager";

  export default {
    name: 'layoutsecurity',
    data() {
      return {
      }
    },

    beforeCreate(){
      const utmManager = new UtmManager();
      utmManager.boot();
    }
  } 
</script>

<style lang="scss" scoped>
  #security-content{
    height: 100%;
    #row-content{
      height: 100%;
    }
  }
  #background-onboarding{
    background-image: url('/assets/images/signup.svg'), linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    @media (max-width: 960px) {
        background-image: url('/assets/images/signup-mobile.svg'), linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%);
    }
  }
</style>