var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "M16.0906 7.84382L16.143 7.88569L16.1905 7.83825L17.2445 6.78422L18.2158 7.75551L17.1617 8.80954L17.1143 8.85698L17.1562 8.90939C18.2382 10.2637 18.7605 11.9809 18.6159 13.7083C18.4714 15.4357 17.6709 17.0422 16.3789 18.1979C15.0868 19.3535 13.4013 19.9706 11.6686 19.9224C9.93577 19.8741 8.28722 19.1643 7.06149 17.9385C5.83575 16.7128 5.12587 15.0642 5.07763 13.3314C5.02939 11.5987 5.64646 9.91318 6.80211 8.62115C7.95776 7.32912 9.56425 6.52862 11.2917 6.38406C13.0191 6.23949 14.7363 6.76184 16.0906 7.84382ZM11.857 18.5515C12.5672 18.5515 13.2705 18.4116 13.9267 18.1398C14.5829 17.868 15.1791 17.4696 15.6813 16.9674C16.1835 16.4652 16.5819 15.8689 16.8537 15.2128C17.1255 14.5566 17.2654 13.8533 17.2654 13.143C17.2654 12.4328 17.1255 11.7295 16.8537 11.0733C16.5819 10.4171 16.1835 9.82092 15.6813 9.3187C15.1791 8.81648 14.5829 8.41809 13.9267 8.14629C13.2705 7.8745 12.5672 7.7346 11.857 7.7346C10.4225 7.7346 9.04689 8.30442 8.03261 9.3187C7.01833 10.333 6.44851 11.7086 6.44851 13.143C6.44851 14.5775 7.01833 15.9531 8.03261 16.9674C9.04689 17.9817 10.4225 18.5515 11.857 18.5515ZM12.5439 9.40844V13.83H11.17V9.40844H12.5439ZM8.88427 4.075H14.8296V5.44884H8.88427V4.075Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }