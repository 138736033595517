import ActivitiesSettingsAPI from "../api/ActivitiesSettings";

const 
  FETCHING          = "FETCHING",
  FETCHING_SUCCESS  = "FETCHING_SUCCESS",
  FETCHING_ERROR    = "FETCHING_ERROR",
  UPDATING          = "UPDATING",
  UPDATING_SUCCESS  = "UPDATING_SUCCESS",
  UPDATING_ERROR    = "UPDATING_ERROR";

export default {

    namespaced: true,

    state: {
        isLoading:          false,
        errors:             null,
        daysBeforeInactive:   '',
    },

    getters: {

        isLoading(state) {
            return state.isLoading;
        },

        hasErrors(state) {
            return state.errors !== null;
        },

        errors(state) {
            return state.errors;
        },

        daysBeforeInactive(state)
        {
            return state.daysBeforeInactive;
        },

    },

    mutations: {

        [FETCHING](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [FETCHING_SUCCESS](state, payload)
        {
            state.isLoading           = false;
            state.errors              = null;
            state.daysBeforeInactive  = payload.daysBeforeInactive;
        },

        [FETCHING_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

        [UPDATING](state)
        {
            state.isLoading = true;
            state.errors    = null;
        },

        [UPDATING_SUCCESS](state, payload)
        {
            state.isLoading         = false;
            state.errors            = null;
            state.daysBeforeInactive  = payload.daysBeforeInactive;
        },

        [UPDATING_ERROR](state, errors)
        {
            state.isLoading = false;
            state.errors    = errors;
        },

    },

    actions: {

        async get({commit})
        {
            commit(FETCHING);

            try {

                let response = await ActivitiesSettingsAPI.get();
                commit(FETCHING_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(FETCHING_ERROR, error);
                return null;
            }
        },

        async update({commit}, payload)
        {
            commit(UPDATING);

            try {

                let response = await ActivitiesSettingsAPI.update(payload);
                commit(UPDATING_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(UPDATING_ERROR, error);
                return null;
            }

        }

    },

}