import SubscriptionAPI from "../api/subscription";

const 
  FETCHING_ESTIMATE = "FETCHING_ESTIMATE",
  FETCHING_ESTIMATE_SUCCESS = "FETCHING_ESTIMATE_SUCCESS",
  FETCHING_ESTIMATE_ERROR = "FETCHING_ESTIMATE_ERROR",
  FETCHING_LAST_INVOICE = "FETCHING_LAST_INVOICE",
  FETCHING_LAST_INVOICE_SUCCESS = "FETCHING_LAST_INVOICE_SUCCESS",
  FETCHING_LAST_INVOICE_ERROR = "FETCHING_LAST_INVOICE_ERROR",
  FETCHING_SUBSCRIPTION = "FETCHING_SUBSCRIPTION",
  FETCHING_SUBSCRIPTION_SUCCESS = "FETCHING_SUBSCRIPTION_SUCCESS",
  FETCHING_SUBSCRIPTION_ERROR = "FETCHING_SUBSCRIPTION_ERROR",
  FETCHING_PORTAL = "FETCHING_PORTAL",
  FETCHING_PORTAL_SUCCESS = "FETCHING_PORTAL_SUCCESS",
  FETCHING_PORTAL_ERROR = "FETCHING_PORTAL_ERROR",
  REQUESTING = "REQUESTING",
  REQUESTING_SUCCESS = "REQUESTING_SUCCESS",
  REQUESTING_ERROR = "REQUESTING_ERROR",
  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",
  FETCH_PLANS_URL = "FETCH_PLANS_URL",
  FETCH_PLANS_URL_SUCCESS = "FETCH_PLANS_URL_SUCCESS",
  FETCH_PLANS_URL_ERROR = "FETCH_PLANS_URL_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    subscription: null,
    lastInvoice: null,
    portalUrl: null,
    checkoutMonthlyUrl: null,
    checkoutYearlyUrl: null,
    checkoutIndividualMonthlyUrl: null,
    checkoutIndividualYearlyUrl: null,
    estimateRenewal: null,
    estimateUpdate: null
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasErrors(state) {
      return state.error !== null;
    },
    getCheckoutYearlyUrl(state) {
      return state.checkoutYearlyUrl;
    },
    getCheckoutMonthlyUrl(state) {
      return state.checkoutMonthlyUrl;
    },
    getCheckoutIndividualYearlyUrl(state) {
      return state.checkoutIndividualYearlyUrl;
    },
    getCheckoutIndividualMonthlyUrl(state) {
      return state.checkoutIndividualMonthlyUrl;
    },
    getLastInvoice(state) {
      return state.lastInvoice;
    },
    getEstimateForRenewal(state){
        return state.estimateRenewal;
    },
    getEstimateForUpdate(state){
        return state.estimateUpdate;
    },
    getPortalUrl(state){
        return state.portalUrl;
    },
    get(state){
        return state.subscription;
    },
    getError(state) {
      return state.error;
    },
    isAppSumo(state) {
      return state.subscription && ['licenceone_tier1', 'licenceone_tier2'].includes(state.subscription.plan);
    },
    getStatus(state) {
      return state.subscription?.status;
    },
    getState(state) {
      return state.subscription?.state;
    },
    isTrial(state) {
      return state.subscription?.status === 'in_trial';
    }
  },
  mutations: {
    [REQUESTING](state) {
      state.isLoading = true;
      state.checkoutUrl = null;
      state.error = null;
    },
    [REQUESTING_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [REQUESTING_ERROR](state) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCH_PLANS_URL](state) {
      state.isLoading = true;
      state.checkoutUrl = null;
      state.error = null;
    },
    [FETCH_PLANS_URL_SUCCESS](state, checkoutsUrl) {
      state.isLoading = false;
      state.checkoutYearlyUrl = checkoutsUrl['yearly'];
      state.checkoutMonthlyUrl = checkoutsUrl['monthly'];
      state.checkoutIndividualYearlyUrl = checkoutsUrl['individual-yearly'];
      state.checkoutIndividualMonthlyUrl = checkoutsUrl['individual-monthly'];
      state.error = null;
    },
    [FETCH_PLANS_URL_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_ESTIMATE](state) {
      state.isLoading = true;
      state.checkoutUrl = null;
      state.error = null;
    },
    [FETCHING_ESTIMATE_SUCCESS](state, estimate) {
      state.isLoading = false;
      state.estimateRenewal = estimate.renewal_estimate;
      state.estimateUpdate = estimate.update_estimate;
      state.error = null;
    },
    [FETCHING_ESTIMATE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_LAST_INVOICE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_LAST_INVOICE_SUCCESS](state, lastInvoice) {
      state.isLoading = false;
      state.lastInvoice = lastInvoice;
      state.error = null;
    },
    [FETCHING_LAST_INVOICE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_SUBSCRIPTION](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_SUBSCRIPTION_SUCCESS](state, subscription) {
      state.isLoading = false;
      state.subscription = subscription;
      state.error = null;
    },
    [FETCHING_SUBSCRIPTION_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [FETCHING_PORTAL](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCHING_PORTAL_SUCCESS](state, portalUrl) {
      state.isLoading = false;
      state.portalUrl = portalUrl;
      state.error = null;
    },
    [FETCHING_PORTAL_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [UPDATING](state) {
      state.isLoading = true;
      state.error = null;
    },
    [UPDATING_SUCCESS](state, subscription) {
      state.isLoading = false;
      state.subscription = subscription;
      state.error = null;
    },
    [UPDATING_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    }
  },
  actions: {
    async getPortalUrl({commit}) {
      commit(FETCHING_PORTAL);
      try {
          let response = await SubscriptionAPI.getPortalUrl();
          commit(FETCHING_PORTAL_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(FETCHING_PORTAL_ERROR, error);
          return null;
      }
    },
    async getPlansUrl({commit}) {
      commit(FETCH_PLANS_URL);
      try {
          let response = await SubscriptionAPI.getPlansUrl();
          commit(FETCH_PLANS_URL_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(FETCH_PLANS_URL_ERROR, error);
          return null;
      }
    },
    async getLastInvoice({commit}) {
      commit(FETCHING_LAST_INVOICE);
      try {
          let response = await SubscriptionAPI.getLastInvoice();
          commit(FETCHING_LAST_INVOICE_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(FETCHING_LAST_INVOICE_ERROR, error);
          return null;
      }
    },
    async get({commit}, payload) {
      commit(FETCHING_SUBSCRIPTION);
      try {
          let response = await SubscriptionAPI.get(payload);
          commit(FETCHING_SUBSCRIPTION_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(FETCHING_SUBSCRIPTION_ERROR, error);
          return null;
      }
    },
    async getSubscriptionEstimate({commit}, payload) {
      commit(FETCHING_ESTIMATE);
      try {
          let response = await SubscriptionAPI.getSubscriptionEstimate(payload);
          commit(FETCHING_ESTIMATE_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(FETCHING_ESTIMATE_ERROR, error);
          return null;
      }
    },
    async sendSubscriptionRequest({commit}, payload) {
      commit(REQUESTING);
      try {
          let response = await SubscriptionAPI.sendSubscriptionRequest(payload);
          commit(REQUESTING_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(REQUESTING_ERROR, error);
          return null;
      }
    },
    async updateSubscriptionInitiator({commit}) {
      commit(REQUESTING);
      try {
          let response = await SubscriptionAPI.updateSubscriptionInitiator();
          commit(REQUESTING_SUCCESS, response.data);
          return response.data;
      } catch (error) {
          commit(REQUESTING_ERROR, error);
          return null;
      }
    }
  }
}