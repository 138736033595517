import client from "./client";

const apiUrl = "/v2/licence-statuses"

export default {
  get() {
    return client.get(apiUrl);
  },
  create(data) {
    return client.post("/api/licence-status", data);
  },
  update(data) {
    const id = data.id;
    delete data.id;
    return client.put("/api/licence-status/"+id, data);
  },
  setAsDefault(id) {
    return client.post("/api/licence-status/default/"+id);
  },
  delete(id) {
    return client.delete("/api/licence-status/"+id);
  },
  
  apiUrl:apiUrl
};