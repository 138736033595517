import client from "./client";

const apiUrl = "/v2/matching-rules"

export default {
    
    list(query) {
        return client.get(apiUrl, {
            params: query
        });
    },
    
    update(data) {
        const id = data.id;
        delete data.id;
        return client.patch(apiUrl+"/"+id, data, {
            headers: {
              "Content-Type": "application/merge-patch+json"
            }
        });
    },
    
    create(data) {
        return client.post(apiUrl, data);
    },

    apiUrl: apiUrl,
};
