<template>
    <div>
        <v-system-bar 
            v-if="isTrial && !company.sandbox"
            app
            window
            color="blue-80"
            class="gray-0--text justify-center"
            id="system-bar"
            height="40"
        >
            <div>
                {{ $t('company.trial.system-callout', {'days': (numberOfDaysRemaining + ' ' + $tc('commons.days', numberOfDaysRemaining)) }) }}
                <a v-if="isGranted(permissions.SEE_ADMIN_SECTIONS)" @click="trialUpgradeClick" class="ml-1 font-weight-bold text-lowercase underline">{{ $t('commons.buttons.upgrade-now') }}</a>
            </div>
        </v-system-bar>
         <subscription-modal 
            v-if="isTrial && !company.sandbox"
            v-model="openUpgradeModal"
            :persistent="false" 
        />
    </div>
</template>

<script>
    
    import {mapGetters} from 'vuex';
    import mixin from '../../Mixins/mixin.js';
    import Security from '../../Mixins/Security';
    import SubscriptionModal from '@app/js/components/modal/subscription/Subscription'

    export default{
        mixins: [mixin, Security],
        data() {
            return {
                openUpgradeModal: false,
            }
        },  
        components: {
            SubscriptionModal
        },
        methods: {
            trialUpgradeClick() {
                this.openUpgradeModal = true;
            }, 
        },
        computed: {
            ...mapGetters('subscription', {
                subscription: 'get',
                isTrial: 'isTrial'
            }),
            ...mapGetters('company', {
                company: 'get',
            }),
            numberOfDaysRemaining(){
                if(this.subscription){
                    let trialEndDate = this.$moment(this.subscription.trialEndDate)
                    let today = this.$moment()
                    let numberOfDays = trialEndDate.diff(today, 'days')+1
                    return numberOfDays;
                }

                return 0;
            }, 
        }
    }
</script>


<style lang="scss" scoped>
    #system-bar {
        box-sizing: border-box;
        box-shadow: 0px -1px 0px 0px var(--v-blue-40-base) inset;
        z-index: 5;
    }
</style>