<template>
    <v-app-bar
        app
        class="mx-0 px-12 topbar"
        light
        color="#FFFFFF"
        flat>
        <router-link :to="topNav.link" class="grey-8--text" v-if="topNav.link">
            <div :class="'topbar__link py-1 pr-2 rounded-lg d-flex align-center' + ((topNav.link)? '' : 'topbar__link--hidden')">
                <icon-base
                    icon-name="icon-chevron-left"
                >
                    <icon-chevron-left></icon-chevron-left>
                </icon-base> 
                <p class="ma-0">{{topNav.label}}</p>
            </div>
        </router-link>
        <v-spacer></v-spacer>
        <searchbar></searchbar>
        <v-spacer></v-spacer>
        <sandbox-to-real-data-modal
            v-if="company.sandbox"
            v-model="sandboxToRealDataModalDialog"
        >
        </sandbox-to-real-data-modal>
        <v-btn
            v-if="inImpersonateMode"
            class="ml-4"
            color="red-30"
            small
            fab
            dark
            depressed
            @click="stopImpersonateMode"
        >
            <v-icon
            >
                mdi-drama-masks
            </v-icon>
        </v-btn>
    </v-app-bar>
</template>
<script>

import IconBase from '../components/IconBase';
import IconSettings from "../components/icons/IconSettings";
import IconNew from "../components/icons/IconNew";
import IconClock from "../components/icons/IconClock";
import IconChevronLeft from '../components/icons/IconChevronLeft';
import Searchbar from "../components/layout/Searchbar";
import SandboxToRealDataModal from '../components/modal/SandboxToRealDataModalAndConfirmEmail';
import mixin from '../Mixins/mixin.js';
import {mapGetters} from 'vuex';
import ImpersonateMode from '../services/ImpersonateMode';

export default {
    name: "topbar",
    mixins: [mixin],
    components: {
        IconBase,
        IconSettings,
        IconClock,
        IconNew,
        IconChevronLeft,
        Searchbar,
        SandboxToRealDataModal,
    },

    data() {
        return {
            sandboxToRealDataModalDialog: false
        };
    },

    computed: {
        topNav(){
            return this.$store.getters.topNav
        },
        ...mapGetters('company', {
            company: 'get',
        }),
        ...mapGetters('subscription', {
            subscription: 'get',
        }),
        inImpersonateMode() {
            return ImpersonateMode.isActive();
        },

    },

    methods: {
        stopImpersonateMode() {
            ImpersonateMode.stop();
        },
    }
}
</script>
<style lang="scss">
    @import '../../scss/mixins.scss';

    #content-app .v-app-bar{
        border-bottom: 1px solid var(--v-grey-2-base) !important;
        & > .v-toolbar__content{
            margin-right: 48px;
            margin-left: 48px;
        }
    }
    .topbar {
        border-bottom: 1px solid var(--v-gray-70-base) !important;
        &__link{
            width: fit-content;
            @include transition;
            &:hover {
                background: var(--v-gray-80-base);
            }
            &--hidden {
                visibility: hidden;
            }
        }
    } 
</style>