//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog.init(
        process.env.POST_HOG_ID,
      {
        api_host: process.env.POST_HOG_HOST
      }
    );
  }
};