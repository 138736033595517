import { mapGetters } from 'vuex'
import countriesData from '../databases/countries.json'
import OnboardingGuard from '../services/Onboarding/OnboardingGuard';
import LicenceHelper from '../services/Licence/LicenceHelper';
import analytics from '@app/js/services/Analytics/Segment';

import ScriptLoader from '@app/loaders/ScriptLoader';
import PlaidScript from '@app/loaders/scripts/Plaid';
import Crisp from '@app/loaders/scripts/Crisp';

export default {
  data: function () {
    return {
      countries: countriesData,
      inventoryPricePerMonth: 10,
      unitPricePerMonth: 1,
      freeMonths: 2,
      placeholderName: "Johnathan Bell",
      placeholderEmail: "john@licenceone.com",
      monthlyReferralDiscountPercent: 0.30,
      yearlyReferralDiscountPercent: 0.30,
      numberOfDaysExtensionUserActive: 30,
      limitPercentageUsersOnExtension: 75,
      isAmortized: !(window.localStorage.getItem('isAmortized') === 'false'),
      slackAppId: process.env.SLACK_APP_ID,
      onboardingStepUrl: OnboardingGuard.onboardingRoutes,
      doNotRedirectRoutesForOnboarding: OnboardingGuard.whiteListedRoutes,
      sandboxToRealDataActionDialog: false,
    }
  },
  beforeRouteLeave (to, from , next) {
      if(this.avoidRedirectionWithChanges) {
        this.$store.dispatch("preventRedirect/wantQuitPage", {
          nextRouteUrl: to.path, 
        });
      } else {
          next()
      }
  },
  watch: {
    isAmortized(val) {
      window.localStorage.setItem('isAmortized', val)
    },
  },
  computed: {
    ...mapGetters([
      'hasRealDataSource',
      'hasDataSource',
      'hasBankDataSource',
      'hasGoogleWorkspaceConnected'
    ]),
    ...mapGetters('company', {
      company: 'get',
    }),
    ...mapGetters('subscription', {
      subscription: 'get'
    }),
    ...mapGetters('activity', {
      lastBrowserUsages : 'lastBrowserUsages',
    }),
    ...mapGetters('user', {
      user: 'get',
      activeUsers: 'getActiveUsers',
      trackedUsers: 'getTrackedUsers',
    }),
    ...mapGetters('preventRedirect', {
      avoidRedirectionWithChanges : "getAvoidRedirectionWithChanges"
    }),

    numberOfTrackedEmployees() {
      return this.trackedUsers.length;
    },

    unitPricePerYear(){
      return this.unitPricePerMonth * 12
    },

    inventoryPricePerYear(){
      return this.inventoryPricePerMonth * 12
    },
    
    discountedInventoryPrice(){
      return this.inventoryPricePerMonth * this.freeMonths
    },

    isBankApiConnected() {
      return !this.company.sandbox
        && (
          this.company.plaidItems.length > 0
          || this.company.bridgeAccess
        )
    },
    percentageUsersConnected() {
      return (this.numberActiveUsersOnExtension() * 100) / this.trackedUsers.length
    },
  },
  methods: {

    isEllipsisActive(e) {
      return (e.offsetWidth < e.scrollWidth);
    },
    getSystemFilter(statuses, step){
      return LicenceHelper.getSystemFilter(statuses, step);
    },
    filterByStatus(licences, status){
      return LicenceHelper.filterByStatus(licences, status);
    },
    isLicenceInactive(licence){
      return LicenceHelper.isLicenceInactive(licence);
    },
    closeModal(ref) {
      this.$refs[ref].close()
    },
    openModal(ref) {
      this.$refs[ref].open()
    },
    computeDateAgo(date) {
      let now = this.$moment()
      let days = now.diff(date, "days")

      if (days > 7) {
        return this.$tc('activity-log.dates.weeks-ago', now.diff(date, "weeks"), { 'nbWeeks': now.diff(date, "weeks") })
      }

      return this.$tc('activity-log.dates.days-ago', days, { 'nbDays': days })
    },
    async openChat() {
      const $crisp = await ScriptLoader.load(new Crisp);
      if (typeof $crisp === 'object') {
        $crisp.push(["do", "chat:show"])
        $crisp.push(["do", "chat:open"])
      }
    },

    getLastSpend(licence) {

      var total = 0;

      if (licence.chargeCondition === "monthly") {

        if (licence.transactions && licence.transactions.length > 0) {

          const StartDate = this.$moment().subtract(1, 'month')
          const EndDate = this.$moment()

          licence.transactions.forEach(transaction => {
            var transactionDate = this.$moment(transaction.date);
            if (transactionDate.isBetween(StartDate, EndDate, null, '[]')) {
              total += Math.abs(transaction.amount);
            }
          });
        }
        return total;

      } else if (licence.chargeCondition === "yearly") {

        if (licence.transactions && licence.transactions.length > 0) {

          const StartDate = this.$moment().subtract(1, 'year')
          const EndDate = this.$moment()

          licence.transactions.forEach(transaction => {
            var transactionDate = this.$moment(transaction.date);
            if (transactionDate.isBetween(StartDate, EndDate, null, '[]')) {
              total += Math.abs(transaction.amount);
            }
          });
        }
        return total;

      } else {

        if (licence.transactions && licence.transactions.length > 0) {
          let lastTransaction = licence.transactions[licence.transactions.length - 1]

          licence.transactions.forEach(transaction => {
            if (lastTransaction.date < transaction.date) {
              lastTransaction = transaction
            }
          });
          return Math.abs(lastTransaction.amount)
        }
      }

      return total
    },
    redirectTo(link, blank) {
      if (blank) {
        window.open(link)
      } else {
        window.location = link
      }
    },
    sortDataTables(items, index, isDescending) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items
      }

      let isDesc = isDescending[0];

      items.sort((a, b) => {

        let firstEl = a[index[0]];
        let secondEl = b[index[0]];

        switch (index[0]) {

          case 'customApp':
            firstEl = a.app.name ? a.app.name.toLowerCase() : a.customApp;
            secondEl = b.app.name ? b.app.name.toLowerCase() : b.customApp;
            break;

          case 'name':
            firstEl = a.user ? a.user.firstName.toLowerCase() : '';
            secondEl = b.user ? b.user.firstName.toLowerCase() : '';
            break;

          case 'manager':
            firstEl = a.manager && a.manager.firstName ? a.manager.firstName.toLowerCase() : 'zz';
            secondEl = b.manager && b.manager.firstName ? b.manager.firstName.toLowerCase() : 'zz';
            break;

          case 'department':
            firstEl = a.department ? a.department.name.toLowerCase() : 'zz';
            secondEl = b.department ? b.department.name.toLowerCase() : 'zz';
            break;

          case 'licenceStatus':
            firstEl = a.status ? a.status.name.toLowerCase() : 'zz';
            secondEl = b.status ? b.status.name.toLowerCase() : 'zz';
            break;

          case 'chargeCondition':

            let sortingRule = {
              "monthly": 0,
              "yearly": 1,
              "onetime": 2,
              "other": 3,
              "pending": 4,
              "null": 5
            };

            firstEl = sortingRule[(a.isFree) ? "null" : a.chargeCondition];
            secondEl = sortingRule[(b.isFree) ? "null" : b.chargeCondition];
            break;  
          case 'status': 

            let statusSortingRule = {
              "not-installed": 0,
              "unactive": 1,
              "active": 2,
            };

            firstEl = statusSortingRule[a.status.text];
            secondEl = statusSortingRule[b.status.text];
            break;  
          case 'lastSpend':
            firstEl = (a.isFree) ? -1 : ((a.lastSpend > 0) ? a.lastSpend : -2);
            secondEl = (b.isFree) ? -1 : ((b.lastSpend > 0) ? b.lastSpend : -2);
            break;

          case 'lastseen':
          case 'activities':
            firstEl = a.time ? a.time : '';
            secondEl = b.time ? b.time : '';
            break;
        }
        return this.sortElements(firstEl, secondEl, isDesc);
      });

      return items;
    },

    sortElements(firstElement, secondElement, isDesc) {
      if (firstElement === "" || firstElement === null) return 1;
      if (secondElement === "" || secondElement === null) return -1;
      if (firstElement === secondElement) return 0;

      if (!isDesc) {
        return firstElement > secondElement ? 1 : -1
      } else {
        return firstElement < secondElement ? 1 : -1
      }
    },

    async openBridgeUpdate(bridgeItemId) {
      const response = await this.$store.dispatch(
        "bank/updateItem"
        , bridgeItemId
      );

      if (!this.$store.getters["bank/hasError"]) {
        document.location.href = this.$store.getters["bank/getBankUpdateUrl"]
      }
    },

    /**
     * Reinitialize plaid connect when using OAuth
     */
    reinitializePlaidConnect() {
      const token = window.localStorage.getItem('ppt');
      const receivedRedirectUri = window.location.href;

      return this.openPlaidConnect(token, receivedRedirectUri);
    },

    /**
     * Update an item with plaid connect
     * @param {*} itemId 
     */
    async updatePlaidConnect(itemId) {
      const response = await this.$store.dispatch("bank/updatePlaidItem", itemId);

      if (response.link_token) {
        const token = response.link_token;
        window.localStorage.setItem('ppt', token);

        return this.openPlaidConnect(token);
      }
    },

    /**
     * Create an item with plaid connect
     */
    async createPlaidConnect() {
      const response = await this.$store.dispatch("bank/connectPlaid");

      if (response.link_token) {
        const token = response.link_token;
        window.localStorage.setItem('ppt', token);

        return this.openPlaidConnect(token);
      }
    },

    /**
     * Open plaid connect
     * @param {*} token 
     * @param {*} receivedRedirectUri 
     */
    async openPlaidConnect(token, receivedRedirectUri) {
      this.bankConnect = false;
      this.bankConnectLoading = false

      const Plaid = await ScriptLoader.load(new PlaidScript);
      Plaid.create({
        token: token,
        receivedRedirectUri: receivedRedirectUri,
        onSuccess: (public_token, metadata) => {
          this.$store.dispatch(
            "bank/exchangePlaidToken",
            {
              'publicToken': public_token
            }
          ).then(function () {
            window.localStorage.removeItem('ppt');
            window.location = '/account/processing';
          });
        },
        onLoad: () => { },
        onExit: (err, metadata) => {
          this.logToSegment('exitedPlaidConnect');
          window.localStorage.removeItem('ppt');
        },
        onEvent: (eventName, metadata) => { },
        //required for OAuth; if not using OAuth, set to null or omit:
      }).open();
    },
    isUserGranted(role, user) {
      let roles = user.roles
      return roles.includes(role)
    },
    async updateUser(data) {
      await this.$store.dispatch("user/update", data);
    },
    async updateTask(data) {
      await this.$store.dispatch("task/update", data);
    },
    async updateLicence(data) {
      await this.$store.dispatch("licence/update", data);
    },
    async prepareUpdateUser(id, userData, toUpdate) {

      var payload = { 'id': id };

      toUpdate.forEach(param => {
        payload[param] = userData[param]
      });

      await this.$store.dispatch("user/update", payload);
    },
    toDayString(date) {

      var today = this.$moment();
      var yesterday = this.$moment().subtract(1, 'day');

      if (this.$moment(date).isSame(today, 'day')) {
        return this.$i18n.t('commons.date-temp.today')
      } else if (this.$moment(date).isSame(yesterday, 'day')) {
        return this.$i18n.t('commons.date-temp.yesterday')
      } else {
        return this.$moment(date).format('LL');
      }
    },
    googleLogin(isLogin) {
      this.logToSegment((isLogin) ? 'loginGoogle' : 'registerGoogle')
      window.location = '/api/connect/google'

    },

    formatNumber(number, currency, forceDecimals, style) {
      // Modulo 1 allows to know if there are decimals
      // If there are any we limit to two decimals 
      // Otherwise we put 0 decimal
      let minDecimal = 0;
      let maxDecimal = 0;
      if (number % 1 !== 0 || forceDecimals) {
        minDecimal = 2;
        maxDecimal = 2;
      }

      let options = {
        minimumFractionDigits: minDecimal,
        maximumFractionDigits: maxDecimal,
      };

      if (currency) {
        style = 'currency';
      }

      let styleOptions = {
        style: style,
      };

      if (currency) {
        styleOptions.currency = currency;
      }

      if(style === 'percent'){
        number = number / 100;
        styleOptions.signDisplay = "exceptZero";
      }

      options = {
        ...options,
        ...styleOptions,
      }

      return new Intl.NumberFormat(navigator.language, options).format(number);
    },

    formatLastSpend(licence) {
      if (licence.lastSpend > 0) {
        let currency = licence.transactions[0] ? licence.transactions[0].currency : this.getCurrencyCode();
        return this.formatNumber(licence.lastSpend, currency)
      } else if (licence.isFree === true) {
        return this.$i18n.t('licences.type.free')
      } else {
        return '-'
      }
    },

    logToSegment(name, props) {
      analytics.track(name, props ?? {});
    },
    formatDate(dateToFormat) {
      const date = new Date(dateToFormat);
      return new Intl.DateTimeFormat(navigator.language).format(date);
    },
    isDate(date, format) {
      const momentDate = this.$moment(date, format, true);
      return momentDate.isValid();
    },
    getMonthNumber(date) {
      return this.$moment(date).format("M");
    },
    getYearNumber(date) {
      return this.$moment(date).format("YYYY");
    },
    getDaysRemainingWithDate(date) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = this.$moment(date)
      const secondDate = this.$moment().startOf('day')

      return Math.round((firstDate - secondDate) / oneDay);
    },
    getFormattedNavigatorLang() {

      const langToReplace = {
        'fr': "fr-FR",
        'en': "en-GB"
      };

      const lang = navigator.language
      const regexpIsoCode = /^[a-z]{2}-[A-Z]{2}$/

      if (regexpIsoCode.test(lang) && (lang == "fr-FR" || lang == "en-GB")) {
        return lang
      } else if (langToReplace[lang]) {
        return langToReplace[lang]
      }

      return "en-GB"
    },

    /**
     * @param {Object} user
     * @returns {string}
     */
    formatUserFullName(user) {
      return user.firstName && user.lastName ?
        user.firstName + " " + user.lastName :
        user.email
        ;
    },

    getLast12MonthsSpendFromLicence(licence) {

      let total = 0;

      if (licence.transactions && licence.transactions.length > 0) {

        const StartDate = this.$moment().subtract(1, 'year')
        const EndDate = this.$moment()

        licence.transactions.forEach(transaction => {
          let transactionDate = this.$moment(transaction.date);
          if (!transaction.isArchived && transactionDate.isBetween(StartDate, EndDate, null, '[]')) {
            total += Math.abs(transaction.amount);
          }
        });
      }

      return total;
    },

    getLastSpendFromLicence(licence, transactions) {

      const transactionsToProcess = transactions ? transactions : (licence.transactions ?? []);
      let currency = transactionsToProcess[0] ? transactionsToProcess[0].currency : this.getCurrencyCode();

      if (licence.isFree === true) {
        return this.$i18n.t('licences.type.free')
      } else if (transactionsToProcess.length > 0 && licence.chargeCondition == "yearly") {
        return this.formatNumber(this.getLastSpend(licence), currency, true);
      } else if (transactionsToProcess.length > 0 && licence.chargeCondition == "monthly") {
        return this.formatNumber(this.getLastSpend(licence), currency, true);
      } else if (transactionsToProcess.length > 0 && ['other', 'onetime'].includes(licence.chargeCondition)) {
        return this.formatNumber(this.getLastSpend(licence), currency, true);
      } else {
        return "-";
      }
    },

    /**
     * @returns {string} Default currency used by the app, not user related (e.g. pricing informations)
     */
    getSystemCurrency: () => 'EUR',

    /**
     * Get the currency symbol depending on the account
     * @returns {string}
     */
    getCurrencyCode() {

      if (this.company && this.company.country) {
        return this.countries.find(country => country.isoAlpha2 == this.company.country).currency.code;
      }

      return this.getSystemCurrency();
    },

    getCurrencySymbol(currencyCode) {

      let currency = (currencyCode) ? currencyCode : this.getCurrencyCode();

      return new Intl.NumberFormat('en', { style: 'currency', currency: currency }).formatToParts("1").find(part => part.type = "currency").value;
    },

    getCurrencyPosition() {
      let currencyCode = this.getCurrencyCode()

      let formatter = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "code"
      });
      let parts = formatter.formatToParts(99); // number doesn't matter here
      let currencyIndex = parts.findIndex(part => part.value === currencyCode);

      if (currencyIndex === 0) {
        return 'before';
      } else {
        return 'after';
      }
    },

    /**
     * Get a parameter in the url
     * @param {string} name 
     */
    getUrlParam(name) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name)
    },

    isPersonalEmail(email) {
      const emailProviders = require("email-providers/all.json")
      const parser = require('tld-extract')

      // 1. Extract the domain
      const broken = email.split('@')
      const address = `http://${broken[broken.length - 1]}`
      const { domain } = parser(address);

      // 2. And check!
      return emailProviders.includes(domain)
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    redirectUri(){
      const route = this.$router.resolve('/slack/callback');
      return new URL(route.href, window.location.origin).href;
    },
    slackUrl(){
      return "https://slack.com/oauth/v2/authorize?scope="
        + "channels:manage," 
        + "chat:write.public," 
        + "chat:write," 
        + "im:write," 
        + "links:write," 
        + "users:read," 
        + "users:read.email" 
        + "&user_scope=&redirect_uri=" + this.redirectUri() + "&client_id=" + process.env.SLACK_ID
    },
    getIsoAlpha2(timezones) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
      if (
        timezone === "" 
        || !timezone 
        || !timezones[timezone] 
        || !timezones[timezone].c
      ) {
        return null;
      }
  
      return timezones[timezone].c[0] ?? null;
    },
    onboardingGuard() {
      let onboardingStep = this.company.onboardingStep

      if(
        onboardingStep === 0
        || onboardingStep ===  -1
      ) {
        this.$router.push({
            name: 'dashboard',
        });
      } else if(
        onboardingStep > 0 
        && this.onboardingStepUrl[onboardingStep] !== this.$route.name 
      ){
          this.$router.push({
              name: this.onboardingStepUrl[onboardingStep],
          });
      }
    },
    
    autofillRenewal(licence){
      var firstPaymentDate = licence.firstPaymentAt ?? licence.transactions[0]?.date ?? null

      if(!firstPaymentDate){
        return null
      }

      var date = this.$moment(firstPaymentDate).set('year', this.$moment().get('year')+1);;   

      return date.format('YYYY-MM-DD');
    },
    hasGoogleId() {
      return this.user.googleId != null
    },
    numberActiveUsersOnExtension() {
      let activeUsers = []
      
      this.trackedUsers.forEach(user => {
        let activity = this.lastBrowserUsages.find((activity) => activity.user_id  === user.id);
        if(activity && activity.last_activity_date && this.$moment(activity.last_activity_date).isAfter(this.$moment().subtract(this.numberOfDaysExtensionUserActive, 'days').startOf('day'))) {
          activeUsers.push(user)
        }
      });

      return activeUsers.length
    },
    handleSandboxAction(event, callback) {
        if(this.company.sandbox){
            this.openSandboxToRealDataActionModal();
        }else{
            callback(event);
        }
    },
    openSandboxToRealDataActionModal() {
        this.sandboxToRealDataActionDialog = true;
    },
    async logout(){
      this.logToSegment('userIcon_clickedLogout');
      const response = await this.$store.dispatch("user/logout");

      if(response){
          window.location.href = '/login';
      }
  },
  }
};