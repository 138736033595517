<template>
  <v-snackbar 
    v-model="isShown" 
    left
    rounded="4px"
    color="gray-10" 
    :timeout="timeout"
    style="left: auto !important"
    max-width="100%"
  >
    <div class="d-inline-flex justify-space-between align-center w-100" style="gap: 32px;">
        <div>
          {{message}}
        </div>
        <div class="cpointer fw-6 f-5" style="color: var(--v-blue-60-base);" v-if=" redirectLink !== '' && redirectMsg !== ''" @click="redirectTo(redirectLink)">
          {{ redirectMsg }}
        </div>
        <div class="cpointer" @click="isShown = false">
          <icon-base
            icon-name="icon-cross"
          >
            <icon-cross></icon-cross>
          </icon-base>
        </div>
    </div>
  </v-snackbar>
</template>

<script>
  import {mapGetters} from 'vuex';
  import IconBase from './IconBase';
  import IconCross from './icons/IconCross';

  export default {
    data () {
      return {
        isShown: false
      }
    },
    components: {
      IconBase,
      IconCross
    },
    computed: {
      ...mapGetters('snackbar', {
          message: 'getMessage', 
          timeout: 'getTimeout',
          redirectLink: 'getRedirectLink',
          redirectMsg: 'getRedirectMsg',
          show: 'isShown'
      })
    },
    watch: {
      show(val){
        if(val){
          this.isShown = val
        }
      },
      isShown(val){
        if(!val){
          this.$store.dispatch("snackbar/reset");
        }
      }
    },
    methods: {
      redirectTo(path) {
        if(this.isInternPath(path)) {
          this.$router.push({path: path})
        } else {
          window.open(path, '_blank')
        }
        this.isShown = false
      },
      isInternPath(path) {
        return (path.charAt(0) === "/")? true : false
      }
    },
  }
</script>
<style>
  .v-snack__content{
    font-size: 14px !important;
    color: #FFFFFF;
  }
</style>