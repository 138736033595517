<template>
    <subscription-modal v-model="openSubscriptionModal" v-if="subscriptionState == 'end_trial' || subscriptionState == 'cancelled'"/>
</template>
<script>
    import SubscriptionModal from "@app/js/components/modal/subscription/Subscription";

    export default {
        components: {
            SubscriptionModal
        },
        data () {
            return {
                openSubscriptionModal : true,
            }
        },
        props:{
            subscriptionState: {
                type: String,
                default: ''
            }
        }
    };
</script>