import TransactionAPI from "../api/transaction";
import Collection from "../../store/modules/transaction/collection";
import moment from 'moment';

const FETCHING_TRANSACTIONS = "FETCHING_TRANSACTIONS",
  FETCHING_TRANSACTIONS_SUCCESS = "FETCHING_TRANSACTIONS_SUCCESS",
  FETCHING_TRANSACTIONS_ERROR = "FETCHING_TRANSACTIONS_ERROR",
  REASSIGNING_TRANSACTIONS = "REASSIGNING_TRANSACTIONS",
  REASSIGNING_TRANSACTIONS_SUCCESS = "REASSIGNING_TRANSACTIONS_SUCCESS",
  REASSIGNING_TRANSACTIONS_ERROR = "REASSIGNING_TRANSACTIONS_ERROR",
  DELETING_TRANSACTIONS = "DELETING_TRANSACTIONS",
  DELETING_TRANSACTIONS_SUCCESS = "DELETING_TRANSACTIONS_SUCCESS",
  DELETING_TRANSACTIONS_ERROR = "DELETING_TRANSACTIONS_ERROR",
  UPDATING_TRANSACTIONS = "UPDATING_TRANSACTIONS",
  UPDATING_TRANSACTIONS_SUCCESS = "UPDATING_TRANSACTIONS_SUCCESS",
  UPDATING_TRANSACTIONS_ERROR = "UPDATING_TRANSACTIONS_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    transactions: [],
    monthlySpent: 0
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasTransactions(state) {
      return state.transactions.length > 0;
    },
    transactions(state) {
      return state.transactions;
    },
    monthlySpent(state) {

      let spend = 0;
      for (const transaction of state.transactions){
        if(moment().startOf('month').isBefore(transaction.date)){
          spend += parseFloat(transaction.amount) * -1;
        }
      }
      return spend;
    }
  },
  mutations: {
    [FETCHING_TRANSACTIONS](state) {
      state.isLoading = true;
      state.error = null;
      state.transactions = [];
    },
    [FETCHING_TRANSACTIONS_SUCCESS](state, transactions) {
      state.isLoading = false;
      state.error = null;
      state.transactions = transactions;
    },
    [FETCHING_TRANSACTIONS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.transactions = [];
    },
    [REASSIGNING_TRANSACTIONS](state) {
      state.isLoading = true;
      state.error = null;
    },
    [REASSIGNING_TRANSACTIONS_SUCCESS](state, licence) {
      state.isLoading = false;
      state.error = null;
      state.transactions = licence.transactions;
    },
    [REASSIGNING_TRANSACTIONS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    [DELETING_TRANSACTIONS](state) {
      state.isLoading = true;
      state.error = null;
    },
    [DELETING_TRANSACTIONS_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [DELETING_TRANSACTIONS_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    }
  },
  actions: {

    async list({ commit }, filters) {
      commit(FETCHING_TRANSACTIONS);
      try {
        const response = await TransactionAPI.list(filters);
        commit(FETCHING_TRANSACTIONS_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(FETCHING_TRANSACTIONS_ERROR, error);
        return null;
      }
    },

    async archiveBatch({ commit }, payload) {
      commit(DELETING_TRANSACTIONS);
      try {
        let response = await TransactionAPI.archiveBatch(payload);
        commit(DELETING_TRANSACTIONS_SUCCESS);
        return response.data;
      } catch (error) {
        commit(DELETING_TRANSACTIONS_ERROR, error);
        return null;
      }
    },

    async reassign({ commit }, payload) {
      commit(REASSIGNING_TRANSACTIONS);
      try {
        let response = await TransactionAPI.reAssign(payload);
        commit(REASSIGNING_TRANSACTIONS_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(REASSIGNING_TRANSACTIONS_ERROR, error);
        return null;
      }
    },

    async batch({ commit }, payload) {
      commit(UPDATING_TRANSACTIONS);
      try {
        let response = await TransactionAPI.batch(payload);
        commit(UPDATING_TRANSACTIONS_SUCCESS, response.data);
        return response.data;
      } catch (error) {
        commit(UPDATING_TRANSACTIONS_ERROR, error);
        return null;
      }
    },
  },

  modules: {
    collection: Collection,

  }
};