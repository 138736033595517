<template>
    <div class="text-center">
        <lottie-player :src="loaderLottie" background="transparent" speed="1" style="margin: auto;width: 100px; height: 100px;" loop autoplay/>
        <p>{{ $t("commons.loading") }}</p>
    </div>   
</template>

<script>
    import loaderLottie from '@app/lotties/loader.json';

    export default {
        name: "loader",
        data() {
            return {
                loaderLottie: JSON.stringify(loaderLottie),
            };
        },
    };
</script>
