"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Crisp_isReady, _Crisp_readyTimeout, _Crisp_timeoutRejecter;
Object.defineProperty(exports, "__esModule", { value: true });
class Crisp {
    constructor() {
        _Crisp_isReady.set(this, void 0);
        _Crisp_readyTimeout.set(this, void 0);
        _Crisp_timeoutRejecter.set(this, void 0);
        __classPrivateFieldSet(this, _Crisp_isReady, false, "f");
        __classPrivateFieldSet(this, _Crisp_timeoutRejecter, () => { }, "f");
    }
    isAvailable() {
        return typeof process.env.CRISP_ID === 'string' && process.env.CRISP_ID.length > 0;
    }
    initialize() {
        window.$crisp = window.$crisp || [];
        window.CRISP_WEBSITE_ID = process.env.CRISP_ID;
        __classPrivateFieldSet(this, _Crisp_isReady, new Promise((resolve, reject) => {
            __classPrivateFieldSet(this, _Crisp_timeoutRejecter, reject, "f");
            __classPrivateFieldSet(this, _Crisp_readyTimeout, setTimeout(__classPrivateFieldGet(this, _Crisp_timeoutRejecter, "f"), 30000), "f");
            window.CRISP_READY_TRIGGER = () => {
                clearTimeout(__classPrivateFieldGet(this, _Crisp_readyTimeout, "f"));
                resolve(true);
            };
        }), "f");
    }
    prepareDomElement(element) {
        element.src = "https://client.crisp.chat/l.js";
    }
    getInstance() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield __classPrivateFieldGet(this, _Crisp_isReady, "f");
                return window.$crisp;
            }
            catch (e) {
                return;
            }
        });
    }
    errorHandler(error) {
        clearTimeout(__classPrivateFieldGet(this, _Crisp_readyTimeout, "f"));
        __classPrivateFieldGet(this, _Crisp_timeoutRejecter, "f").call(this, error);
    }
}
_Crisp_isReady = new WeakMap(), _Crisp_readyTimeout = new WeakMap(), _Crisp_timeoutRejecter = new WeakMap();
exports.default = Crisp;
