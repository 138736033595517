import Authorization from "../api/Authorization";
import Security from "../services/Security/Security";

const 
    FETCHING          = "FETCHING",
    FETCHING_SUCCESS  = "FETCHING_SUCCESS",
    FETCHING_ERROR    = "FETCHING_ERROR"
;

export default {

    namespaced: true,

    state: {
        loading: false,
        error: null,
        authorization: null,
    },

    getters: {

        permissions(state) {
            return state.authorization?.permissions;
        },

    },

    mutations: {

        [FETCHING](state){
            state.loading = true;
        },

        [FETCHING_SUCCESS](state, authorization){
            state.loading = false;
            state.error = null;
            state.authorization = authorization;
            Security.authorization = authorization;
        },

        [FETCHING_ERROR](state, error){
            state.loading = false;
            state.error = error;
        },

    },

    actions: {

        async get({commit})
        {
            commit(FETCHING);

            try {

                const response = await Authorization.get();
                commit(FETCHING_SUCCESS, response.data);

                return response.data;

            } catch (error) {

                commit(FETCHING_ERROR, error);
                return null;
            }
        },

    },

}