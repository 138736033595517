import client from "./client";

export default {
    revoke() {
        return client.delete("/api/google/access");
    },

    getAuthUrl() {
        return client.get("/api/google/auth-url");
    },

    connectWorkspace(payload) {
        return client.post("/api/google-workspace/oauth-callback", payload);
    },

};
