import client from "./client";

const apiUrl = "/v2/departments"

export default {
  getAll() {
    return client.get(apiUrl);
  },
  create() {
    return client.post("/api/departments/create", {});
  },
  update(id, data) {
    return client.post("/api/departments/update/"+id, data);
  },
  delete(id){
    return client.delete("/api/departments/delete/"+id);
  },

  apiUrl: apiUrl,
};