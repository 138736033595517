import client from "./client";

export default {

    session: {
        create: () => {
            return client.post("/api/accounting/session");
        },
    },

    connection: {
        list: () => {
            return client.get("/api/accounting/connections");
        },
    },

    synchronize: (service) => {
        return client.post("/api/accounting/synchronize", {
            service: service,
        });
    },
};