import moment from 'moment';

export default class SearchEngine {

    static AVAILABLE_CRITERIAS = [
        'createdDate',
        'assignedToTeam',
    ];

    constructor(filters, vueStore) {
        this.filters = filters;
        this.vueStore = vueStore;
    };

    get store() {
        if(!this.vueStore){
            throw new Error('VueStore has not been injected to search engine');
        }

        return this.vueStore;
    };

    search(activeFilters, entity) {

        let match = true;
        for (const filterName in activeFilters){

            switch(filterName){
                case 'createdDate':
                    match = match && this.searchCreatedDate(activeFilters[filterName], entity);
                    break;

                case 'assignedToTeam':
                    match = match && this.searchAssignedToTeam(activeFilters[filterName], entity);
                    break;

                case 'assignedToUser':
                    match = match && this.searchAssignedToUser(activeFilters[filterName], entity);
                    break;
            }
        }

        return match;
    };

    searchCreatedDate(filter, entity) {

        // No date selected, no need to search
        if(!filter.value || filter.value === 'whenever'){
            return true;
        }

        const filterConfig = this.filters.find(filter => filter.type === 'createdDate');
        const createdDate = filterConfig.getter(entity);
        
        switch(filter.value){
            case '-1 year':
                return moment(createdDate).isSameOrAfter(moment().subtract(1, 'year'));

            case '-1 month':
                return moment(createdDate).isSameOrAfter(moment().subtract(1, 'months'));

            case '-7 days':
                return moment(createdDate).isSameOrAfter(moment().subtract(7, 'days'));

        }

        throw new Error('Value ' + filter.value + ' is not implemented yet. Please implement it or use a possible value')
    };

    searchAssignedToTeam(team, entity) {

        // No team selected, no need to search
        if(!team.value){
            return true;
        }

        const filterConfig = this.filters.find(filter => filter.type === 'assignedToTeam');
        const department = filterConfig.getter(entity);

        return department && department.id === team.value;
    };

    searchAssignedToUser(user, entity) {

        // No user selected, no need to search
        if(!user.value || user.value === 'anyone'){
            return true;
        }
        
        const currentUser   = this.store.getters['user/get'];
        const filterConfig  = this.filters.find(filter => filter.type === 'assignedToUser');
        const assignedUser  = filterConfig.getter(entity);

        switch(user.value){
            case 'me':
                return assignedUser && assignedUser.id === currentUser.id;

            case 'others':
                return assignedUser && assignedUser.id !== currentUser.id;

            case 'not':
                return !assignedUser;
        }

        throw new Error('value ' + user.value + ' is invalid');
    };

};
