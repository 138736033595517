var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M11.9062 8.5625C11.9062 7.53125 11.2187 6.5 9.5 6.5C7.78125 6.5 7.09375 7.53125 7.09375 8.5625C7.09375 9.9375 8.46875 11.3125 9.5 11.3125C10.5312 11.3125 11.9062 9.9375 11.9062 8.5625Z",
        fill: "#6B7280",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        d: "M9.5 12.3438C15.3397 12.3438 14.9962 17.5 14.9962 17.5H4.00381C4.00381 17.5 3.66033 12.3438 9.5 12.3438Z",
        fill: "#6B7280",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        d: "M17 8.71429C17 9.57143 16.1429 11 15 11C13.8571 11 13 9.57143 13 8.71429C13 7.85714 13.5714 7 15 7C16.4286 7 17 7.85714 17 8.71429Z",
        fill: "#6B7280",
      },
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        d: "M15.2692 12C16.8133 12 18.0156 12.4262 18.8304 13.2479C19.6439 14.0684 20 15.2114 20 16.5V17H15.7263C15.6362 16 15.3565 15.2128 15 14.5C14.7775 14.0549 14.3568 13.6098 13.9585 13.2529C13.4885 12.8317 13.486 12.1345 14.1116 12.0514C14.498 12 14.9089 12 15.2692 12Z",
        fill: "#6B7280",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }