import TeamAPI from "../api/team";

const 
  CREATING_EMPLOYEES = "CREATING_EMPLOYEES",
  CREATING_EMPLOYEES_SUCCESS = "CREATING_EMPLOYEES_SUCCESS",
  CREATING_EMPLOYEES_ERROR = "CREATING_EMPLOYEES_ERROR",
  INVITING_EMPLOYEES = "INVITING_EMPLOYEES",
  INVITING_EMPLOYEES_SUCCESS = "INVITING_EMPLOYEES_SUCCESS",
  INVITING_EMPLOYEES_ERROR = "INVITING_EMPLOYEES_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errors: null,
    snack: false,
    snackMessage: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasErrors(state) {
      return state.errors !== null;
    },
    errors(state) {
      return state.errors;
    },
  },
  mutations: {
    [CREATING_EMPLOYEES](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [CREATING_EMPLOYEES_SUCCESS](state) {
      state.isLoading = false;
      state.errors = null;
    },
    [CREATING_EMPLOYEES_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
    },
    [INVITING_EMPLOYEES](state) {
      state.isLoading = true;
      state.errors = null;
    },
    [INVITING_EMPLOYEES_SUCCESS](state) {
      state.isLoading = false;
      state.errors = null;
    },
    [INVITING_EMPLOYEES_ERROR](state, errors) {
      state.isLoading = false;
      state.errors = errors;
    },
  },
  actions: {
    async createUsers({commit}, payload) {
      commit(CREATING_EMPLOYEES);
      try {
          let response = await TeamAPI.createUsers(payload);
          commit(CREATING_EMPLOYEES_SUCCESS, response.data);
          return response.data;
      } catch (errors) {
          commit(CREATING_EMPLOYEES_ERROR, errors);
          return null;
      }
    },
    async removeAccess({commit}, userId) {
      commit(INVITING_EMPLOYEES);
      try {
          let response = await TeamAPI.removeAccess(userId);
          commit(INVITING_EMPLOYEES_SUCCESS, response.data);
          return response.data;
      } catch (errors) {
          commit(INVITING_EMPLOYEES_ERROR, errors);
          return null;
      }
    },
    async resendInvitation({commit}, payload) {
      commit(INVITING_EMPLOYEES);
      try {
          let response = await TeamAPI.resendInvitation(payload);
          commit(INVITING_EMPLOYEES_SUCCESS, response.data);
          return response.data;
      } catch (errors) {
          commit(INVITING_EMPLOYEES_ERROR, errors);
          return null;
      }
    },
  }
}