import client from "./client";

export default {

    getAuthUrl() {
        return client.get("/v2/xero/authorization-url");
    },

    createToken(payload) {
        return client.post("/v2/xero/access-token", payload);
    },

    deleteToken() {
        return client.delete("/v2/xero/access-token");
    },

    get() {
        return client.get("/v2/xero-auth");
    },

};
