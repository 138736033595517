<template>
    <div class="d-flex radio-btn-group filter grey-1 radius tabSystem">
        <div v-for="(n, index) in tabs" :key="index" class="radio">
            <div v-if="!n.isDisabled" class="pa-1">
                <input 
                    :name="Math.random()"
                    :id="timestamp + index" 
                    type="radio" 
                    :value="n.value" 
                    @change="updateValue(n.value);(n.track) ? logToSegment(n.track) : ''"
                    v-model="inputModel"
                />
                <label :for="timestamp + index" class="px-4 cpointer">
                    {{ n.label }}
                </label>
            </div>
        </div>
    </div>
</template>
<script>
    
    import mixin from '../Mixins/mixin.js';

    export default {
        name: "tab-system",
        mixins: [mixin],
        model: {
            event: 'change'
        },
        props: {
            color: {
                default: false
            },
            tabs: {
                default: false,
                required: false
            },
            defaultValue:{
                default: '',
                type: String,
                required: false
            },
        },
        data () {
            return {
                inputModel: this.defaultValue,
                timestamp: Date.now()
            }
        },
        watch:{
            defaultValue(val){
                this.inputModel = val
            },
        }, 
        methods: {
            updateValue(value){
                this.$emit('change', value)
            }
        }
    };
</script>
<style scoped lang="scss">
    .v-toolbar__content label:hover{
        color: var(--v-grey-8-base) !important;
    }
    .radio-btn-group.filter{
        .radio{
            input[type=radio]:checked + label{
                box-shadow: 0px 2px 8px rgba(76, 89, 103, 0.2);
            }
            label{
                line-height: 18px;
            }
        } 
    }
</style>