import { AxiosError } from "axios";

const mutations = {
    SET_ERROR: "SET_ERROR",
    RESET_ERROR: "RESET_ERROR",
};

const module = {

    /**
     * @typedef ErrorState
     * @type {Object}
     * @property {Number} code
     */
    state: {
        code: 0,
    },

    getters: {

        /**
         * 404 error code is considered as a RBAC error too as the API responds with this HTTP code when a resource can not be found because of permissions.
         * @param {ErrorState} state
         * @returns {boolean}
         */
        hasRBACError(state) {
            return [403, 404].includes(state.code);
        },

    },

    mutations: {

        /**
         * @param {ErrorState} state 
         * @param {AxiosError} error
         */
        [mutations.SET_ERROR](state, error) {
            state.code = error.response.status;
        },

        /**
         * @param {ErrorState} state 
         */
        [mutations.RESET_ERROR](state) {
            state.code = 0;
        },

    },

};

export {module as default, mutations};