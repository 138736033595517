import client from "./client";

export default {
  create(data) {
    return client.post("/api/licence/create", data);
  },
  update(id, data) {
    return client.post("/api/licences/update/"+id, data);
  },
  batch(data){
    return client.patch("/v2/batch/licences", 
          data,
        {
          headers:{
            'Content-Type': "application/merge-patch+json"
          },
        }
    );
  },
  archive(id) {
    return client.delete("/api/licences/archive/"+id);
  },
  addUsers(id, data) {
    return client.post("/api/licences/addUsers/"+id, data);
  },
  removeUser(id, userId) {
    return client.post("/api/licences/removeUser/" + id, 
      {
        'data': {
          "userId": userId
        }
      }
    );
  },
  getOne(id) {
    return client.get("/v2/licences/" + id);
  },

  list(query) {
    return client.get("/v2/licences", {
      params: query,
    });
  },

  getMonthRenewals() {
    return client.get("/api/licences/card/getMonthRenewals");
  }
};
