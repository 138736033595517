import Vue from 'vue'
import store from '../store'
import { extend, configure } from 'vee-validate';
import { required, email, url, max } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate';
import i18n from '../plugins/i18n'

configure({
    defaultMessage: (_, values) => i18n.t(`security.validations.${values._rule_}`, values)
})

extend('required', required);
extend('email', email)
extend('website', {
    message: field => {
        return i18n.t('error.website')
    },
    validate: value => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(value);
    }
});
extend('website-existing', async value => {
    const response = await store.dispatch('app/getFromWebsite', {
        website: value,
    });

    if(response == null) {
        return true
    } 
      
    return i18n.t('error.website-existing', {'name': response.name})
});
extend('companyMail', {
    message: field => {
        return i18n.t('error.company-email')
    },
    validate: value => {
        // Todo : add a test for disposable emails

        // Only add this test on prod accounts
        if(process.env.NODE_ENV === 'production'){
            return !/@(gmail\.com)|(yopmail\.com)/i.test(value)
        }
        return true
    }
})
extend('password', {
    validate: value => {
        // ToDO : Check pwd
        // var strongRegex = new RegExp("^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$");
        // return strongRegex.test(value);
        return true
    }
})

extend('image-url', {
    message: field => {
        return i18n.t('error.image-url')
    },

    validate: value => {

        const img = new Image();
        img.src = value;

        return img.decode().then(
            () => true,
            () => false,
        );
    },

});

extend('max', {
    ...max,
    message: (field, constraint) => {
        return i18n.t('security.validations.max', {max: constraint.length});
    }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

export default{}