var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "menu",
      attrs: {
        "mobile-breakpoint": "sm",
        app: "",
        color: "bg-menu",
        width: "240",
        "mini-variant": _vm.mini,
        "mini-variant-width": "72",
      },
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          _c(
            "div",
            {
              class:
                "pt-5 pb-2 d-flex " +
                (_vm.mini ? "justify-center" : "px-3 justify-space-between") +
                " align-center",
              staticStyle: { "max-height": "64px" },
            },
            [
              _c(
                "div",
                {
                  on: {
                    mouseover: function ($event) {
                      _vm.hover = true
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("v-img", {
                        style: _vm.mini ? "" : "margin-left: 0.5rem;",
                        attrs: {
                          contain: "",
                          position: "left center",
                          width: _vm.mini ? "24" : "140",
                          "max-height": _vm.mini ? "24" : "28",
                          src: _vm.mini ? _vm.logoMiniSrc : _vm.logoSrc,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.mini && _vm.hover
                ? _c(
                    "div",
                    {
                      staticClass: "pa-1 cpointer absolute menu__selected-logo",
                      on: {
                        click: _vm.updateMiniValue,
                        mouseleave: function ($event) {
                          _vm.hover = false
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon-expend f-2 white--text d-flex",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.mini
                ? _c(
                    "div",
                    {
                      class:
                        "cpointer " +
                        (_vm.expandHover ? "menu__selected-logo" : "") +
                        " pa-1",
                      on: {
                        mouseover: function ($event) {
                          _vm.expandHover = true
                        },
                        mouseleave: function ($event) {
                          _vm.expandHover = false
                        },
                        click: _vm.updateMiniValue,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "icon-collapse f-2 white--text d-flex",
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.menuItemsData, function (category, index) {
            return _c(
              "div",
              {
                key: category.id,
                staticClass: "menu-item-category",
                attrs: { id: "menu-" + category.id },
              },
              [
                (!category.permissions ||
                  _vm.isGranted(category.permissions)) &&
                !_vm.isCategoryEmpty(category.items)
                  ? _c(
                      "div",
                      {
                        class:
                          "category" +
                          (_vm.mini
                            ? " d-flex align-center justify-center flex-column"
                            : " px-4 " +
                              (category.id == "workspace" ? "pt-2" : "pt-4")),
                      },
                      [
                        index > 0
                          ? _c("menu-title", {
                              attrs: {
                                title: category.title,
                                isCollapse: _vm.mini,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        category.items
                          ? _c(
                              "div",
                              { staticClass: "mt-2 d-flex flex-column gap-1" },
                              _vm._l(category.items, function (item) {
                                return _c(
                                  "div",
                                  { key: item.title },
                                  [
                                    item.subItems
                                      ? _c("menu-group", {
                                          attrs: {
                                            group: item,
                                            isCollapse: _vm.mini,
                                          },
                                          on: {
                                            openMenu: function ($event) {
                                              _vm.mini = false
                                            },
                                          },
                                        })
                                      : !item.permissions ||
                                        _vm.isGranted(item.permissions)
                                      ? _c("menu-item", {
                                          attrs: {
                                            item: item,
                                            isCollapse: _vm.mini,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }