// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/signup.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/signup-mobile.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#security-content[data-v-60eca206]{height:100%}#security-content #row-content[data-v-60eca206]{height:100%}#background-onboarding[data-v-60eca206]{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___}),linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%);background-size:cover;background-repeat:no-repeat;background-position:center;min-height:100vh}@media(max-width: 960px){#background-onboarding[data-v-60eca206]{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___}),linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%)}}`, "",{"version":3,"sources":["webpack://./assets/js/views/Security/LayoutSecurity.vue"],"names":[],"mappings":"AAEA,mCACE,WAAA,CACA,gDACE,WAAA,CAGJ,wCACE,mHAAA,CACA,qBAAA,CACA,2BAAA,CACA,0BAAA,CACA,gBAAA,CACA,yBANF,wCAOM,mHAAA,CAAA","sourcesContent":["@import 'assets/scss/variables.scss';\n\n#security-content{\n  height: 100%;\n  #row-content{\n    height: 100%;\n  }\n}\n#background-onboarding{\n  background-image: url('/assets/images/signup.svg'), linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%);\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  min-height: 100vh;\n  @media (max-width: 960px) {\n      background-image: url('/assets/images/signup-mobile.svg'), linear-gradient(108.3deg, #FFFFFF 20.37%, #FFD6D6 149.53%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
