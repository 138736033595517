"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.helpers = exports.collection = exports.lastPage = exports.nextPage = exports.currentPage = exports.previousPage = exports.firstPage = exports.total = exports.loading = exports.CollectionStateFactory = void 0;
;
class CollectionStateFactory {
    create(response) {
        return {
            total: response.getTotal(),
            firstPage: response.getFirstPage(),
            previousPage: response.getPreviousPage(),
            currentPage: response.getCurrentPage(),
            nextPage: response.getNextPage(),
            lastPage: response.getLastPage(),
            collection: response.getCollection(),
        };
    }
}
exports.CollectionStateFactory = CollectionStateFactory;
const loading = (state) => state.loading;
exports.loading = loading;
const total = (state) => state.total;
exports.total = total;
const firstPage = (state) => state.firstPage;
exports.firstPage = firstPage;
const previousPage = (state) => state.previousPage;
exports.previousPage = previousPage;
const currentPage = (state) => state.currentPage;
exports.currentPage = currentPage;
const nextPage = (state) => state.nextPage;
exports.nextPage = nextPage;
const lastPage = (state) => state.lastPage;
exports.lastPage = lastPage;
const collection = (state) => state.collection;
exports.collection = collection;
const helpers = {
    loading,
    total,
    firstPage,
    previousPage,
    currentPage,
    nextPage,
    lastPage,
    collection,
};
exports.helpers = helpers;
