var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.subscriptionState == "end_trial" ||
    _vm.subscriptionState == "cancelled"
    ? _c("subscription-modal", {
        model: {
          value: _vm.openSubscriptionModal,
          callback: function ($$v) {
            _vm.openSubscriptionModal = $$v
          },
          expression: "openSubscriptionModal",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }