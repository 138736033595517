"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Cello {
    isAvailable() {
        return typeof process.env.CELLO_PRODUCT_ID === 'string' && process.env.CELLO_PRODUCT_ID.length > 0;
    }
    initialize() {
        window.cello = window.cello || { cmd: [] };
    }
    prepareDomElement(element) {
        element.src = "https://assets." + process.env.CELLO_SUBDOMAIN + "cello.so/app/v2/latest/cello.js";
        element.type = "module";
    }
    getInstance() {
        return window.cello;
    }
    errorHandler() { }
}
exports.default = Cello;
