<template>
    <v-app v-if="isLayoutSecurity">
        <layout-security></layout-security>
    </v-app>
    <v-app v-else-if="isLayoutOnboarding">
        <layout-onboarding></layout-onboarding>
    </v-app>
    <v-app v-else-if="isLayoutApp" id="content-app">
        <layout></layout>
    </v-app>
</template>
<script>
    
    import {mapGetters} from 'vuex';
    import Layout from "./Layout";
    import LayoutSecurity from "./Security/LayoutSecurity";
    import LayoutOnboarding from "./Onboarding/Layout.vue";
    import { setUser as setSentryUser, setContext as setSentryContext } from '@sentry/vue';
    import Vue from 'vue';
    import mixin from '../Mixins/mixin.js';
    import Security from '../Mixins/Security';
    import Matomo from "../services/Analytics/Matomo";
    import permissions from '../services/Security/Permissions';
    import analytics from '@app/js/services/Analytics/Segment';

    import CrispScript from '@app/loaders/scripts/Crisp';
    import Cello from '@app/loaders/scripts/Cello';
    import ScriptLoader from '@app/loaders/ScriptLoader';

    export default {
        name: "app",
        mixins: [mixin, Security],    
        components: {
            'layout': Layout,
            'layout-security': LayoutSecurity,
            LayoutOnboarding
        },
        data () {
            return {
                appReady: false,
                viewportWidth: this.$vuetify.breakpoint.width,
                viewportHeight: this.$vuetify.breakpoint.height,
                unloggedRoutes: [
                    'test',
                    'login',
                    'magic-login',
                    'extension-success',
                    'extension-error',
                    'register',
                    'extension',
                    'password',
                    'passwordconfirm',
                    'registerconfirmed',
                    'onboardinguser',
                    'onboardingpassword',
                    'onboardingcompany',
                    'welcome',
                    'account-deleted',
                    'notfound',
                    'building',
                    'subscription.checkout'
                ]
            }
        },
        methods: {
            setLocal(){
                //set locale for i18n
                let lang = '';
                if(!this.user) {
                    lang = this.getFormattedNavigatorLang()
                } else {
                    lang = this.user.prefLang
                }  
                
                Vue.config.lang = this.$i18n.locale = lang
                
                if(lang == 'fr-FR'){
                    require('moment/locale/fr')
                    this.$vuetify.lang.current = 'fr'
                }
            },

            configureErrorMonitoring(){

                setSentryUser({
                    id: this.user.id,
                    email: this.user.email,
                });

                if(this.company){

                    setSentryContext("company", {
                        id: this.company.id,
                        name: this.company.name,
                    });
                }
            },

            /**
             * @todo Refactor to an Analytics service which should boot all related services
             */
            configureAnalytics() {

                Matomo.setup();

                if(this.user){
                    let segmentData = {
                        'email': this.user.email,
                        'firstname': this.user.firstName,
                        'lastname': this.user.lastName,
                        'roles': this.user.roles,
                        'screenResolution': this.viewportWidth + ' x ' + this.viewportHeight,
                        'language': this.user.prefLang,
                    };

                    if(this.company){
                        segmentData = {
                            ...segmentData,
                            'companyId': this.company.id,
                            'companyName': this.company.name,
                            'country': this.company.country,
                            'companySize': this.company.size,
                            'isGoogleSync': this.company.isGsuiteEnabled,
                            'isBridgeSync': (this.company.bridgeAccess && this.company.bridgeAccess.state) ? true : false,
                            'companyCreatedAt': this.company.createdAt,
                            'trialStartDate': this.subscription ? this.subscription.trialStartDate : '',
                            'sandbox': this.company.sandbox,
                        };
                    }

                    if(this.subscription){
                        segmentData['subscriptionType'] = this.subscription.plan;
                        segmentData['trialStartDate'] = this.subscription.trialStartDate;
                        segmentData['trialEndDate'] = this.subscription.trialEndDate;
                        segmentData['subscriptionStartDate'] = this.subscription.subscriptionStartDate;
                        segmentData['subscriptionEndDate'] = this.subscription.subscriptionEndDate;
                    }

                    // Identify the user for segment
                    analytics.identify(this.user.id, segmentData);
                }
            },
            
            async configureCrisp(){

                const $crisp = await ScriptLoader.load(new CrispScript);
                
                if ($crisp && this.user) {
                    
                    $crisp.push(["do", "chat:hide"])

                    $crisp.push(["on", "message:received", () => {
                        this.$store.dispatch('chat/load');
                        $crisp.push(["do", "chat:close"])
                        $crisp.push(["do", "chat:show"])
                    }]);

                    $crisp.push(["on", "chat:opened", () => {
                        this.$store.dispatch('chat/load');
                    }]);

                    $crisp.push(["on", "chat:closed", () => {
                        $crisp.push(["do", "chat:hide"])
                    }]);

                    if(this.user){
                        $crisp.push(["set", "user:email", [this.user.email]]);
                    }

                    if(this.company){
                        
                        if(!this.company.sandbox){
                            $crisp.push(["set", "user:nickname", [this.user.firstName + ' ' + this.user.lastName]]);
                            $crisp.push(["set", "user:company", [this.company.name]]);
                        }

                        $crisp.push(["set", "session:data", ["companyId", this.company.id]]);

                        if(this.subscription){
                            $crisp.push(["set", "session:data", ["plan", this.subscription.plan]]);
                        }
                    }
                }
            },
            configurePostHog(){

                if (typeof this.$posthog === 'object' && this.user){
                    let properties = {
                        email: this.user.email,
                        name: this.user.firstName + ' ' + this.user.lastName,
                        roles: this.user.roles,
                    };
                    
                    if(this.company) {
                        properties = {
                            ...properties,
                            companySanbox: this.company.sandbox,
                            companyId: this.company.id,
                            companyName: this.company.name,
                            companyCountry: this.company.country,
                            companyCreatedAt: this.company.createdAt,
                        };
                    }

                    if(this.subscription){
                        properties = {
                            ...properties,
                            subscriptionType: this.subscription.plan,
                            subscriptionTrialStart: this.subscription.trialStartDate,
                            subscriptiontrialEnd: this.subscription.trialEndDate,
                            subscriptionStartDate: this.subscription.subscriptionStartDate,
                            subscriptionEndDate: this.subscription.subscriptionEndDate,
                            subscriptionStatus: this.subscription.status,
                            subscriptionUnits: this.subscription.units
                        };
                    }
                    
                    this.$posthog.identify(this.user.id, properties);
                }
            },
            async configureCello(){

                const cello = await ScriptLoader.load(new Cello);
                if (typeof cello === 'object' && this.user) {

                    await this.$store.dispatch("user/authenticateCello");
                    cello.cmd.push((cello) => {
                        cello.boot({
                            customLauncherSelector: '#cello-launcher',
                            hideDefaultLauncher: true,
                            productId: process.env.CELLO_PRODUCT_ID,
                            token: this.celloToken,
                            showOnBoot: false
                        });
                    });
                }
            },

            /**
             * This data can and should be loaded asynchronously to avoid boot locking.
             */
            loadUnnecessaryBootData() {
                this.$store.dispatch('user/list');
            },
        },
        computed: {
            ...mapGetters('user', {
                user: 'get',
                celloToken : 'getCelloToken'
            }),

            ...mapGetters('company', {
                company: 'get',
            }),

            ...mapGetters('subscription', {
                subscription: 'get',
                isAppSumo: 'isAppSumo',
            }),
            isUnloggedRoute() {
                return (this.unloggedRoutes.indexOf(this.$route.name) >= 0)
            },
            layout() {
                return this.$route.meta?.layout ?? "default";
            },
            isLayoutSecurity() {
                return this.appReady && (this.isUnloggedRoute || this.layout === 'security')
            },
            isLayoutOnboarding() {
                return this.appReady && this.user && this.layout === 'onboarding' || this.company?.onboardingStep >= 1
            },
            isLayoutApp() {
                return this.appReady && this.user
            },
        },
        async created() {

            if(this.$route.query.ucc){
                window.localStorage.setItem('ucc', this.$route.query.ucc);
            }
            
            // If user is not authenticated, no need to go further
            if(this.user){

                this.loadUnnecessaryBootData();

                const promises = [
                    this.$store.dispatch("subscription/get"),
                ];

                if(this.isGranted(permissions.SEE_TRANSACTIONS)){
                    promises.push(
                        this.$store.dispatch("amortizationsSettings/get"),
                    );
                }

                /** This following data should be awaited to boot the app */
                await Promise.all(promises);

                this.configureErrorMonitoring();
                this.configureCrisp();
                this.configureAnalytics();
                this.configurePostHog();
                if(this.layout !== 'security'){
                    this.configureCello();
                }

                if(!this.company && this.$route.name !== 'building'){
                    this.$router.push({
                        name: 'building',
                    });
                }

                if(this.company && !this.company.onboarded && this.isAppSumo){
                    this.$router.push({
                        name: 'onboardingpassword',
                    });
                }

            }else{
                this.configureCrisp();
                this.configureAnalytics();
            }

            this.setLocal();
            this.appReady = true;
        },
    }
</script>
<style scoped>   
</style>