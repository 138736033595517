import store from "../../store";

export default new class FinancialDataSourcesManager {

    /**
     * @returns {Promise}
     */
    fetch(){
        return Promise.all([
            store.dispatch("bank/getItems"),
            store.dispatch("sellsy/get"),
            store.dispatch("quickbooks/get"),
            store.dispatch("xero/get"),
            store.dispatch("spendesk/list"),
        ]);
    }

}