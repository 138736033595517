var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticStyle: { left: "auto !important" },
      attrs: {
        left: "",
        rounded: "4px",
        color: "gray-10",
        timeout: _vm.timeout,
        "max-width": "100%",
      },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-inline-flex justify-space-between align-center w-100",
          staticStyle: { gap: "32px" },
        },
        [
          _c("div", [_vm._v("\n        " + _vm._s(_vm.message) + "\n      ")]),
          _vm._v(" "),
          _vm.redirectLink !== "" && _vm.redirectMsg !== ""
            ? _c(
                "div",
                {
                  staticClass: "cpointer fw-6 f-5",
                  staticStyle: { color: "var(--v-blue-60-base)" },
                  on: {
                    click: function ($event) {
                      return _vm.redirectTo(_vm.redirectLink)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.redirectMsg) + "\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "cpointer",
              on: {
                click: function ($event) {
                  _vm.isShown = false
                },
              },
            },
            [
              _c(
                "icon-base",
                { attrs: { "icon-name": "icon-cross" } },
                [_c("icon-cross")],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }