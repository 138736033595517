var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      fill: "none",
      d: "M10.5359 5.91809L10.8904 4.5H13.1097L13.4642 5.91809C13.5065 6.08741 13.6339 6.22235 13.8001 6.27456C14.139 6.38099 14.465 6.5167 14.7751 6.67879C14.9298 6.75963 15.1153 6.75413 15.2649 6.66438L16.5187 5.91209L18.0879 7.48131L17.3357 8.73512C17.2459 8.88471 17.2404 9.07027 17.3213 9.22495C17.4833 9.53508 17.6191 9.86106 17.7255 10.2C17.7777 10.3662 17.9127 10.4936 18.082 10.5359L19.5 10.8904V13.1096L18.082 13.4641C17.9127 13.5064 17.7777 13.6338 17.7255 13.8C17.6191 14.1389 17.4833 14.4649 17.3213 14.7751C17.2404 14.9297 17.2459 15.1153 17.3357 15.2649L18.0879 16.5187L16.5187 18.0879L15.2649 17.3356C15.1153 17.2459 14.9297 17.2404 14.7751 17.3212C14.465 17.4833 14.139 17.619 13.8001 17.7254C13.6338 17.7777 13.5065 17.9126 13.4642 18.0819L13.1097 19.5H10.8904L10.5359 18.0819C10.4936 17.9126 10.3663 17.7777 10.2 17.7254C9.86112 17.619 9.53515 17.4833 9.22502 17.3212C9.07033 17.2403 8.88477 17.2459 8.73519 17.3356L7.48134 18.0879L5.91211 16.5187L6.66443 15.2648C6.75419 15.1152 6.75968 14.9297 6.67884 14.775C6.51676 14.4649 6.38105 14.1389 6.27463 13.8001C6.2224 13.6338 6.08745 13.5065 5.91816 13.4642L4.5 13.1096V10.8904L5.91816 10.5358C6.08747 10.4935 6.22241 10.3662 6.27463 10.1999C6.38105 9.86107 6.51676 9.53511 6.67884 9.22501C6.75969 9.07031 6.75417 8.88476 6.66443 8.73518L5.91211 7.48131L7.48134 5.91208L8.73519 6.6644C8.88481 6.75417 9.07036 6.75964 9.22502 6.6788C9.53514 6.51671 9.86112 6.38099 10.2 6.27456C10.3663 6.22234 10.4936 6.08739 10.5359 5.91809ZM12.0001 15C12.8637 15 13.6222 14.7099 14.1661 14.1661C14.71 13.6222 15.0001 12.8636 15.0001 12C15.0001 11.1364 14.71 10.3778 14.1661 9.83395C13.6222 9.29006 12.8637 9 12.0001 9C11.1364 9 10.3779 9.29006 9.83401 9.83395C9.29012 10.3778 9.00006 11.1364 9.00006 12C9.00006 12.8636 9.29012 13.6222 9.83401 14.1661C10.3779 14.7099 11.1364 15 12.0001 15Z",
      stroke: "#0A0D10",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }