import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

/**
 * Add support of a new pluralization "unlimited" for $tc function
 * 
 * @param count {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
 */
function addUnlimitedPluralizationSupport (count, choicesLength) {

  if( choicesLength === 2 ){
    return count > 1 ? 1 : 0;
  }

  if( count === 'unlimited' && choicesLength === 4 ) {
    return 3;
  }else{
    return count > 1 ? 2 : count;
  }
};

export default new VueI18n({
  fallbackLocale: 'en-GB',
  messages: loadLocaleMessages(),
  pluralizationRules: {
    'fr-FR': addUnlimitedPluralizationSupport,
    'en-GB': addUnlimitedPluralizationSupport,
  },
})
