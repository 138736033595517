import { AxiosError } from "axios";
import ActivityAPI from "../api/activity";

const 
  FETCHED = "FETCHED",
  CHART_FETCHED = "CHART_FETCHED",
  LAST_BROWSER_USAGES_FETCHED = "LAST_BROWSER_USAGES_FETCHED",
  LOADING = "LOADING",
  ERROR = "ERROR";

export default {

    namespaced: true,

    state: {
      isLoading: false,
      error: null,
      userActivities: [],
      chartActivities: [],
      lastBrowserUsages : [],
      activities: [],
      activity: ''
    },
    getters: {
      isLoading(state) {
        return state.isLoading;
      },
      hasError(state) {
        return state.error !== null;
      },
      error(state) {
        return state.error;
      },
      hasActivity(state) {
        return state.activities.length > 0;
      },
      activities(state) {
        return state.activities;
      },
      activity(state) {
        return state.activity;
      },
      chartActivities(state) {
        return state.chartActivities
      },
      lastBrowserUsages(state) {
        return state.lastBrowserUsages
      }
    },

    mutations: {

        /**
         * @param {Array} activities
         */
        [FETCHED](state, activities)
        {
          state.activities = Object.values(activities);
        },

        /**
         * @param {Array} chartActivities
         */
        [CHART_FETCHED](state, chartActivities)
        {
          state.chartActivities = Object.values(chartActivities);
        },

        /**
         * @param {Array} lastBrowserUsages
         */
        [LAST_BROWSER_USAGES_FETCHED](state, lastBrowserUsages)
        {
          state.lastBrowserUsages = Object.values(lastBrowserUsages);
        },

        /**
         * @param {Boolean} loading
         */
        [LOADING](state, loading)
        {
            state.loading = loading;
        },

        /**
         * @param {AxiosError} error
         */
        [ERROR](state, error)
        {
            state.error = error;
        },

    },

    actions: {

      async getChartActivities({ commit }, licenceId) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {

          const response = await ActivityAPI.getChartActivities(licenceId);

          commit(LOADING, false);
          commit(CHART_FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },
      async getLastBrowserUsages({ commit }) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {

          const response = await ActivityAPI.getLastBrowserUsages();

          commit(LOADING, false);
          commit(LAST_BROWSER_USAGES_FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },

      /**
       * @param {Object} query
       */
      async getLastUsages({ commit }, query) {

        commit(LOADING, true);
        commit(ERROR, null);

        try {

          const response = await ActivityAPI.lastUsages(query);

          commit(LOADING, false);
          commit(FETCHED, response.data);

          return response.data;
        } catch (error) {

          commit(LOADING, false);
          commit(ERROR, error);
        }
      },
    },
}